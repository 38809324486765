import { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';

import { ReactComponent as PlusPopupIcon } from '../../assets/plus_popup_icon.svg'
import { ReactComponent as GroupDeleteIcon } from '../../assets/group_delete_icon.svg'

import { COLORS } from '../../styles/colors';

import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { setFeedPlanEventActivityByIndex, setFeedPlanEventInputValue } from '../../store/feedPlanSlice'
import { PlanEventTypeEnum } from '../../models';
import TimeField from '../shared/TimeField';
import { TypographySecondary } from '../../ui/TypographySecondary';
import { TypographyPrimary } from '../../ui/TypographyPrimary';

const CreatePlanListCleaningContent = () => {

  const { feedPlanData } = useAppSelector(store => store.feedPlanReducer)
  const { groupList } = useAppSelector(store => store.groupsReducer)
  const dispatch = useAppDispatch()

  const [totalCols, setTotalCols] = useState(1)

  const renderPlanDataCells = (groupId) => {
    const dataCells = []
    let foundCells = 0

    feedPlanData.plan_data.forEach((el, index) => {
      if (!el.active && el.group === groupId && el.event === PlanEventTypeEnum.Cleaning) {
        foundCells++
        dataCells.push(
          <TableCell key={'cleaningCell' + el.event + el.group + foundCells} align='center' sx={{ pr: '12px',  }} >
            <TimeField
              key={`cleaningTimeCell${groupId}${index}`}
              value={el.time}
              onBlur={(value) => {
                dispatch(setFeedPlanEventInputValue({ groupId: groupId, event: PlanEventTypeEnum.Cleaning, key: 'time', value: value, index: index }))
              }}
            />
          </TableCell>
        )
      }
    })

    for (let i = foundCells; i < totalCols; i++) {
      dataCells.push(<TableCell key={'cleaningCell' + PlanEventTypeEnum.Cleaning + i} align='center' sx={{ pr: '12px' }} >
        <TimeField
          key={`cleaningTimeCell${groupId}${i}`}
          value={''}
          onBlur={(value) => dispatch(setFeedPlanEventInputValue({ groupId: groupId, event: PlanEventTypeEnum.Cleaning, key: 'time', value: value }))}
        />
      </TableCell>
      )
    }
    return dataCells
  }

  const changePlanColumnsComposition = (columnNumber, addColumn: boolean) => {
    if (totalCols === 1 && !addColumn) return

    groupList.forEach((group) => {
      let counter = 0
      
      for (const [index, planEvent] of Object.entries(feedPlanData.plan_data)) {
        if (planEvent.event === PlanEventTypeEnum.Cleaning && planEvent.group === group.id) {
          if (!planEvent.active && planEvent.modified && addColumn) {
            dispatch(setFeedPlanEventActivityByIndex({index: index, active: true}))
            break
          } else if (planEvent.active) {
            counter+=1
            if (counter===columnNumber) {
              dispatch(setFeedPlanEventActivityByIndex({index: index, active: false}))
              break
            }
          }
        }
      }
    })
    setTotalCols(totalCols + (addColumn ? 1 : -1))
  }

  if (feedPlanData && groupList) {
    const distinctTimeColumns = groupList.map(
      (group) => feedPlanData.plan_data.filter(el => el.active && el.group === group.id && el.event === PlanEventTypeEnum.Cleaning).length)
    
    const currentMaxColumns = Math.max(...distinctTimeColumns)

    if (currentMaxColumns > totalCols) setTotalCols(currentMaxColumns)
  }

  const timeColumns = []
  for (let i = 1; i <= totalCols; i++) timeColumns.push(
    <TableCell key={`timeColumns_${i}`} align='center' sx={{ minWidth: '150px', borderBottom: `1px solid ${COLORS.secondaryFontOpacity} !important`, }} >
        <TypographySecondary>
          {'Уборка ' + i} <GroupDeleteIcon onClick={() => changePlanColumnsComposition(i, false)} style={{paddingTop: '7px'}} />
        </TypographySecondary>
    </TableCell>)

    const sxBorder = `1px solid ${COLORS.secondaryFontOpacity} !important`

  return <>
    <Box display='flex' flexDirection='row' sx={{ pt: '18px' }}>
      <TypographyPrimary>Время уборки корма задается отдельно для каждой группы в назначенное время. </TypographyPrimary>
    </Box>
    <Box sx={{ pt: '24px', maxWidth: '1150px' }}>
      <TableContainer >
        <Table sx={{
          minWidth: 450,
          width: "max-content",
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none"
          }
        }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '200px', borderBottom: sxBorder }}><TypographySecondary> Группа</TypographySecondary></TableCell>
              <TableCell sx={{ width: '170px', borderBottom: sxBorder}} > <TypographySecondary>Действия</TypographySecondary> </TableCell>
              {timeColumns}
              <TableCell align='right' sx={{ minWidth: '100px', borderBottom: sxBorder }}>
                <PlusPopupIcon onClick={ () => changePlanColumnsComposition(totalCols+1, true) } />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              groupList.map((group) => (
                <TableRow
                  sx={{}}
                  key={`clean${group.key}_${group.id}`}
                >
                  <TableCell component="td" scope="row" align='left' padding='none' >
                    <Box display='flex' flexDirection='column' justifyContent='center' 
                          sx={{ borderLeft: `2px solid ${COLORS.icon}`, pl: '12px', height: '54px', }}>
                      <Box display='flex' flexDirection='row' alignItems='baseline' sx={{ pb: '12px', }}>
                        <TypographyPrimary sx={{ whiteSpace: 'nowrap' }}>
                          {group.title}
                        </TypographyPrimary>
                        <TypographySecondary sx={{ ml: '3px', whiteSpace: 'nowrap' }}>
                          {group.barntitle}
                        </TypographySecondary>
                      </Box>
                      <TypographyPrimary sx={{ color: COLORS.secondary }}>{group.cattles_qty}</TypographyPrimary>
                    </Box>
                  </TableCell>
                  <TableCell align='left' padding='none'  >
                    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ height: '110px', }}>
                      <Box display='flex' flexDirection='column' justifyContent='center' sx={{pt: '12px'}} >
                        {
                          feedPlanData.plan_data
                            .filter(plan => plan.active && plan.group === group.id && plan.event === PlanEventTypeEnum.Distribution)
                            .map((plan, index) => <Box display='flex' flexDirection='row' alignItems='center' sx={{ pb: '2px', pl: '4px', }} key={`plan_time${index}`}>
                              <TypographyPrimary sx={{ color: COLORS.secondaryFont, whiteSpace: 'nowrap', pr: '4px', }} >{'P' + (index + 1) + ':'}</TypographyPrimary>
                              <TypographyPrimary sx={{ whiteSpace: 'nowrap', }}>{plan.time}</TypographyPrimary>
                            </Box>
                            )
                        }
                      </Box>
                    </Box>
                  </TableCell>
                  {renderPlanDataCells(group.id)}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </>
}

export default CreatePlanListCleaningContent