import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Box, CardContent, Grid, Typography } from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { Item } from '../../ui/Item'
import { CardStandart } from '../../ui/CardStandart'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { TypographySecondary } from '../../ui/TypographySecondary'

import { COLORS } from '../../styles/colors'
import axAPI from '../../http'

export const yesterdayDate = new Date(
	Date.now() - 86400000
).toLocaleDateString()
// const mockData = [
//     {num: '45', uom: 'кг/гол', title: 'Среднее ПСВ', difference: 1, id: 0},
//     {num: '123', uom: 'мин/гр', title: 'Отсутствие корма', difference: 1, id: 1},
//     {num: '999 345', uom: 'кг', title: 'Роздано корма', difference: 1, id: 2},
//     {num: '9 345', uom: 'кг', title: 'Остаток корма', difference: 1, id: 3},
// ]

const GetPoolCards = () => {
	const [statisticData, setStatisticData] = useState([])

	useEffect(() => {
		axAPI
			.get('feedplan/statistic')
			.then(response => setStatisticData(response.data))
	}, [])

	return (
		<NavLink to='groups'>
			<CardStandart
				sx={{
					width: '100%',
					height: '324px',
				}}
			>
				<CardContent>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'baseline',
							justifyContent: 'space-between',
							paddingBottom: '24px',
						}}
					>
						<TypographyHeader>Показатели</TypographyHeader>
						<TypographyPrimary sx={{ color: '#7F7F84' }}>
							Вчера, {yesterdayDate}
						</TypographyPrimary>
					</Box>
					<Box>
						<Grid container justifyContent='space-between' spacing={2}>
							{statisticData.map(card => (
								<Grid item xs={6} key={card.id}>
									<Item
										sx={{
											height: '106px',
											boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.16)',
										}}
									>
										<TypographySecondary
											sx={{
												color: '#7F7F84',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												marginTop: '-15px',
												marginRight: '-10px',
											}}
										>
											{/* <ArrowDropUpIcon sx={{color: COLORS.success}}/>
                                         {card.difference} */}
										</TypographySecondary>
										<Box
											sx={{
												marginTop: '15px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}
										>
											<Typography
												sx={{
													fontSize: 18,
													fontWeight: 600,
													color: 'black',
												}}
											>
												{card.num}
											</Typography>
											<TypographyPrimary sx={{ color: '#7F7F84', pl: '4px' }}>
												{card.uom}
											</TypographyPrimary>
										</Box>
										<Typography
											sx={{
												fontSize: 16,
												fontWeight: 400,
												display: 'flex',
												color: 'black',
												textAlign: 'left',
											}}
										>
											{card.title}
										</Typography>
									</Item>
								</Grid>
							))}
						</Grid>
					</Box>
				</CardContent>
			</CardStandart>
		</NavLink>
	)
}

export default GetPoolCards
