import React from 'react'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import 'moment/locale/ru'
import '../../styles/dateRangeStyle.css'
import { ReactComponent as CalendarMainLogo } from '../../assets/calendar_main_logo.svg'
import { dayListHandler } from '../group/utils'
import { antdMonths } from '../group/utils/ruLocalization'
import { COLORS } from '../../styles/colors'
moment.locale('ru', antdMonths)
const { RangePicker } = DatePicker
const dateFormat = 'DD.MM.YYYY'
type RangeDateProps = {
	startDate?: any
	endDate?: any
	onChangeUpdateDate?: any
}
const RangeDate = (props: RangeDateProps) => {
	const [color, setColor] = React.useState(COLORS.icon)
	const dayList = dayListHandler()
	if (dayList === null || dayList.length === 0) return
	const beginFormattedDay = dayList[0].toISOString().slice(0, 10).split('-').reverse().join('-')
	const currentFormattedDay = dayList[dayList.length - 1].toISOString().slice(0, 10).split('-').reverse().join('-')
	const disabledDate = current => {
		return current && current > moment().add(1, 'day').endOf('day')
	}
	const handleChangeDebut = range => {
		const beginValue = Date.parse(range[0])
		const endValue = Date.parse(range[1])
		props.onChangeUpdateDate([beginValue, endValue])
	}
	return (
		<RangePicker
			onMouseEnter={() => setColor(COLORS.main)}
			onMouseLeave={() => setColor(COLORS.icon)}
			suffixIcon={<CalendarMainLogo stroke={color} />}
			clearIcon={null}
			className='dataRange'
			defaultValue={
				props.startDate && props.endDate
					? [moment(props.startDate), moment(props.endDate)]
					: [moment(beginFormattedDay, dateFormat), moment(currentFormattedDay, dateFormat)]
			}
			disabledDate={disabledDate}
			locale={locale}
			format={dateFormat}
			onChange={handleChangeDebut}
		/>
	)
}
export default RangeDate
