import * as React from 'react'

import 'antd/dist/antd.min.css'

import '../../styles/groupDateStyle.css'

import locale from 'antd/es/date-picker/locale/ru_RU'

import { COLORS } from '../../styles/colors'
import { antdMonths } from '../group/utils/ruLocalization'

import { ReactComponent as CalendarMainLogo } from '../../assets/calendar_main_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'

import { DatePicker } from 'antd'
import { Moment } from 'moment'
import moment from 'moment'
import { useEffect, useState } from 'react'

interface IGroupDateProps {
	onChange?: any
	value?: Date | null | string
}

export default function GroupDate(props: IGroupDateProps) {
	const [feedPlanDate, setFeedPlanDate] = React.useState<Moment | null>(
		moment(new Date())
	)
	const [color, setColor] = React.useState(COLORS.icon)
	const [dateFormat, setDateFormat] = useState('')

	moment.locale('ru', antdMonths)

	const disabledDate = current => {
		return current && current > moment().add(1, 'day').endOf('day')
	}

	const nowDate = new Date(Date.now()).toLocaleDateString()

	const yesterdayDate = new Date(Date.now() - 86400000).toLocaleDateString()

	useEffect(() => {
		if (moment(props.value).format('L') === nowDate) {
			setDateFormat('[Cегодня, ]DD.MM.YYYY')
		} else if (moment(props.value).format('L') === yesterdayDate) {
			setDateFormat('[Вчера, ]DD.MM.YYYY')
		} else {
			setDateFormat('DD.MM.YYYY')
		}
	}, [props.value])

	const onChangeHandler = date => {
		props.onChange(date)
	}
	return (
		<DatePicker
			// dateRender={current => {
			// 	const style: React.CSSProperties = {}
			// 	if (current.date() === 16 || current.date() === 10) {
			// 		// style.position = 'absolute'
			// 		// style.content = ''
			// 		// style.left = '22px'
			// 		// style.top = '34px'
			// 		// style.width = '6px'
			// 		// style.height = '6px'
			// 		// style.borderRadius = '50%'
			// 		// style.backgroundColor = '#F09A37'
			// 	}
			// 	return (
			// 		<div className='ant-picker-cell'>
			// 			<div className='ant-picker-cell::after'>
			// 				{current.date()}
			// 			</div>
			// 		</div>
			// 	)
			// }}
			defaultValue={props.value ? moment(props.value) : feedPlanDate}
			onMouseEnter={() => setColor(COLORS.main)}
			onMouseLeave={() => setColor(COLORS.icon)}
			suffixIcon={<CalendarMainLogo stroke={color} />}
			onChange={newValue => {
				props.onChange
					? onChangeHandler(newValue.toDate())
					: setFeedPlanDate(newValue)
			}}
			// disabledDate={disabledDate}
			className='groupDate'
			locale={locale}
			format={dateFormat}
			showToday={false}
		/>
	)
}
