import { createSlice } from '@reduxjs/toolkit';

type NotificationProps = {
    open: boolean; 
    handleClose?: () => void;
    isError?: boolean;
    title: string; 
    description: string  
}

const initialState: NotificationProps = {
    open: false, 
    title: '',
    description: '',
}

export const notificationsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        notifyUser(state, action) {
            state.open = true
            state.title = action.payload.title
            state.description = action.payload.description
            state.isError = action.payload.isError
            state.handleClose = action.payload.handleClose
        },
        closeNotification(state) {
            state.open = false
        },
    },
})

export default notificationsSlice.reducer
export const { notifyUser, closeNotification } = notificationsSlice.actions