import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axAPI from '../http'

import { IRationEntity, IRationState } from '../models'

export const fetchRationList = createAsyncThunk(
    'ration/list',
    async function(_, {rejectWithValue}) {      
        try {
            const response = await axAPI({
                method: 'GET',
                url: 'ration/list',
            })
            
            if (response.status!==200) {
                throw new Error('HTTP request error!')
            }
            return response.data
        } catch(error) {
            let errorMessage = 'Failed to retrive groups!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

export const fetchRationEntity = createAsyncThunk(
    'ration/entity',
    async (key: string, { rejectWithValue }) => {
        try {
            const response = await axAPI({
                method: 'GET',
                url: `ration/${key}`,
            })
            
            if (response.status!==200) {
                throw new Error('HTTP request error!')
            }
            return response.data
        } catch(error) {
            let errorMessage = 'Failed to retrive groups!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

const initialState: IRationState = {
    loading: false,
    error: '',
}

export const rationSlice = createSlice({
    name: 'ration',
    initialState,
    reducers: {
        addComponent(state) {
            state.currentRation.components.push({
                item: '',
                itemKey: '',
                coef: 0
            })
        },
        removeComponent(state, action) {
            state.currentRation.components.splice(action.payload.index, 1)
        },
        setBlankRation(state) {
            state.currentRation = {
                key: '',
                title: '',
                gtitles: '',
                dry: 0,
                balance: 0,
                components: []
            }
        },
        setRationAttributeValue(state, action) {
            state.currentRation[action.payload.field] = action.payload.value
        },
        setRowComponentValue(state, action){
            state.currentRation.components[action.payload.index].item = action.payload.item
            state.currentRation.components[action.payload.index].itemKey = action.payload.itemKey
        },
        setRowCoefValue(state, action){
            state.currentRation.components[action.payload.index].coef = action.payload.coef
        },
        // updateRationListFromCurrentValue(state, action) {
        //     state.currentRation = action.payload

        //     let index = state.rationList.findIndex(el => el.key=state.currentRation.key)
        //     if (index!==-1) {
        //         state.rationList[index] = { ...state.currentRation }
        //     } else {
        //         state.rationList.unshift( { ...state.currentRation } )
        //     }
        // },
    },
    extraReducers: {
        [fetchRationList.fulfilled.type]: (state, action: PayloadAction<IRationEntity[]>) => {
            state.loading = false;
            state.error = '';
            state.rationList = action.payload
        },
        [fetchRationList.pending.type]: (state) => {
            state.loading = true;
            state.error = '';
            state.rationList = [];
        },
        [fetchRationList.rejected.type]: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.rationList = [];
        },
        [fetchRationEntity.fulfilled.type]: (state, action: PayloadAction<IRationEntity>) => {
            state.loading = false;
            state.error = '';
            state.currentRation = action.payload
        },
        [fetchRationEntity.pending.type]: (state) => {
            state.loading = true;
            state.error = '';
            state.currentRation = undefined;
        },
        [fetchRationEntity.rejected.type]: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.currentRation = undefined;
        },
    }
})

export const {
    addComponent,
    removeComponent,
    setRationAttributeValue,
    setBlankRation,
    setRowComponentValue,
    setRowCoefValue,
} = rationSlice.actions
export default rationSlice.reducer;