import { CardContent } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { COLORS } from '../../styles/colors'
import { CardStandart } from '../../ui/CardStandart'
import { OutlinedButton } from '../../ui/OutlinedButton'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { TypographyPrimary } from '../../ui/TypographyPrimary'

type FarmFillLinkProps = {
    stepNum: string;
    title: string;
    link: string;
    clicked?: boolean;
    onClick?: () => void;
} 

const FarmFillLink = (props: FarmFillLinkProps) => {
  const [clicked, setClicked] = React.useState(props.clicked)

  return (
    <CardStandart  sx={{
        mt: '24px',
        '&:hover': {boxShadow: props.clicked ? '0px 4px 16px 0px rgba(82, 34, 208, 0.24)' : '0px 4px 16px 0px rgba(0, 0, 0, 0.08)'}
        }}>
        <CardContent>
          <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' sx={{mt: '8px'}}>
            <Box display='flex' flexDirection='row' alignItems='center' >
              <TypographyPrimary sx={{color: COLORS.secondary, pr: '8px'}}>Шаг {props.stepNum}</TypographyPrimary>
              <TypographyHeader sx={{color: props.clicked ? COLORS.black : COLORS.icon}} >{props.title}</TypographyHeader>
            </Box>
            { clicked 
            ? 
            <NavLink to={props.link} onClick={props.onClick}><OutlinedButton 
              sx={{
                width: '260px', height: '55px',
                mt: '0px',
                }}>Начать</OutlinedButton>
            </NavLink> 
            :
            <TypographyPrimary sx={{color: COLORS.icon}}>Станет доступным после заполнения “Шаг {+props.stepNum - 1}”.</TypographyPrimary>
            }
          </Box>
        </CardContent>
      </CardStandart>
  )
}

export default FarmFillLink