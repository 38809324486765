import { Box } from '@mui/material'
import React from 'react'
import { fetchGroupList } from '../../store/groupsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { NavLink, useParams } from 'react-router-dom'
import FarmFillGroupListItem from './FarmFillGroupListItem'
import { ButtonUI } from '../../ui/ButtonUI'

const FarmFillGroupList = () => {

  const { groupList } = useAppSelector(store => store.groupsReducer)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(fetchGroupList())
  }, [])

  return (<Box>
    {groupList.map((group) => 
     <FarmFillGroupListItem id={group.id} title={group.title} />
    )}
    <NavLink to='/fill/1/feedplan'>
            <Box display='flex' justifyContent='flex-end'><ButtonUI sx={{
              width: '600px',
              height: '55px',
              mt: '48px',
              textTransform: 'none',
          }} disabled={groupList.length === 0} >Сохранить и перейти к следующему шагу</ButtonUI></Box>
          </NavLink>
    </Box>
  )
}

export default FarmFillGroupList