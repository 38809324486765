import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IGroupsAnalytics, ICameraData } from '../models/IGroup';
import axAPI from '../http'

export const fetchGroupAnalytics = createAsyncThunk(
    'groupAnalytics/analytics',
    async function (_, { rejectWithValue }) {
        try {
            const response = await axAPI({
                method: 'GET',
                url: 'cam_data_raw/',
            })

            if (response.status!==200) {
                throw new Error('HTTP request error!')
            }
            return response.data
        } catch (error) {
            let errorMessage = 'Failed to retrive groups!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

export const fetchGroupAnalyticsAggragated = createAsyncThunk(
    'groupAnalytics/analyticsAggregated',
    async function (_, { rejectWithValue }) {
        try {
            const response = await axAPI({
                method: 'GET',
                url: 'cam_data_agg/',
            })

            if (response.status!==200) {
                throw new Error('HTTP request error!')
            }
            return response.data
        } catch (error) {
            let errorMessage = 'Failed to retrive groups!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

const initialState: IGroupsAnalytics = {
    lineBarData: [],
    lineBarDataAggragated: [],
    analyticsLoading: false,
}

export const groupsAnalyticsSlice = createSlice({
    name: 'groupAnalytics',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchGroupAnalytics.fulfilled.type]: (state, action: PayloadAction<ICameraData[]>) => {
            state.analyticsLoading = false;
            state.analyticsError = ''
            state.lineBarData = action.payload;
        },
        [fetchGroupAnalytics.pending.type]: (state) => {
            state.analyticsLoading = true;
        },
        [fetchGroupAnalytics.rejected.type]: (state, action: PayloadAction<string>) => {
            state.analyticsLoading = false;
            state.analyticsError = action.payload
        },
        [fetchGroupAnalyticsAggragated.fulfilled.type]: (state, action: PayloadAction<ICameraData[]>) => {
            state.analyticsLoading = false;
            state.analyticsError = ''
            state.lineBarDataAggragated = action.payload;
        },
        [fetchGroupAnalyticsAggragated.pending.type]: (state) => {
            state.analyticsLoading = true;
        },
        [fetchGroupAnalyticsAggragated.rejected.type]: (state, action: PayloadAction<string>) => {
            state.analyticsLoading = false;
            state.analyticsError = action.payload
        },
    }
})

export default groupsAnalyticsSlice.reducer;