import Box from '@mui/material/Box/Box'
import React, { useEffect, useState } from 'react'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { TypographyPrimary } from '../../ui/TypographyPrimary'

import InputFormField from '../shared/InputFormField'
import EditPlanTableDistribution from './EditPlanTableDistribution'
import GroupsApiService from '../../services/groupsApiService'


const EditPlanPageDistribution = ({ feedPlanDistribution, setAdapterFeedplan, setAdapterNudgePlan, setFeedPlanDistribution, setDeviationArray }) => {
	const [groupList, setGroupList] = useState([])
		const deviationValueForDistribution = feedPlanDistribution[0]?.deviation

		useEffect (() => {
			GroupsApiService.getGroupList().then(response => {
				setGroupList(response.data)
			})
		},[])
	return (
		<Box
			sx={{
				width: 'border-box',
				padding:'24px',
				boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
				backgroundColor: 'white',
				borderRadius: '12px',
				overflowX:'hidden'
			}}
		>
		
				<Box sx={{ mb: '24px', }}>
					<TypographyHeader> Раздача корма </TypographyHeader>
				</Box>
				<TypographyPrimary sx={{ marginBottom:'30px'}}>
					Время раздачи корма задается отдельно для каждой группы в назначенное
					время. Сумма % веса раздач всех компонентов должна составлять 100%.
				</TypographyPrimary>
				<Box
					display='flex'
					flexDirection='row'
					alignItems='center'
					sx={{gap:'15px'}}
				>
					<TypographyPrimary sx={{}}>
						Допустимое отклонение во времени выполнения
					</TypographyPrimary>
					<InputFormField type='distr' value={deviationValueForDistribution} setAdapterNudgePlan={setAdapterNudgePlan} setFeedPlanDistribution={setFeedPlanDistribution} setDeviationArray={setDeviationArray}/>
				</Box>
				<Box sx={{ overflowX: 'auto', }}>
          <EditPlanTableDistribution feedPlanDistribution={feedPlanDistribution}setAdapterFeedplan={setAdapterFeedplan} groupList={groupList}/>
        </Box>
			
		</Box>
	)
}

export default EditPlanPageDistribution
