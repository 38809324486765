import { Box, Typography } from '@mui/material'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { TypographySecondary } from '../../ui/TypographySecondary'

import { ReactComponent as FoodIcon } from '../../assets/food_icon.svg'
import { ReactComponent as TimeIcon } from '../../assets/clock_group_logo.svg'
import { ReactComponent as CameraIcon } from '../../assets/translation_deviations_logo.svg'

import moment from 'moment'

const DeviationItem = props => {

	return (
		<Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '24px' }}>
				<Box sx={{ marginRight: '15px' }}>
					{props.item.type === 'ration' ? <FoodIcon /> : <></>}
					{props.item.type === 'feedplan' ? <TimeIcon /> : <></>}
					{props.item.type === 'camera' ? <CameraIcon /> : <></>}
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: '4px',
						}}
					>
						<Typography sx={{fontWeight: '600', fontSize:'16px', lineHeight:'19.5px'}}>{props.item.title}</Typography>
						<Typography sx={{fontWeight: '400', fontSize:'14px', lineHeight:'16.8px'}}>
							{moment(props.item.created_dt).format('DD MMMM HH:mm')}
						</Typography>
					</Box>
					<Typography sx={{fontWeight: '400', fontSize:'14px', lineHeight:'16.8px'}}>{props.item.description}</Typography>
				</Box>
			</Box> 
		</Box>
	)
}

export default DeviationItem
