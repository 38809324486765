import { Box, Card, CardContent, Typography } from '@mui/material'

import InputField from '../shared/InputField'
import CreatePlanListDistributionContent from './CreatePlanListDistributionContent'

import { COLORS } from '../../styles/colors'

import { setFeedPlanEventDeviation } from '../../store/feedPlanSlice'
import { useAppDispatch } from '../../store/hooks'
import { PlanEventType, PlanEventTypeEnum } from '../../models'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { TypographyHeader } from '../../ui/TypographyHeader'

const CreatePlanListDistribution = (props: PlanEventType) => {
	const dispatch = useAppDispatch()

	return (
		<Card
			sx={{
				maxWidth: props.isFirstTry ? '100%' : '97%',
				boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
				backgroundColor: 'white',
				borderRadius: '12px',
			}}
		>
			<CardContent>
				<Box sx={{ pt: '8px', pl: '16px' }}>
					<TypographyHeader> Раздача корма </TypographyHeader>
				</Box>
				<Box
					display='flex'
					flexDirection='row'
					alignItems='center'
					sx={{ pt: '24px', pl: '16px' }}
				>
					<TypographyPrimary sx={{ pr: '16px' }}>
						Допустимое отклонение по времени
					</TypographyPrimary>
					<InputField
						key='inputFieldDeviationDistribution'
						label='Минуты'
						type='number'
						value={props.deviation.toString()}
						onBlur={value =>
							dispatch(
								setFeedPlanEventDeviation({
									deviation: value,
									eventId: PlanEventTypeEnum.Distribution,
								})
							)
						}
						sx={{
							width: '120px',
							height: '54px',
							'& .MuiOutlinedInput-root': {
								'&.Mui-focused fieldset': {
									borderColor: COLORS.secondaryFontOpacity,
								},
							},
						}}
					/>
				</Box>
				<CreatePlanListDistributionContent />
			</CardContent>
		</Card>
	)
}

export default CreatePlanListDistribution
