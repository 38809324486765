import { Box } from '@mui/material'
import React from 'react'
import {ReactComponent as ChartLegendLogo} from '../assets/chart_legend_logo.svg'
import { COLORS } from '../styles/colors'
import { TypographyPrimary } from './TypographyPrimary'

const ChartLegend = (props) => {
  return (
    <Box display='flex' flexDirection='row' sx={{pr: '16px'}}>
        <ChartLegendLogo fill={props.fill} />
        <TypographyPrimary sx={{pl: '8px', color: COLORS.icon}}>{props.description}</TypographyPrimary>
    </Box>
  )
}

export default ChartLegend