import { Box } from '@mui/material'
import React from 'react'

import { TypographyHeaderExtra } from '../../ui/TypographyHeaderExtra'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import FarmFillLink from './FarmFillLink'

const needableData = [
  {stepNum: '1', title: 'Рационы', link: 'ration', clicked: true},
  {stepNum: '2', title: 'Группы', link: 'groups', clicked: false},
  {stepNum: '3', title: 'План кормления', link: 'feedplan', clicked: false},
]


const FarmFillPage = () => {

  
  return (
    <Box sx={{pt: '24px', pr: '150px'}}>
      <TypographyHeaderExtra>
        Ферма 1
      </TypographyHeaderExtra>
      <TypographyPrimary sx={{pt: '24px'}}>
      Заполните данные по рационам, группам и плану кормления.
      </TypographyPrimary>
      {needableData.map((step) => <FarmFillLink stepNum={step.stepNum} title={step.title} link={step.link} clicked={step.clicked} key={step.stepNum} />)}
    </Box>
  )
}

export default FarmFillPage