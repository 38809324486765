import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'

import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'

import { Box, IconButton } from '@mui/material'
import React from 'react'
import axAPI from '../../http'
import { SchedulerDataType } from '../../models'
import { COLORS } from '../../styles/colors'
import { TypographyHeader } from '../../ui/TypographyHeader'
import GroupDate from './GroupDate'
import GroupSpecificPlanTable from './GroupSpecificPlanTable'
import { sxButton, sxList } from './utils/headChartStyles'

export default function GroupSpecificFeedPlan(props) {
	const [open, setOpen] = React.useState(false)
	const [color, setColor] = React.useState(COLORS.secondaryFont)

	const [feedPlanDate, setFeedPlanDate] = React.useState<Date | null>(
		new Date()
	)
	const [scheduledEvents, setScheduledEvent] =
		React.useState<SchedulerDataType>()

	React.useEffect(() => {
		axAPI
			.get(`feedplan/schedule/${feedPlanDate.toISOString().slice(0, 10)}`)
			.then(response => setScheduledEvent(response.data))
	}, [feedPlanDate])

	if (!scheduledEvents) return <></>

	const factScheduleData = [
		...scheduledEvents.done,
		...scheduledEvents.active,
		...scheduledEvents.future,
	]

	const groupSortedData = factScheduleData.filter(
		el => el.groups.some(el => el.group_id === props.id)
	)

	const sxBtn = sxButton(color)

	const sx = sxList(open)

	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<List sx={sx}>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ pt: '12px', pb: '12px' }}
			>
				<ListItemButton
					disableRipple
					disableTouchRipple
					sx={{ '&:hover': { backgroundColor: 'transparent' } }}
					onClick={handleClick}
				>
					<TypographyHeader>План кормления</TypographyHeader>
				</ListItemButton>
				<Box
					display='flex'
					flexDirection='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<Box display='flex' flexDirection='row'>
						{/* <OutlinedButton 
                            onMouseOver={() => setColor(COLORS.main)} 
                            onMouseLeave={() => setColor(COLORS.secondaryFont)} 
                            sx={sxBtn}
                            startIcon={<AnalyticsDownloadLogo 
                                onMouseOver={() => setColor(COLORS.main)} 
                                onMouseLeave={() => setColor(COLORS.secondaryFont)} 
                                stroke={color} />}
                            >
                                Экспорт
                        </OutlinedButton> */}
						<Box sx={{ pl: '24px', pr: '16px' }}>
							<GroupDate
								value={feedPlanDate}
								onChange={newDate => setFeedPlanDate(newDate)}
							/>
						</Box>
					</Box>
				</Box>
				<IconButton
					disableRipple
					disableTouchRipple
					onClick={handleClick}
					sx={{
						pr: '16px',
						pt: '8px',
						'&:hover': { backgroundColor: 'transparent' },
					}}
				>
					{open ? <ArrowOpenDone /> : <ArrowCloseDone />}
				</IconButton>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<ListItemButton
						sx={{ pl: 2, '&:hover': { backgroundColor: 'transparent' } }}
						disableRipple
						disableTouchRipple
					>
						<Box sx={{ width: '99%' }}>
							<GroupSpecificPlanTable
								groupId={props.id}
								groupData={groupSortedData}
							/>
						</Box>
					</ListItemButton>
				</List>
			</Collapse>
		</List>
	)
}
