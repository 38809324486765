import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Card, CardContent } from '@mui/material';
import { Box } from '@mui/system'
import React from 'react'
import { NavLink } from 'react-router-dom';
import { TypographyHeader } from '../../ui/TypographyHeader';
import { TypographySecondary } from '../../ui/TypographySecondary';



const SettingsManagmentList = () => {
  return <NavLink to='management'><Card sx={{
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)', 
    backgroundColor: 'white',
    borderRadius: '12px',
    '&:hover': {boxShadow: '0px 4px 16px 0px rgba(82, 34, 208, 0.24)'  },
    }}>
    <CardContent>
      <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{pt: '12px', pl: '12px'}}>
        <TypographyHeader>Управление пользователями</TypographyHeader>
        <NavLink to='management'><ArrowForwardIosIcon sx={{ color: "#7F7F84"}}/></NavLink>
      </Box>
      <Box sx={{pt: '24px', pl: '12px'}}>
        <TypographySecondary>Создавайте и редактируйте пользователей</TypographySecondary>
      </Box>
    </CardContent>
  </Card></NavLink>
}

export default SettingsManagmentList