import React from 'react'
import { COLORS } from '../../styles/colors'
import Box from '@mui/material/Box/Box'
import { ReactComponent as PlusIcon } from '../../assets/plan_edit_plus_logo.svg'

const CreateButtonTimeNudge = ({onClick}) => {
	return (
		<Box
		component={'button'}
		disabled={false}
		onClick={onClick}
			sx={{
				transition: 'all 0.1s ease-in-out',

				display:'flex',
				alignItems:'center',
				justifyContent:'center',
				cursor:'pointer',
				width: '120px',
				backgroundColor: 'white',
				height: '50px',
				border: '1px solid #DBDBDB',
				borderRadius: '12px',
				color: COLORS.secondary,
				'&:hover': {
					color: COLORS.main,
					borderColor: COLORS.main,
					backgroundColor: 'white',
				},
				'&:active': {
				opacity: '0.5'
				},
			}}
		>
			<PlusIcon />
		</Box>
	)
}

export default CreateButtonTimeNudge
