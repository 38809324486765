import { COLORS } from "../../../styles/colors";

export const sxTypography = {
  fontSize: 12, fontWeight: 400, 
  color: COLORS.secondary, 
  display: 'flex', alignItems: 'center', justifyContent: 'flex-end', 
  marginTop: '-15px', marginRight: '-10px', 
  pt: '4px', pl: '4px'}

  export const sxCountTypography = {
    fontSize: 18, 
    fontWeight: 600,
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'flex-start',
    color: COLORS.black
  }