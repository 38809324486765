import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axAPI from '../http'
import { IUserData, IUserSlice } from '../models/IUserData'

export const fetchUserData = createAsyncThunk(
    'user/getdata',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axAPI({
                method: 'GET',
                url: 'auth/users/me/',
            })

            if (response.status !== 200) {
                throw new Error('HTTP request error!')
            }
            return response.data

        } catch (error) {
            let errorMessage = 'Failed to retrieve user data!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

export const fetchUsersList = createAsyncThunk(
    'user/getuserslist',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axAPI({
                method: 'GET',
                url: 'auth/users/',
            })

            if (response.status !== 200) {
                throw new Error('HTTP request error!')
            }
            return response.data

        } catch (error) {
            let errorMessage = 'Failed to retrieve users list!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

const initialState: IUserSlice = {
    userDataPending: false,
    userData: {
        username: '',
        email: '',
        firstname: '',
        lastname: '',
        superuser: false,
        is_active: false,
    }
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserActivityFlag(state, action) {
            state.usersList.forEach((el) => {
                if (el.id === action.payload.userId) el.is_active = action.payload.active
            })
        }
    },
    extraReducers: {
        [fetchUserData.fulfilled.type]: (state, action: PayloadAction<IUserData>) => {
            state.userDataPending = false;
            state.userData = action.payload;
        },
        [fetchUserData.pending.type]: (state) => {
            state.userDataPending = true;
            state.userDataError = '';

            state.userData.username = ''
            state.userData.email = ''
            state.userData.firstname = ''
            state.userData.lastname = ''
            state.userData.superuser = false
        },
        [fetchUserData.rejected.type]: (state, action: PayloadAction<IUserSlice>) => {
            state.userDataPending = false;
            state.userDataError = action.payload.detail;
        },
        [fetchUsersList.fulfilled.type]: (state, action: PayloadAction<IUserData[]>) => {
            state.userDataPending = false;
            state.usersList = action.payload;
        },
        [fetchUsersList.pending.type]: (state) => {
            state.userDataPending = true;
            state.userDataError = '';
        },
        [fetchUsersList.rejected.type]: (state, action: PayloadAction<IUserSlice>) => {
            state.userDataPending = false;
            state.userDataError = action.payload.detail;
        },
    }
})

export default userSlice.reducer
export const { setUserActivityFlag } = userSlice.actions