import { COLORS } from "../../../styles/colors";

export const sxButton = (color) => {
  return {
  width: '128px', 
  height: '40px', 
  margin: '0px', 
  color: color, 
  border: `1px solid ${COLORS.secondaryFontOpacity}`,
  textTransform: 'none',
  fontWeight: 600,
  '&:hover': {
      backgroundColor: 'transparent',
      color: color,
      borderColor: COLORS.main,
      boxShadow: 'none'
    }
  }
}

export const sxList = (open) => {
  return {
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)', 
    backgroundColor: 'white', 
    borderRadius: '12px',
    pl: '8px', pr: '8px',
    '&:hover': {boxShadow: open ? '0px 4px 16px 0px rgba(0, 0, 0, 0.08)' :'0px 4px 16px 0px rgba(82, 34, 208, 0.24)'  },
  }
}