import { Card, Divider } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { COLORS } from '../../styles/colors'
import { TypographyHeader } from '../../ui/TypographyHeader'

type FarmFillStepIndexProps = {
    stepNum: string;
    fullfied: '1' | '2' | '3'
}

const FarmFillStepIndex = (props: FarmFillStepIndexProps) => {
  return (
    <Card sx={{mt: '12px', borderRadius: '12px', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)'}}>
              <TypographyHeader sx={{pt: '24px', pl: '24px', pb: '24px'}}>
                  Шаг {props.stepNum} из 3
              </TypographyHeader>
              <Box display='flex' flexDirection='row' sx={{pb: '24px', mr: '12px'}}>
                <Divider sx={{borderBottomWidth: 4, width: '32%', borderColor: COLORS.main, borderRadius: '12px', marginRight: '8px', ml: '24px',}} />
                <Divider sx={{borderBottomWidth: 4, width: '32%', borderColor: props.fullfied === '2' || props.fullfied === '3' ?  COLORS.main : COLORS.secondaryFontOpacity, borderRadius: '12px', marginRight: '8px',}} />
                <Divider sx={{borderBottomWidth: 4, width: '32%', borderColor: props.fullfied === '3' ? COLORS.main : COLORS.secondaryFontOpacity, borderRadius: '12px', marginRight: '24px',}} />
              </Box>
          </Card>
  )
}

export default FarmFillStepIndex