import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Grid, Stack, Typography, Button, CircularProgress, ClickAwayListener, CssBaseline, IconButton, Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/system';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchGroupList } from '../store/groupsSlice';
import { fetchUserData } from '../store/userSlice';
import { fetchFeedPlanData } from '../store/feedPlanSlice';

import {ReactComponent as BarchartInfoLogo} from '../assets/barchart_info_logo.svg'

import StackedBarChart from '../components/StackedBarChart';
import CowCounter from '../components/feed/CowCounter';
import GetPoolCards from '../components/feed/GetPoolCards';
import GetCardItem from '../components/feed/GetCardItem';
import SimpleCard from '../components/feed/SimpleCard';
import GroupHeadPopup from '../components/shared/GroupHeadPopup';

import { fontTheme } from '../styles/font';
import { COLORS } from '../styles/colors';

import { TypographyPrimary } from '../ui/TypographyPrimary';
import ChartLegend from '../ui/ChartLegend';
import { CardStandart } from '../ui/CardStandart';


const BarchartTooltip = ( ) => {
    return <Box >
        <Box display='flex' flexDirection='row' sx={{pb: '12px', pt: '12px'}} alignItems='center'>
          <TypographyPrimary>График отображает отношение корма в зоне доступности к корму в зоне недоступности на текущий момент времени для каждой группы. 
              Объем корма на данном графике не показывается.</TypographyPrimary>
        </Box>
    </Box>
  }

const FeedGroupsPage = () => {
    
    const dispatch = useAppDispatch()
    const { groupList, loading, error } = useAppSelector(state => state.groupsReducer)

    useEffect(() => {
        dispatch(fetchGroupList())
        dispatch(fetchUserData())
        dispatch(fetchFeedPlanData({}))
    }, [])

    const [open, setOpen] = React.useState(false);
  
    const handleCloseTooltip = () => {
      setOpen(false)
    }

    if (loading || groupList.length === 0) {
        return <>
            <Box
                sx={{
                    marginTop: '25%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CircularProgress sx={{ '&.MuiCircularProgress-colorPrimary': { color: COLORS.main } }} />
            </Box>
        </>
    } else if (error) {
        <>
            {error}
        </>
    }

    const theme = fontTheme

    return <ThemeProvider theme={theme}>
        <CssBaseline />
        <CowCounter />
        <Grid container spacing={{ md: 3 }} sx={{ fontFamily: theme.typography.fontFamily, pl: '16px', pr: '32px' }} >
            <Grid item md={12}>
                <Stack direction="row" spacing={3} >
                    <Box sx={{width: '33%'}}>
                        <GetPoolCards />
                    </Box>
                    <Box sx={{width: '33%'}}>
                        <GetCardItem title='План кормления на день' />
                    </Box>
                    <Box sx={{width: '33%'}}>
                        <SimpleCard title='Обнаруженные отклонения' />
                    </Box>
                </Stack>
            </Grid>
            <Grid item md={12} >
                <CardStandart sx={{ '&:hover': {boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)'} }}>
                        <Box 
                            sx={{ fontSize: '20px', fontWeight: 'bold', 
                                pl: '16px', pt: '10px', 
                                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between' 
                                }}>
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>Доступность корма 
                            <ClickAwayListener onClickAway={ () => setOpen(false) }>
                                <Box>
                                    <Tooltip
                                        componentsProps={{ 
                                            tooltip: { 
                                                sx: { 
                                                    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)', backgroundColor: 'white', 
                                                    borderRadius: '8px', maxWidth: '600px' 
                                                }}}}
                                        PopperProps={{
                                          disablePortal: true,
                                        }}
                                        onClose={ () => setOpen(false) }
                                        open={open}
                                        placement='bottom-end'
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={<Button 
                                          onClick={handleCloseTooltip} 
                                          sx={{
                                            textTransform: 'none',
                                            textAlign: 'left',
                                            '&:hover': {
                                              backgroundColor: 'transparent'
                                            }
                                          }}><BarchartTooltip /></Button>}
                                        >
                                            <IconButton sx={{mt: '2px'}} onClick={() => setOpen(!open)}>
                                              <BarchartInfoLogo stroke={COLORS.secondaryFont}  />
                                            </IconButton>
                                        </Tooltip>
                                </Box>
                            </ClickAwayListener>
                            </Box>
                            <Box display='flex' flexDirection='row'>
                                <ChartLegend fill={COLORS.success} description='Корм доступен' />
                                <ChartLegend fill={COLORS.deviationFont} description='Корм недоступен' />
                                <ChartLegend fill={COLORS.icon} description='Кормовой стол пустой' />
                            </Box>
                        </Box>
                        <Box sx={{ p: '12px', }}><StackedBarChart /></Box>
                </CardStandart>
            </Grid>
            <GroupHeadPopup />
        </Grid>
        <Outlet />
    </ThemeProvider>
}

export default FeedGroupsPage