import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { OutlinedButton } from '../../ui/OutlinedButton';
import { TypographyHeader } from '../../ui/TypographyHeader';
import { TypographyPrimary } from '../../ui/TypographyPrimary';
import { COLORS } from '../../styles/colors';

import { useAppDispatch } from '../../store/hooks'
import { notifyUser } from '../../store/notificationsSlice'

import axAPI from '../../http'
import { Box } from '@mui/material';

type ManagementPopupProps = {
  userId?: string;
  active: boolean;
  open: boolean;
  userName?: string;
  onClose?: any;
  handleClose?: () => void;
  title: string;
  status: string;
} 

const ManagementPopup = (props: ManagementPopupProps) => {

  const dispatch = useAppDispatch()

  const setActivityFlagAtServer = () => {
    axAPI({
      method: 'POST',
      url: 'auth/set_activity',
      data: { id: props.userId, active: !props.active }
    }).then(response => {
      if (response.status === 202) {
        dispatch(notifyUser({
          title: !props.active ? 'Восстановление пользователя' : 'Удаление пользователя',
          description: `Пользователь ${props.userName} ${!props.active ? 'восстановлен' : 'удален'}`,
          isError: false,
        }))
        props.handleClose()
      }
    })
  }

  if (!props.open) return <></>

  return <Box><Dialog open={props.open} onClose={props.onClose}  scroll='body' >
    <DialogTitle ><TypographyHeader align='center' sx={{ pt: '16px' }} >{props.title} пользователя</TypographyHeader></DialogTitle>
    <DialogContent>
      <DialogContentText>
        <TypographyPrimary align='center'>
          {
            !props.active
              ? `Вы уверены, что хотите восстановить пользователя “${props.userName}”?`
              : `Вы уверены, что хотите удалить пользователя “${props.userName}”?
            Профиль перейдет в неактивые пользователи, его всегда можно восстановить.`
          }
        </TypographyPrimary>
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{ pb: '32px' }}>
      <OutlinedButton
        sx={{
          margin: 0,
          ml: '16px',
          color: COLORS.deviationFont,
          border: `2px solid ${COLORS.deviationFont}`,
          "&:hover": {
            color: COLORS.deviationOpacity,
            border: `2px solid ${COLORS.deviationOpacity}`,
            backgroundColor: 'transparent',
            boxShadow: 'none'
          },
        }} onClick={props.onClose}>Отмена</OutlinedButton>
      <OutlinedButton sx={{ margin: 0, mr: '16px', pl: '8px' }} onClick={ setActivityFlagAtServer }>{props.status}</OutlinedButton>
    </DialogActions>
  </Dialog>
  </Box>
}

export default ManagementPopup