import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Autocomplete, createFilterOptions, IconButton, InputAdornment, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import React from 'react'
import { COLORS } from '../../styles/colors'



type TypeRationTextFieldProps = {
  label: string;
  key?: string;
  id?:any;
  sx?: any;
  value?: any;
  isPassword?: boolean;
  helperText?: string;
  isError?: boolean;
  marginLeft?: string;
  isErrorFocus?: boolean;
  onChange?: (e) => void;
  onBlur?: any;
  onFocus?: any;
  choiceList?: any
}

export const RationTextField = (props: TypeRationTextFieldProps) => {
  const [showPass, setShowPass] = React.useState(!props.isPassword)

  const [focused, setFocused] = React.useState(false)

  let sx = {width: '500px', mt: '32px', minHeight: '72px', ml: props.marginLeft, '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: props.isErrorFocus ? COLORS.deviationFont : '#ced4da',
      borderWidth: props.isErrorFocus ? '2px' : '1px',
    },
    '&:hover fieldset': {
      borderColor:  props.isErrorFocus ? COLORS.deviationFont : 'black'
    },
    '&.Mui-focused fieldset': {
      borderColor: props.isErrorFocus ? COLORS.deviationFont : COLORS.main,
    },
  },
  '&.MuiOutlinedInput-notchedOutline': {
    '&.Mui-focused fieldset': {
      borderColor: COLORS.main,
    },
  },
}

  const handleBlur = () => {
    setFocused(false)
    props.onBlur()
  }

  const handleFocus = () => {
    setFocused(true)
    props.onFocus()
  }

  return <TextField 
    helperText={props.isErrorFocus ? props.helperText : ''}
    margin="dense"
    key={props.id}
    id={props.id}
    label={props.label}
    value={props.value}
    onChange={props.onChange}
    onBlur={handleBlur}
    onFocus={handleFocus}
    type={showPass ? "text" : "password"}
    sx={props.sx ? props.sx : sx}
    variant="outlined"
    FormHelperTextProps={{
      style: { fontSize: '12px', lineHeight: '15px', fontWeight: 400, color: props.isErrorFocus ? COLORS.deviationFont : COLORS.icon, }
    }}
    InputProps={{
      style: { fontSize: '14px', lineHeight: '17px', fontWeight: 400, color: props.isError ? COLORS.deviationFont : COLORS.black, borderRadius: '8px', borderColor: focused ? COLORS.main : COLORS.icon},
      endAdornment: props.isPassword ? (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPass(!showPass)}
          >
            {showPass ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment> 
      ) : <></>
    }}
    InputLabelProps={{
      style: { fontSize: '14px', lineHeight: '17px', fontWeight: 400, color: props.isErrorFocus ? COLORS.deviationFont : (focused ? COLORS.main : COLORS.icon), }
    }}
  />
}

type TypeRationSelectFieldProps = {
  label: string
  sx?: any
  key: string
  value: string | number
  onChange?: any
  choiceList?: any
  isErrorFocus?: boolean
  title?: string | number
  inputValue?: string;
}
const filter = createFilterOptions<TypeRationSelectFieldProps>();


export const RationSelectField = (props: TypeRationSelectFieldProps) => {
  const sx = {
    width: '64.3vw', '& .MuiOutlinedInput-root': {
      borderColor: props.isErrorFocus ? COLORS.deviationFont : COLORS.secondaryFontOpacity,
      '&.Mui-focused fieldset': {
        borderColor: props.isErrorFocus ? COLORS.deviationFont : COLORS.secondaryFontOpacity,
      },
    },
    '&.MuiOutlinedInput-notchedOutline': {
      borderColor: props.isErrorFocus ? COLORS.deviationFont : COLORS.secondaryFontOpacity,
    },
    "& .MuiOutlinedInput-notchedOutline legend": { display: "none", }
  }
  return (
    <>
      {/* <Autocomplete
      value={props.value}
      onChange={props.onChange}
      filterOptions={(options, params) => {
        

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          props.choiceList.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return props.choiceList;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option=== 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={props.choiceList.map(el => el.title)}
      renderOption={(option) => <li {...props}> {option.title}</li>}
      style={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="Компонент" variant="outlined" />
      )}
    />
  ); */}

  <TextField
    error={props.isErrorFocus}
    select
    margin="dense"
    key={props.key}
    label={props.label}
    value={props.value}
    onChange={props.onChange}
    type="text"
    sx={props.sx ? props.sx : sx}
    variant="outlined"
    InputProps={{
      style: { fontSize: '14px', lineHeight: '17px', fontWeight: 400, color: props.isErrorFocus ? COLORS.deviationFont : COLORS.black, borderRadius: '8px' }
    }}
    InputLabelProps={{
      style: { fontSize: '14px', lineHeight: '17px', fontWeight: 400, color: props.isErrorFocus ? COLORS.deviationFont : COLORS.icon, }
    }}
  >
    {
      props.choiceList.map((option) => {
        return <MenuItem key={option.key} value={option.title}>
          {option.title}
        </MenuItem>
      })
    }
  </TextField>
  </>)
}