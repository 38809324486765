import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import {ReactComponent as FillRationPlusLogo} from '../../assets/fill_ration_plus_logo.svg'

import { TypographyPrimary } from '../../ui/TypographyPrimary';
import { NavLink, useNavigate } from 'react-router-dom';
import { COLORS } from '../../styles/colors';
import { TypographyHeaderExtra } from '../../ui/TypographyHeaderExtra';
import { OutlinedButton } from '../../ui/OutlinedButton';
import { ButtonUI } from '../../ui/ButtonUI';
import RationGrid from '../ration/RationGrid';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchRationList } from '../../store/rationSlice';
import RationEditPopup from '../ration/RationEditPopup';
import { TypographyHeaderSecondary } from '../../ui/TypographyHeaderSecondary';
import FarmFillStepIndex from './FarmFillStepIndex';
import FarmFillBack from './FarmFillBack';

const FarmFillRation = () => {

  const [color, setColor] = useState('#5222D0')

  const colorHandlerOver = () => {
    setColor('white')
  }

  const colorHandlerOut = () => {
    setColor('#5222D0')
  }

  const dispatch = useAppDispatch()
  const { rationList }  = useAppSelector(store => store.rationReducer)

  const navigate = useNavigate()

  useEffect(() => { 
    dispatch(fetchRationList())
  }, [])

  if (!rationList) return <></>


  return (
      <Box sx={{pr: '100px'}}>
        <FarmFillBack prevStep='/' />
        <FarmFillStepIndex stepNum='1' fullfied='1' />
          
        <Box sx={{pt: '60px'}}>
            <TypographyHeaderExtra>
                Рационы
            </TypographyHeaderExtra>
            <TypographyPrimary sx={{pt: '24px', pb: '32px'}}>
              Создайте рационы, которые используются на ферме.
            </TypographyPrimary>
            
            <RationGrid tableData={ rationList } />
            
            <OutlinedButton onMouseOver={colorHandlerOver} onMouseOut={colorHandlerOut} onClick={()=>{navigate('/fill/1/ration/create')}}
              sx={{
                width: '100%',
                height: '48px',
                mt: '24px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              
              >
                <Box></Box>
                <TypographyHeaderSecondary sx={{color: color}}>{rationList.length === 0 ? 'Создать' : 'Добавить'} рацион</TypographyHeaderSecondary>
                <Box sx={{pt: '4px', pr: '16px'}}><FillRationPlusLogo stroke={color}  /></Box>
              </OutlinedButton>
              <RationEditPopup isFirstTry />
          </Box>
          <NavLink to='/fill/1/groups'>
            <Box display='flex' justifyContent='flex-end'><ButtonUI sx={{
              width: '600px',
              height: '55px',
              mt: '48px',
              textTransform: 'none',
          }} disabled={rationList.length === 0} >Сохранить и перейти к следующему шагу</ButtonUI></Box>
          </NavLink>
      </Box>
  )
}

export default FarmFillRation