import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchRationList } from '../../store/rationSlice';

import RationGrid from './RationGrid'
import RationTable from './RationTable'


const RationList = ({grid}) => {

  const dispatch = useAppDispatch()
  const { rationList } = useAppSelector(store => store.rationReducer)

  useEffect(() => { 
    //if (!rationList) dispatch(fetchRationList())
    dispatch(fetchRationList())
  }, [])

  if (!rationList) return <></>

  return <>
    {grid ? <RationGrid tableData={ rationList } /> : <RationTable tableData={ rationList } />}
  </>
}

export default RationList