import { Paper, styled } from "@mui/material";


export const Element = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    borderLeft: '2px solid black',
    borderRadius: 0,
    boxShadow: "0 0 0 0",
    padding: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '40px'
  }));