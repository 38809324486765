import { Grid } from '@mui/material'
import RationItem from './RationItem'

import { IRationEntity, SchedulerEventDataType } from '../../models'

const RationGrid = ({ tableData }) => {
	return (
		<Grid
			container
			direction='column'
			sx={{ pt: '24px' }}
		>
			{tableData.map((tableRow: IRationEntity & SchedulerEventDataType) => (
				<Grid
					item
					key={tableRow.key}
				>
					<RationItem total_distribution_weight={tableRow.total_distribution_weight} rationKey={tableRow.key} {...tableRow} />
				</Grid>
			))}
		</Grid>
	)
}

export default RationGrid
