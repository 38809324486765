import { createContext } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';

import { authLogin, authLogout, authSetPassword } from '../store/authSlice'

export const AuthContext = createContext<any>(null);

export const AuthProvider = ({ children }) => {

    const dispatch = useAppDispatch()
    const authState = useAppSelector(state => state.authReducer)

    const signInAndContinue = (username: string, password: string) => {
        dispatch(authLogin({ username, password }))
    }

    const signOutAndContinue = (cb: any) => {
        dispatch(authLogout());
        cb();
    }

    const setPasswordAndContinue = (current_password: string, new_password: string) => {
        dispatch(authSetPassword({current_password, new_password}))
    }
    
    const value = {
        token: authState.apiToken,
        signInAndContinue: signInAndContinue,
        signOutAndContinue: signOutAndContinue,
        setPasswordAndContinue: setPasswordAndContinue
    }
    
    return <AuthContext.Provider value={value}>
        { children }
    </AuthContext.Provider>
}