import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IGroupEntity, IGroups } from '../models/IGroup'
import axAPI from '../http'
import { RootState } from '.'

export const fetchGroupList = createAsyncThunk(
	'groups/fetchList',
	async function (_, { rejectWithValue }) {
		try {
			const response = await axAPI({
				method: 'GET',
				url: 'groups/list',
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrive groups!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const fetchGroupData = createAsyncThunk(
	'groups/fetchGroupData',
	async (groupKey: string, { rejectWithValue }) => {
		try {
			const response = await axAPI({
				method: 'GET',
				url: `groups/${groupKey}`,
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrive group data!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

const initialState: IGroups = {
	groupList: [],
	loading: false,
}

export const groupsSlice = createSlice({
	name: 'groups',
	initialState,
	reducers: {
		updateGroupsData: (state, action) => {
			state.groupList = state.groupList.map(group =>
				group.key === action.payload.key
					? { ...group, ...action.payload }
					: group
			)
		},
	},
	extraReducers: {
		[fetchGroupList.fulfilled.type]: (
			state,
			action: PayloadAction<IGroupEntity[]>
		) => {
			state.loading = false
			state.error = ''
			state.groupList = action.payload
		},
		[fetchGroupList.pending.type]: state => {
			state.loading = true
		},
		[fetchGroupList.rejected.type]: (state, action: PayloadAction<string>) => {
			state.loading = false
			state.error = action.payload
		},
		[fetchGroupData.fulfilled.type]: (
			state,
			action: PayloadAction<IGroupEntity>
		) => {
			state.loading = false
			state.error = ''
			state.currentGroup = action.payload
		},
		[fetchGroupData.pending.type]: state => {
			state.loading = true
		},
		[fetchGroupData.rejected.type]: (state, action: PayloadAction<string>) => {
			state.loading = false
			state.error = action.payload
		},
	},
})
export const { updateGroupsData } = groupsSlice.actions
export default groupsSlice.reducer
