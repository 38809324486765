import { useState } from "react"

import { TextField } from "@mui/material"
import { COLORS } from "../../styles/colors"


import { feedPlanFactDataOnChange } from '../../store/feedPlanSlice'
import { notifyUser } from '../../store/notificationsSlice'
import { useAppDispatch } from '../../store/hooks';
import { PlanFeedFieldName, IDataOnChangeHandlerParameters, PlanCardFlagsEnum, EventTimeType, EventStatusEnum } from '../../models';
import { TypographyPrimary } from "../../ui/TypographyPrimary"
import moment from 'moment'


interface IPlanFeedFieldProps {
  type?: string;
  maxValue?: number;
  label?: string;
  value?: string | number
  fieldValue?: number | null
  fieldName: PlanFeedFieldName
  groupId: number
  planId: number
  dispatchUpdate?: any
  feedPlanDate: Date | null 
  metaFlags: boolean
  componentTimeType?: string
  autoComplete?: string
  isFutureDate: boolean
}


export const PlanFeedField = (props: IPlanFeedFieldProps) => {
  const [clicked, setClicked] = useState(false)
  const [fieldValue, setFieldValue] = useState(props.value !== null ? props.value : '')
  const dispatch = useAppDispatch()
  
  const fieldValueHandler = (e) => {
    let value = e.target.value
    if (props.type === 'number') {
      if (Number(value) < 0) {
        value = '';
      }
      if (Number(value) > props.maxValue) value = props.maxValue
    }
    setFieldValue(value)
  }
  const onChangeHandler = () => {
    if ((!props.value && !fieldValue) || Number(props.value) === Number(fieldValue) || fieldValue === '') return

    const params: IDataOnChangeHandlerParameters = {
      feedPlanDate: moment(props.feedPlanDate).format('YYYY-MM-DD'),
      feedPlanId: props.planId,
      // weight: props.fieldName === PlanFeedFieldName.WEIGHT ? fieldValue : 0,
      weight: fieldValue,
      feedGroupId: props.groupId,
    }
    dispatch(feedPlanFactDataOnChange({ ...params }))
    // it's need for overlap onClick text field event
    setTimeout(() => setClicked(false), 20)
    dispatch(notifyUser({
      title: 'Фактические данные',
      description: `Данные о весе сохранены`,
      isError: false,
    }))
  }

  const enterPressHandler = (e) => {
    if (e.key === 'Enter') {
      onChangeHandler()
      e.target.blur()
    }
  }

  const emptyLabel = ''

  const exceptSymbols = ["e", "E", "+", "-", ".", ","];
  return (
  <TextField
    key={`planFeedField${props.fieldName}${props.groupId}`}
    margin="dense"
    disabled={props.isFutureDate ? true : false}
    placeholder={props.label ? `  ${props.label}` : 'кг'}
    type={props.type ? props.type : 'text'}
    label={emptyLabel}
    value={fieldValue === null ? '' : fieldValue}
    autoComplete={props.autoComplete ? props.autoComplete : 'off'}
    onChange={fieldValueHandler}
    sx={{
      pr: '0px',
      width: '166px',
      height: '40px',
      '& .MuiOutlinedInput-notchedOutline': {
        pr: '0px',
        borderColor: props.metaFlags === true && fieldValue === '' ? COLORS.health : COLORS.secondaryFontOpacity
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: COLORS.main,
        },
      },
      '& .MuiOutlinedInput-input': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
           WebkitAppearance: 'none',
        },
      },
      "& .MuiOutlinedInput-notchedOutline legend": { display: "none", }
    }}
    onBlur={onChangeHandler}
    onKeyPress={enterPressHandler}
    onKeyDown={(e) =>
      exceptSymbols.includes(e.key) && e.preventDefault()
    }
    variant="outlined"
    InputProps={{
      endAdornment: (
        fieldValue !== '' ?
          (props.label === '%'
            ?
            <TypographyPrimary sx={{ position: 'absolute', ml: '45px', pb: '6px', color: COLORS.icon }}>%</TypographyPrimary>
            :
            (props.label === 'кг'
              ?
              <TypographyPrimary sx={{ position: 'absolute', ml: '40px', pb: '7px', color: COLORS.icon }}>кг</TypographyPrimary>
              :
              <></>)) : <></>
      ),
      inputProps: {
        style: {
          paddingLeft: '0px',
          paddingRight: props.label === 'кг' ? '80px' : '10px',
          paddingTop: '10px',
          display: 'flex',
          alignItems: 'center',
          textAlign: props.label === 'кг' ? 'right' : 'center',
          width: props.label ? '100px' : '115px', 
        },
      },
      style: {
        display: 'flex',
        justifyContent: 'center',
        height: '40px',
        paddingRight: '0px',
        fontSize: '14px',
        lineHeight: '17px',
        textAlign: 'right',
        fontWeight: 400,
        color: COLORS.black,
        borderRadius: '12px',
      }
    }}
    InputLabelProps={{
      shrink: false,
      style: {
        textAlign: 'right',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 400,
        color: COLORS.icon,
      }
    }}
  />
  )
}