import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IAuthState, IAuthResponse } from '../models/IUserData';
import axAPI from '../http'

// export const authLogin = createAsyncThunk(
//     'auth/login',
//     async (authData: {username: string, password: string }, { rejectWithValue }) => {             
//         try {        
//             const response = await axAPI({
//                 method: 'POST',
//                 url: 'auth/token/login/',
//                 data: { ...authData, username: authData.username.toLowerCase() }
//             })
            
//             if (response.status!==200) {
//                 throw new Error('HTTP request error!')
//             }
//             return response.data
            

//         } catch(error) {
//             let errorMessage = 'Authentification failed!'
//             if (error instanceof Error) {
//                 errorMessage = error.message
//             }
//             return rejectWithValue(errorMessage)
//         }
//     }
// )

export const authLogin = createAsyncThunk(
    'auth/login',
    async (authData: {username: string, password: string }, { rejectWithValue }) => {             
        try {        
            const response = await axAPI({
                method: 'POST',
                url: '/auth/jwt/create/',
                data: { ...authData, username: authData.username.toLowerCase() }
            })
            
            if (response.status!==200) {
                throw new Error('HTTP request error!')
            }
            const responseData = response.data;
            const apiToken = responseData;
            return apiToken

        } catch(error) {
            let errorMessage = 'Authentification failed!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

export const authSetPassword = createAsyncThunk(
    'auth/set_password',
    async (setData: {current_password: string, new_password: string }, {rejectWithValue }) => {             
        try {
            const response = await axAPI({
                method: 'POST',
                url: '/auth/users/set_password/',
                data: setData,
                validateStatus: status => (status === 400 || status === 201 || status === 204)
            })
            
            if (response.status===400) {
                if (response.data.current_password!==undefined) {
                    throw new Error(response.data.current_password[0])
                } else if (response.data.new_password!==undefined) {
                    throw new Error(response.data.new_password[0])
                } else {
                    throw new Error('Ошибка при изменении пароля!')
                }
            }
            
            return response.data

        } catch(error) {
            let errorMessage = 'Authentification failed!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)


export const authLogout = createAsyncThunk(
    'auth/logout',
    async (_, {rejectWithValue }) => {             
        try {
            const response = await axAPI({
                method: 'POST',
                url: 'auth/token/logout/',
                data: localStorage.getItem('apiToken'),
            })
            
            if (response.status!==204) {
                throw new Error('HTTP request error!')
            }
            return response.data

        } catch(error) {
            let errorMessage = 'Failed to logout current user!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

const initialState: IAuthState = {
    apiToken: localStorage.getItem('apiToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    authRequest: false,
    authError: '',
    passwordUpdated: false,

}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    },
    extraReducers: {
        [authLogin.fulfilled.type]: (state, action: PayloadAction<IAuthResponse>) => {
            state.authRequest = false;
            state.authError = action.payload.detail;
            state.authRejected = false;
            state.apiToken = action.payload.access;
            state.refreshToken = action.payload.refresh;
            localStorage.setItem('apiToken', action.payload.access)
            localStorage.setItem('refreshToken', action.payload.refresh)
        },
        [authLogin.pending.type]: (state) => {
            state.authRequest = true;
            state.authError = '';
            state.authRejected = false;
            state.apiToken = '';
            state.refreshToken = ''
            localStorage.setItem('apiToken', '')
            localStorage.setItem('refreshToken', '')
        },
        [authLogin.rejected.type]: (state, action: PayloadAction<IAuthResponse>) => {
            state.authRequest = false;
            state.authError = action.payload.detail;
            state.authRejected = true;
            state.apiToken = action.payload.access;
            state.refreshToken = action.payload.refresh;
            localStorage.setItem('apiToken', '')
            localStorage.setItem('refreshToken', '')
        },
        [authLogout.fulfilled.type]: (state, action: PayloadAction<IAuthResponse>) => {
            state.authRequest = false;
            state.authError = action.payload.detail;
            state.apiToken = '';
            localStorage.setItem('apiToken', '')
            state.passwordUpdated = false;
            state.passwordError = '';
        },
        [authLogout.pending.type]: (state) => {
            state.authRequest = true;
            state.authError = '';
        },
        [authLogout.rejected.type]: (state, action: PayloadAction<IAuthResponse>) => {
            state.authRequest = false;
            state.authError = action.payload.detail;
        },
        [authSetPassword.fulfilled.type]: (state, action: PayloadAction<IAuthResponse>) => {
            state.authRequest = false;
            state.authError = action.payload.detail;
            state.passwordError = '';
            state.passwordUpdated =  true;
        },
        [authSetPassword.pending.type]: (state) => {
            state.authRequest = true;
            state.authError = '';
            state.passwordError = '';
            state.passwordUpdated =  false;
        },
        [authSetPassword.rejected.type]: (state, action: PayloadAction<string>) => {         
            state.authRequest = false;
            state.passwordUpdated =  false;
            state.passwordError = action.payload;
        },
    }
})

export default authSlice.reducer