import { Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material'

import React, { useEffect, useState } from 'react'
import { ReactComponent as ManagementEditLogo } from '../../assets/management_edit_logo.svg'
import { ReactComponent as TrashLogo } from '../../assets/trash_logo.svg'
import { ReactComponent as RestoreLogo } from '../../assets/restore_logo.svg'

import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { TypographySecondary } from '../../ui/TypographySecondary'

import { COLORS } from '../../styles/colors';
import ManagementPopup from './ManagementPopup';
import UserPopup from './UserPopup';

import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { fetchUsersList, setUserActivityFlag } from '../../store/userSlice'



const ManagementTable = (props) => {
  const blankPopupProps = { open: false, userName: '', active: false, title: '', status: '' }
  const blankUserPopupProps = { open: false, userId: undefined, title: '',  action: '', handleClose: ()=>{} }

  const [managmentPopupProps, setShowManagmentPopup] = useState(blankPopupProps)

  const [userPopupProps, setUserPopupProps] = useState(blankUserPopupProps);

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchUsersList())
  }, [props.parentOpen])

  const { usersList, userData } = useAppSelector(store => store.userReducer)

  const renderUsersListRows = (usersList, active) => {
    return usersList.filter(el => el.is_active === active).map((row) => (
      <TableRow key={row.id}>
        <TableCell>
          <Box sx={{ width: '183px'}}>
            <TypographyPrimary>{`${row.lastname} ${row.firstname}`}</TypographyPrimary>
            <TypographyPrimary sx={{ color: COLORS.secondaryFont }}>{row.position}</TypographyPrimary>
          </Box>
        </TableCell>
        <TableCell sx={{ width: '183px'}}>
          <TypographyPrimary sx={{wordWrap:'break-word'}}>{row.username}</TypographyPrimary>
        </TableCell>
        <TableCell>
          <Box sx={{ width: '183px'}}>
            <TypographyPrimary sx={{wordWrap:'break-word'}}>{row.email}</TypographyPrimary>
            <TypographyPrimary>{row.mobile_phone}</TypographyPrimary>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <TypographyPrimary>Всего: {10}</TypographyPrimary>
            <TypographyPrimary sx={{ color: COLORS.secondaryFont }}>Последний сеанс:</TypographyPrimary>
            <TypographyPrimary sx={{ color: COLORS.secondaryFont }}>{row.last_login}</TypographyPrimary>
          </Box>
        </TableCell>
        <TableCell>
          <TypographyPrimary>{ row.role_title ? row.role_title : 'Не указана'}</TypographyPrimary>
        </TableCell>
        <TableCell>
          <Box display='flex' flexDirection='row' sx={{ pl: '8px' }}>
            {
              (userData.id !== row.id)
              ? <>
                <Box onClick={() => fillUserPopupProps(row.id)} >
                  <ManagementEditLogo style={{cursor: 'pointer'}}/>
                </Box>
                <Box onClick={() => fillManagmentPopupProps(row.id, `${row.lastname} ${row.firstname}`, active)} sx={{ pl: '12px' }}>
                  {active ? <TrashLogo style={{cursor: 'pointer'}}/> : <RestoreLogo style={{cursor: 'pointer'}}/>}
                </Box></>
              : <></>
            }

          </Box>
        </TableCell>
      </TableRow>
    ))
  }

  const fillUserPopupProps = (userId) => {
    const newUserPopupProps = {
      open: true,
      userId: userId,
      title: 'Редактирование',
      action: 'Смена',
      handleClose: () => {       
        setUserPopupProps(blankUserPopupProps)
        dispatch(fetchUsersList())
      }
    }
    setUserPopupProps(newUserPopupProps)
  }

  const fillManagmentPopupProps = (userId, userName, active) => {
    const newManagmentPopupProps = {
      open: true,
      userId: userId,
      userName: userName,
      active: active,
      title: active ? 'Удаление' : 'Восстановление',
      status: active ? 'Удалить' : 'Восстановить',
      onClose: () => {
        setShowManagmentPopup(blankPopupProps)
      },
      handleClose: () => {
        dispatch(setUserActivityFlag({ userId: userId, active: !active }))
        setShowManagmentPopup(blankPopupProps)
      },
    }
    setShowManagmentPopup(newManagmentPopupProps)
  }

  return <Box sx={{ pt: '24px', pl: '32px', pr: '24px' }}>
    <TableContainer sx={{ boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)', backgroundColor: 'white', borderRadius: '12px' }}>
      <Table sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none"
        }
      }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TypographySecondary>Фамилия Имя</TypographySecondary>
              <TypographySecondary>Должность</TypographySecondary>
            </TableCell>
            <TableCell> <TypographySecondary>Имя пользователя</TypographySecondary></TableCell>
            <TableCell>
              <TypographySecondary>Электронная почта</TypographySecondary>
              <TypographySecondary>и номер телефона</TypographySecondary>
            </TableCell>
            <TableCell><TypographySecondary>Вход в систему</TypographySecondary></TableCell>
            <TableCell><TypographySecondary>Роль</TypographySecondary></TableCell>
            <TableCell><TypographySecondary>Действия</TypographySecondary></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersList ? renderUsersListRows(usersList, true) : ''}
        </TableBody>
        {(usersList && usersList.filter(el => el.is_active === false).length > 0) ?
          <><TableHead >
            <TableRow >
              <TableCell sx={{ pt: '24px' }}>
                <TypographyPrimary sx={{ color: COLORS.secondaryFont }}>Неактивные пользователи</TypographyPrimary>
              </TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {renderUsersListRows(usersList, false)}
            </TableBody></>
          : <></>
        }
      </Table>
    </TableContainer>

    <ManagementPopup {...managmentPopupProps} />
    <UserPopup {...userPopupProps} />

  </Box>
}



export default ManagementTable