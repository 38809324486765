import { Box } from '@mui/material'
import React from 'react'

import { TypographyPrimary } from '../ui/TypographyPrimary'
import { TypographyHeaderExtra } from '../ui/TypographyHeaderExtra';
import FarmFillCard from '../components/fill/FarmFillCard';

const FillPage = () => {
  return (
    <Box sx={{pt: '24px', pr: '150px'}}>
        
        <Box>
            <TypographyHeaderExtra>
                Добро пожаловать!
            </TypographyHeaderExtra>
            <TypographyPrimary sx={{pt: '24px'}}>
            Выберите ферму для первичного заполнения данных по рационам, группам и плану кормления.
            </TypographyPrimary>
        </Box>

        <Box>
            <FarmFillCard title='Ферма 1' id='1' />
        </Box>
    </Box>
  )
}

export default FillPage