import { Divider, ThemeProvider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { COLORS } from '../styles/colors'
import { fontTheme } from '../styles/font'

import { ReactComponent as NotificationLogo } from '../assets/notification_logo.svg'
import {ReactComponent as NotificationEmpty} from '../assets/icons/notification_empty.svg'
import { ReactComponent as SettingLogo } from '../assets/setting_logo.svg'
import { NavLink } from 'react-router-dom'

const theme = fontTheme

const SidebarSettings = ({ setOpenNotifications, countNotif }) => {
	

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ fontFamily: theme.typography.fontFamily, position: 'sticky' }}>
				<Divider sx={{ borderBottomWidth: 1, borderColor: COLORS.secondary, marginBottom:'10px' }} />
				<Box sx={{ display:' flex', flexDirection:'row', alignItems:'center', height:'30px', paddingLeft:'5px'}}>
					{countNotif === 0 ? <NotificationEmpty /> : <NotificationLogo />}
					<Typography
						onClick={() => setOpenNotifications(true)}
						sx={{
							color: 'rgba(255, 255, 255, 1)',
							pl: '4px',
							pt: '2px',
							fontSize: '16px',
							fontWeight: 600,
							lineHeight: '20px',
							cursor: 'pointer',
						}}
					>
						Уведомления 
					</Typography>
					<Typography
						sx={{
              height:'auto',
							fontSize: '12px',
							fontWeight: '400',
							lineHeight: '14.63',
							color: '#F09A37',
							padding: '0',
							marginLeft: '8px',
						}}
					>
						{countNotif === 0 ? '' : `+${countNotif}`}
					</Typography>
				</Box>
				<Box
					display='flex'
					flexDirection='row'
					sx={{ pt: '24px', pl: '5px' }}
					alignItems='center'
				>
					<SettingLogo stroke='white' />
					<NavLink to='settings'>
						<Typography
							sx={{
								color: 'rgba(255, 255, 255, 1)',
								fontSize: '16px',
								fontWeight: 600,
								lineHeight: '20px',
							}}
						>
							Настройки
						</Typography>
					</NavLink>
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export default SidebarSettings
