import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { TypographySecondary } from '../../ui/TypographySecondary'

import { ReactComponent as WaitingLogo } from '../../assets/waiting_logo.svg'

import { COLORS } from '../../styles/colors'

import { TypographyPrimary } from '../../ui/TypographyPrimary'

import { PlanEventTypeEnum } from '../../models'
import { Box } from '@mui/material'

interface IGroupSpecificPlanTableProps {
	groupId: number
	groupData: any[]
}

const GroupSpecificPlanTable = (props: IGroupSpecificPlanTableProps) => {
	const typoPrimaryCell = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	}
	return (
		<TableContainer>
			<Table
				sx={{
					[`& .${tableCellClasses.root}`]: {
						borderBottom: 'none',
					},
				}}
			>
				<TableHead>
					<TableRow
						sx={{ borderBottom: `1px solid ${COLORS.secondaryFontOpacity}` }}
					>
						<TableCell>
							<TypographySecondary>Действие</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>Вес раздачи, кг</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>Плановое время</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>Допустимое отклонение</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>Фактическое время</TypographySecondary>
						</TableCell>
						{/* <TableCell align='center'>
							<TypographySecondary>Влажность, %</TypographySecondary>
						</TableCell> */}
						<TableCell align='center'>
							<TypographySecondary>Остатки, кг</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>
								Фактический вес раздачи, кг
							</TypographySecondary>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.groupData.map(data => {
						return (
							<TableRow key={data.unique_key}>
								<TableCell>{data.event_title}</TableCell>
								<TableCell align='right'>
									<Box sx={typoPrimaryCell}>
										<TypographyPrimary>
											{data.groups[0].food_weight}
										</TypographyPrimary>
										<TypographySecondary sx={{ pl: '4px' }}>
											кг
										</TypographySecondary>
									</Box>
								</TableCell>
								<TableCell align='center'>{data.time}</TableCell>
								<TableCell>
									<Box sx={typoPrimaryCell}>
										<TypographyPrimary>
											&plusmn; {data.event_deviation}
										</TypographyPrimary>
										<TypographySecondary sx={{ pl: '4px' }}>
											мин
										</TypographySecondary>
									</Box>
								</TableCell>
								<TableCell align='center'>
									{data.groups[0].time ? data.groups[0].time : <WaitingLogo />}
								</TableCell>
								{/* <TableCell sx={{ minWidth: '170px' }} align='center'>
									<TypographySecondary>Данные не введены</TypographySecondary>
								</TableCell> */}
								<TableCell align='center'>
									<Box sx={typoPrimaryCell}>
										<TypographyPrimary>{'-'}</TypographyPrimary>
										<TypographySecondary sx={{ pl: '4px' }}>
											кг
										</TypographySecondary>
									</Box>
								</TableCell>
								<TableCell>
									{data.event_id === PlanEventTypeEnum.Nudge ? (
										<></>
									) : (
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											{data.groups[0].weight ? (
												<>
													{data.groups[0].weight}
													<TypographySecondary sx={{ pl: '4px' }}>
														{' кг'}
													</TypographySecondary>
												</>
											) : (
												<TypographySecondary sx={{ pl: '4px' }}>
													{'Данные не введены'}
												</TypographySecondary>
											)}
										</Box>
									)}
								</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default GroupSpecificPlanTable
