import { Box, Collapse, List, ListItemButton } from '@mui/material'
import React from 'react'
import { TypographyHeader } from '../../ui/TypographyHeader'

import { ReactComponent as GroupSpecificEditLogo } from '../../assets/group_specific_edit.svg'

import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'
import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { COLORS } from '../../styles/colors'
import GroupSpecificEdit from '../group/GroupSpecificEdit'

type FarmFillGroupListItemProps = {
    title: string;
    id: any;
}

const FarmFillGroupListItem = (props: FarmFillGroupListItemProps) => {
  const [open, setOpen] = React.useState(false)
  return (
    <List
     sx={{
      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)', 
      backgroundColor: 'white', 
      borderRadius: '12px', 
      mt: '24px', 
      '&:hover': {boxShadow: open ? '0px 4px 16px 0px rgba(0, 0, 0, 0.08)' :'0px 4px 16px 0px rgba(82, 34, 208, 0.24)'  }, 
      '&:active': {boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)'}, 
  
      }}> 
          <Box display='flex' flexDirection='row' alignItems='center' sx={{pt: '12px', pb: '12px'}}>
            <ListItemButton
            disableRipple
            disableTouchRipple
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
            onClick={() => { setOpen(!open) }}>
                  <TypographyHeader sx={{pr: '11px', pl: '24px'}}>{props.title}</TypographyHeader>
                  {open ? <GroupSpecificEditLogo /> : <></>}
            </ListItemButton>
            <Box display='flex' flexDirection='row' alignItems='center' sx={{pr: '32px'}}>
              <TypographyPrimary sx={{color: COLORS.icon, mr: '16px'}}>Заполнить данные</TypographyPrimary>
              {open ? <ArrowOpenDone /> : <ArrowCloseDone />}
            </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
      <GroupSpecificEdit groupId={props.id} isFirstTry />
  </Collapse>
    </List> 
  )
}

export default FarmFillGroupListItem