import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import { CardProps } from '../../models'
import FeedPlanElement from './FeedPlanElement'
import { CardStandart } from '../../ui/CardStandart'
import axAPI from '../../http'
import { ReactComponent as OkVerifyLogo } from '../../assets/ok_verify_logo.svg'

const GetCardItem = ({ title }: CardProps) => {
	const [testPlanData, setTestPlanData] = useState<any[]>()
	const [feedPlanDate, setFeedPlanDate] = useState<Date | null>(new Date())

	useEffect(() => {
		axAPI
			.get(`feedplans/${feedPlanDate.toISOString().slice(0, 10)}`)
			.then(resonse => setTestPlanData(resonse.data))
	}, [feedPlanDate])

	if (!testPlanData)
		return (
			<CardStandart
				sx={{
					width: '100%',
					height: '324px',
					marginTop: '24px',
					padding: '17px',
				}}
			>
				<Box
					sx={{
						width: '55%',
						height: '5%',
						background: 'black',
						opacity: '0.5',
						borderRadius: '12px',
						marginBottom: '38px',
					}}
				></Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'start',
						gap: '10px',
						marginBottom:'20px',
					}}
				>
					<Box
						sx={{ width: '2px', height: '40px', background: '#7F7F84' }}
					></Box>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box
							sx={{
								width: '50px',
								height: '10px',
								background: '#7F7F84',
								opacity: '0.5',
								borderRadius: '12px',
								marginBottom: '10px',
								marginTop: '5px',
							}}
						></Box>
						<Box
							sx={{
								width: '100px',
								height: '10px',
								background: 'black',
								opacity: '0.5',
								borderRadius: '12px',
							}}
						></Box>
					</Box>
				</Box>


				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'start',
						gap: '10px',
						marginBottom:'20px',
					}}
				>
					<Box
						sx={{ width: '2px', height: '40px', background: '#7F7F84' }}
					></Box>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box
							sx={{
								width: '50px',
								height: '10px',
								background: '#7F7F84',
								opacity: '0.5',
								borderRadius: '12px',
								marginBottom: '10px',
								marginTop: '5px',
							}}
						></Box>
						<Box
							sx={{
								width: '120px',
								height: '10px',
								background: 'black',
								opacity: '0.5',
								borderRadius: '12px',
							}}
						></Box>
					</Box>
				</Box>


				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'start',
						gap: '10px',
						marginBottom:'20px',
					}}
				>
					<Box
						sx={{ width: '2px', height: '40px', background: '#7F7F84' }}
					></Box>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box
							sx={{
								width: '50px',
								height: '10px',
								background: '#7F7F84',
								opacity: '0.5',
								borderRadius: '12px',
								marginBottom: '10px',
								marginTop: '5px',
							}}
						></Box>
						<Box
							sx={{
								width: '80px',
								height: '10px',
								background: 'black',
								opacity: '0.5',
								borderRadius: '12px',
							}}
						></Box>
					</Box>
				</Box>


				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'start',
						gap: '10px',
						marginBottom:'20px',
					}}
				>
					<Box
						sx={{ width: '2px', height: '40px', background: '#7F7F84' }}
					></Box>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box
							sx={{
								width: '50px',
								height: '10px',
								background: '#7F7F84',
								opacity: '0.5',
								borderRadius: '12px',
								marginBottom: '10px',
								marginTop: '5px',
							}}
						></Box>
						<Box
							sx={{
								width: '150px',
								height: '10px',
								background: 'black',
								opacity: '0.5',
								borderRadius: '12px',
							}}
						></Box>
					</Box>
				</Box>
			</CardStandart>
		)

	const now = new Date()
	const hours = now.getHours().toString().padStart(2, '0')
	const minutes = now.getMinutes().toString().padStart(2, '0')
	const seconds = now.getSeconds().toString().padStart(2, '0')

	const timeString: string = `${hours}:${minutes}:${seconds}`

	const filteredListAfterCurrentTime = testPlanData.filter(
		el => el.time > timeString
	)

	const getDisplayedEvents = planData => {
		const toDisplay = []

		const currentDate = new Date()
		const currentHr = currentDate.getHours()
		const currentValue = currentHr * 60 + currentDate.getMinutes()

		planData.forEach(event => {
			let targetValue =
				Number(event.time.split(':')[0]) * 60 + Number(event.time.split(':')[1])

			if (event.time.split(':')[0] > currentHr) {
				toDisplay.push(event)
			} else if (targetValue >= currentValue) {
				toDisplay.push(event)
			}
		})
		return toDisplay
	}

	const isActiveEvent = (eventId, time) => {
		const event = testPlanData.find(el => el.id === eventId)
		if (!event) return false

		const currentDate = new Date()
		const currentValue = currentDate.getHours() * 60 + currentDate.getMinutes()
		const targetValue =
			Number(time.split(':')[0]) * 60 + Number(time.split(':')[1])

		return (
			currentValue >= targetValue &&
			currentValue <= targetValue + event.deviation
		)
	}
	const planData = []
	let currentTime = undefined
	let currentEvent = undefined

	getDisplayedEvents(testPlanData).forEach((plan, index) => {
		if (currentTime !== plan.time || currentEvent !== plan.type) {
			planData.push(plan)
			currentTime = plan.time
			currentEvent = plan.event
		}
	})
	return (
		<NavLink to='plan'>
			<CardStandart
				sx={{
					width: '100%',
					height: '324px',
					padding:'24px',
				}}
			>
					<Typography
						sx={{ fontSize: 20, fontWeight: 700, marginBottom: '24px',  }}
					>
						{title}
					</Typography>
					
						{planData.length === 0 
						? 
						<Box sx={{display:'flex', flexDirection:'row', alignItems:'center',gap:'15px'}}>
							<Typography
							sx={{
								fontSize: 14,
								fontWeight: 400,
								color: '#7F7F84',
							}}
						>
							Все задачи на сегодня выполнены
						</Typography>
							<OkVerifyLogo style={{width:'24px', height:'24px'}}/>
						</Box>
						:
						<Grid container direction='column' spacing={2}>
						{planData.slice(0, 4).map(plan => {
							return (
								<Grid item key={plan.id}>
									<FeedPlanElement
										isProcessing={isActiveEvent(plan.event, plan.time)}
										timeBegin={plan.time}
										title={plan.type}
									/>
								</Grid>
							)
						})}
						</Grid>
					}
					
					{filteredListAfterCurrentTime.length > 4 ? (
						<Typography
							sx={{
								fontSize: 14,
								fontWeight: 400,
								color: '#7F7F84',
								display: 'flex',
								marginTop: '19px',
							}}
						>
							{`+ еще ${filteredListAfterCurrentTime.length - 4}`}
						</Typography>
					) : (
						<></>
					)}
				
			</CardStandart>
		</NavLink>
	)
}

export default GetCardItem