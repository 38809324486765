import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
    addComponent, fetchRationEntity, fetchRationList, removeComponent, setBlankRation, setRationAttributeValue, setRowCoefValue, setRowComponentValue
} from '../../store/rationSlice'

import {
    fetchGroupList
} from '../../store/groupsSlice'

import axAPI from '../../http'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { notifyUser } from '../../store/notificationsSlice'

import { ReactComponent as ChipDeleteIcon } from '../../assets/chip_delete_icon.svg'
import { ReactComponent as GroupDeleteIcon } from '../../assets/group_delete_icon.svg'

import InputField from '../shared/InputField'

import { Autocomplete, Box, Divider, IconButton, TextField } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { COLORS } from '../../styles/colors'
import { OutlinedButton } from '../../ui/OutlinedButton'

import { ChipItem } from '../../ui/ChipItem'
import { TypographyHeaderSecondary } from '../../ui/TypographyHeaderSecondary'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { TypographySecondary } from '../../ui/TypographySecondary'
import RationCheckmarks from './RationCheckmarks'
import RationChipItem from './RationChipItem'
import RationProgressBar from './RationProgressBar'
import { RationSelectField, RationTextField } from './RationTextField'
import React from 'react'

type RationEdipPopupProps = {
    isFirstTry?: boolean
}

const RationEditPopup = (props: RationEdipPopupProps) => {

    const urlParams = useParams()
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const { currentRation, rationList } = useAppSelector(store => store.rationReducer)
    const { groupList } = useAppSelector(store => store.groupsReducer)

    const [componentsList, setComponentsList] = useState([])

    const [checkedGroups, setCheckedGroups] = useState(undefined)

    const [groupRationCheck, setGroupRationCheck] = useState(false)
    
    const rationError = useRef ({
        isGroupError: false,
        approved: [], 
        invalidComponents: [],
        errorGroupList: [{
            id: null, title: ''
        }],
        ration: [],
        errorText: '',
    })

    const handleGroupChange = (groupId) => {
        if (checkedGroups.includes(groupId)) setCheckedGroups(checkedGroups.filter(el => el !== groupId))
        else setCheckedGroups([...checkedGroups, groupId])
        setGroupRationCheck(true)
    }

    async function onSubmitChanges() {
        axAPI({
            method: currentRation.key ? 'PUT' : 'POST',
            url: currentRation.key ? `/ration/${currentRation.key}` : '/ration/add/',
            data: { ...currentRation, groupList: checkedGroups },
        }).then((response) => {
            if (response.status===201 || response.status===200) {
                dispatch(notifyUser({
                    title: currentRation.title,
                    description: 'Изменения успешно сохранены',
                    isError: false,
                }))
                dispatch(fetchRationList())
                dispatch(fetchGroupList())
                navigate(props.isFirstTry ? '/fill/1/ration' : '/feed/ration')
            }
        }).catch(error => {
            dispatch(notifyUser({
                title: currentRation.title,
                description: 'Произошла ошибка при сохранении рациона',
                isError: true,
            }))
        })
    }

    useEffect(() => {
        const response = axAPI({
            method: 'GET',
            url: '/ration/component/list'
        }).then((response) => {
            setComponentsList(response.data)
        })
    }, [])
    
    useEffect(() => {
        if (urlParams.rationkey && urlParams.rationkey!=='create') {
            dispatch(fetchRationEntity(urlParams.rationkey))            
        } else {
            dispatch(setBlankRation())
        }
        setCheckedGroups(undefined)
        rationError.current = {
            ...rationError.current,
            approved: [],
        }
    }, [urlParams.rationkey])

    if (!urlParams.rationkey) return <></>
    if (urlParams.rationkey!=='create' && !currentRation) return <></>

    if (currentRation && checkedGroups===undefined) {
        setCheckedGroups(
            groupList.filter(group => group.ration === currentRation.id).map(group => group.id)
        )
    }
 
    const initialValue = 0
    const coefSum = currentRation.components.map(row => row.coef).reduce((prevValue, currentValue) => prevValue+Number(currentValue), initialValue)

    const invalidRationTitle = (currentRation.title && rationList
        .filter(el => (el.title.trim().toLocaleLowerCase() === currentRation.title.trim().toLocaleLowerCase()
            && el.key!==currentRation.key)).length > 0)


    rationError.current = {
        isGroupError: false,
        approved: [...rationError.current.approved],
        invalidComponents: [],
        errorGroupList: [],
        ration: [],
        errorText: '',
    }
   
    if (groupRationCheck && checkedGroups) {
        checkedGroups.forEach(group => {
            let currentGroup = groupList.find(el => el.id === group)
            if (currentGroup.ration && currentGroup.ration !== currentRation.id && !rationError.current.approved.includes(currentGroup.id)) {
                rationError.current.isGroupError = true
                rationError.current.errorGroupList.push({
                    id: currentGroup.id,
                    title: currentGroup.title
                }) //not approved
                rationError.current.ration.push(currentGroup.rationtitle)
            }
        })
        if (rationError.current.errorGroupList.length === 1) {
            rationError.current.errorText = `Для группы "${rationError.current.errorGroupList[0].title}" установлен рацион "${rationError.current.ration[0]}".`
            rationError.current.errorText += ' Вы уверены, что хотите изменить рацион для данной группы?'
        } else if (rationError.current.errorGroupList.length > 1) {
            const groupsTitle = rationError.current.errorGroupList.map(el => `"${el.title}"`).join(', ')
            rationError.current.errorText = `Для групп ${groupsTitle} установлены другие рационы.`
            rationError.current.errorText += ' Вы уверены, что хотите изменить рацион для данных групп?'
        }
    }

    currentRation.components.forEach((component, index) => {
        if (component.itemKey) {
            currentRation.components.forEach((el, comparisionIndex) => {
                if (el.itemKey === component.itemKey && comparisionIndex > index) {
                    rationError.current.invalidComponents.push(comparisionIndex)
                }
            })
        }
    })

    return <Dialog open={true} key={`${urlParams.rationkey}${currentRation.id}${currentRation.key}`} maxWidth='lg' fullWidth scroll='body' >
            <DialogTitle sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
                {urlParams.rationkey==='create' ? 'Создание рациона' : `Редактирование рациона: ${ currentRation.title }`}
                <IconButton onClick={() => navigate(props.isFirstTry ? '/fill/1/ration' : '/feed/ration')}>
                    <GroupDeleteIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
               { !props.isFirstTry ? <Box>
                <Box sx={{pt: '12px'}}>
                    <RationCheckmarks sx={{width: '300px'}} label='Группы' checkedGroups={ checkedGroups } selectionCallback={ (groupId) => handleGroupChange(groupId) }/>
                </Box>
                {
                    rationError.current.isGroupError
                    ? <Box 
                        display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' 
                        sx={{ 
                            backgroundColor: COLORS.deviationOpacity,
                            borderRadius: '8px',
                            mt: '24px'
                            }}>
                        <TypographyPrimary sx={{p: '16px'}}>
                        { rationError.current.errorText }
                        </TypographyPrimary>
                        <OutlinedButton
                            sx={{width: '300px', mt: '8px', mb: '8px', mr: '16px'}}
                            onClick={() => {
                                setGroupRationCheck(false)
                                rationError.current.approved.push(
                                   ...rationError.current.errorGroupList.map(el => el.id)
                                )
                            }}
                        >Изменить</OutlinedButton>
                    </Box>
                    : <></>
                }
                <Box>
                {
                    checkedGroups===undefined ? <></>
                    : groupList.filter(group => checkedGroups.includes(group.id)).map((group, index) => {
                        if(rationError.current.errorGroupList.includes({id: group.id, title: group.title})) {
                            return <ChipItem 
                                    key={group.key}
                                    label={ group.title } 
                                    onDelete={ () => handleGroupChange(group.id) } 
                                    deleteIcon={<ChipDeleteIcon stroke={COLORS.icon} />}
                                    sx={{ 
                                        mt: '24px', mr:'16px', 
                                        backgroundColor: COLORS.deviationOpacity,
                                        '&:hover': {
                                            color: COLORS.black,
                                            backgroundColor: COLORS.deviation
                                        },
                                        '&	.MuiChip-label': {
                                            pl: '12px',
                                        }
                                    }} />
                        }
                        return <RationChipItem 
                            label={ group.title } 
                            onDelete={ () => {
                                handleGroupChange(group.id)
                            } } 
                            sx={{ 
                                mt: '24px', mr:'16px',
                                backgroundColor: COLORS.background,
                                '&:hover': {
                                    color: COLORS.black,
                                    backgroundColor: COLORS.mainSecondaryOpacity
                                },
                                '&	.MuiChip-label': {
                                    pl: '12px',
                                }
                            }} />
                    })
                }
                </Box>
                <Divider sx={{pt: '24px'}} />
                </Box> : <></>}
               { !props.isFirstTry ? <TypographyHeaderSecondary sx={{pt: '24px'}}>Рацион</TypographyHeaderSecondary> : <></>}
                <Box display='flex' flexDirection='row' sx={{ pt: '32px' }}>
                    <Box display='flex' flexDirection='column'>
                      <RationTextField
                        isError={ invalidRationTitle }
                        key={ `rationTitle${currentRation.rationKey}` }
                        label='Название рациона*'
                        sx={{ width: '60.6vw', '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: COLORS.secondaryFontOpacity,
                            },
                          } }}
                        value={ currentRation.title }
                        onChange={(e) => {dispatch(setRationAttributeValue({field: 'title', value: e.target.value}))}}
                        />
                        {
                            invalidRationTitle
                            ? <TypographySecondary sx={{color: COLORS.deviationFont}}>Данное название рациона уже используется</TypographySecondary>
                            : <></>
                        }
                    </Box>
                    <InputField
                        key={`share${urlParams.rationkey}${currentRation.rationKey}`}
                        label='Сухое вещество, %'
                        type='number'
                        sx={{ width: '12vw', ml: '24px', mt: '8px', '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: COLORS.secondaryFontOpacity,
                            },
                          } }}
                        value={ currentRation.dry }
                        maxValue={100}
                        onBlur={ (value) => dispatch(setRationAttributeValue({ field: 'dry', value: value })) }
                    />
                    {/* <InputField
                        key={`balance${urlParams.rationkey}${currentRation.rationKey}`}
                        label='Норма остатков, %'
                        type='number'
                        sx={{ width: '12vw', ml: '24px', mt: '8px', '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: COLORS.secondaryFontOpacity,
                            },
                          } }}
                        value={ currentRation.balance }
                        maxValue={100}
                        onBlur={ (value) => dispatch(setRationAttributeValue({ field: 'balance', value: value })) }
                    /> */}
                </Box>
                <Box display='flex' flexDirection='column' sx={{ pt: '45px' }}>
                    <TypographyHeaderSecondary>Состав</TypographyHeaderSecondary>
                    <TypographyPrimary sx={{pt: '24px'}}>Сумма % долей всех компонентов должна составлять 100%.</TypographyPrimary>
                    <Box sx={{pt: '24px', pb: '24px'}}><RationProgressBar value={coefSum} isSumMore={coefSum > 100} isHundred={coefSum === 100} /></Box>
                    {coefSum === 100 ?
                     <TypographySecondary sx={{pb: '24px', display: 'flex', justifyContent: 'flex-end', pr: '8px'}}>Cумма компонентов 100%</TypographySecondary>                             
                     :
                     <></>}
                    {coefSum > 100 ? 
                     <TypographySecondary sx={{pb: '24px', display: 'flex', justifyContent: 'flex-end', pr: '8px', color: COLORS.deviationFont}}>Cумма компонентов превышает 100% на {coefSum - 100}%</TypographySecondary>
                     : 
                     <></>
                     }
                     {coefSum < 100 ? 
                     <TypographySecondary sx={{pb: '24px', display: 'flex', justifyContent: 'flex-end', pr: '8px'}}>Осталось добавить компонентов на {100 - coefSum}%</TypographySecondary>
                     : 
                     <></>
                     }
                     <TypographyPrimary sx={{color: COLORS.icon, pb: '4px'}}>Компоненты</TypographyPrimary>
                    {
                        currentRation.components && currentRation.components.length ?
                        currentRation.components.map((row, index) => <Box display='flex' flexDirection='row' alignItems='center'>
                            <RationSelectField
                                key={`component${row.itemKey}${index}`}
                                isErrorFocus={rationError.current.invalidComponents.includes(index)}
                                label=''
                                title={''}
                                value={row.item}
                                choiceList={componentsList}
                                onChange={(e) => {
                                    dispatch(setRowComponentValue({
                                        index: index,
                                        item: e.target.value,
                                        itemKey: componentsList.find(comp => comp.title === e.target.value).key
                                    }))
                                } } 
                                
                            />
                            <InputField
                                key={`share${row.itemKey}${index}`}
                                isError={ rationError.current.invalidComponents.includes(index) }
                                label=''
                                autoFocus={false}
                                type='number'
                                sx={{ width: '8vw', ml: '24px', mt: '4px', '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                      borderColor: COLORS.secondaryFontOpacity,
                                    },
                                  },
                                  "& .MuiOutlinedInput-notchedOutline legend": { display: "none", }
                                }}
                                isStart
                                value={row.coef}
                                maxValue={100}
                                onBlur={ (value) => dispatch(setRowCoefValue({ index: index, coef: value })) }
                            />
                            <Box sx={{pt: '8px', pl: '30px'}}>
                                <IconButton onClick={ () => dispatch(removeComponent({index: index})) }>
                                    <GroupDeleteIcon />
                                </IconButton>
                            </Box>
                        </Box>)
                        :
                        <></>
                    }
                </Box>
            <Box display='flex'>
                <OutlinedButton onClick={() => dispatch(addComponent())} sx={{ width: '80vw', border: `1px solid ${COLORS.secondaryFont}`, color: COLORS.secondaryFont, '&:hover': {backgroundColor: 'transparent', color: COLORS.main, borderColor: COLORS.main, boxShadow: 'none'}, }}>
                    Добавить компонент +
                </OutlinedButton>
            </Box>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: '24px' }}>
            <OutlinedButton
                type="submit"
                disabled={
                    coefSum !== 100 ||
                    rationError.current.invalidComponents.length > 0 ||
                    rationError.current.isGroupError ||
                    invalidRationTitle ||
                    currentRation.title === '' 
                    
                }
                onClick={onSubmitChanges}
            >Сохранить</OutlinedButton>
        </DialogActions>
    </Dialog>
}

export default RationEditPopup