import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import { ChipItem } from '../../ui/ChipItem'

import { useNavigate, useParams } from 'react-router-dom'
import HistoryTable from './HistoryTable'
import { COLORS } from '../../styles/colors'
import RangeDate from '../shared/RangeDate'
import moment from 'moment'
import axAPI from '../../http'

const HistoryPage = () => {
	const [allColor, setAllColor] = useState({
		backgroundColor: COLORS.main,
		color: '#fff',
	})

	const date = moment().add(1, 'days').format().slice(0, 10)

	const [historyData, setHistoryData] = useState([])

	useEffect(() => {
		let url = `/feedplan/history/${date}`
		axAPI({
			method: 'GET',
			url: url,
		}).then(response => {
			setHistoryData(response.data)
		})
	}, [])

	const urlParams = useParams()
	const navigate = useNavigate()

	const [dateRange, setDateRange] = useState(date)

	const { groupList } = useAppSelector(store => store.groupsReducer)

	const threeMonthsAgo = moment().subtract(3, 'months').format().slice(0, 10)

	const chipItemStyle = { mr: '16px' }

	const dateChangeHandler = value => {
		setDateRange(value[1])
	}

	if (!historyData[historyData.length - 1]?.date) return <></>

	return (
		<Box sx={{ pl: '24px', pr: '40px', display:'flex', flexDirection:'column' }}>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='flex-end'
				alignItems='center'
        marginBottom='10px'
			>
				
				<RangeDate
					startDate={historyData[historyData.length - 1].date}
					endDate={dateRange}
					onChangeUpdateDate={dateChangeHandler}
				/>
			</Box>
			{/* <Box
				sx={{ pt: '24px', whiteSpace: 'wrap' }}
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				alignItems='center'
			> */}
				<Box>
					<ChipItem
						label='Все'
						sx={{
							mr: '16px',
              margin: '5px',
							backgroundColor: allColor.backgroundColor,
							color: allColor.color,
						}}
						onClick={() => {
							navigate('/feed/plan/history')
							setAllColor({ backgroundColor: COLORS.main, color: '#fff' })
						}}
					/>
					{groupList.map(group => (
						<ChipItem
							label={group.title}
              key={'groupHistory' + group.key}
							sx={
								group.key === urlParams.groupkey ||
								(!group.key && group.title === 'Все')
									? {
											...chipItemStyle,
                      margin: '5px',
											backgroundColor: '#5222D0',
											color: '#fff',
									  }
									: chipItemStyle
							}
							onClick={e => {
								navigate(`/feed/plan/history/${group.key}`)
								setAllColor({
									backgroundColor: '#DBDBDB',
									color: 'rgba(0, 0, 0, 0.87)',
								})
							}}
							
						/>
					))}
				</Box>
			{/* </Box> */}
			<Box>
				<HistoryTable date={[threeMonthsAgo, dateRange]} />
			</Box>
		</Box>
	)
}

export default HistoryPage
