import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axAPI from '../../http'
import { ChipItem } from '../../ui/ChipItem'
import DeviationItem from '../deviations/DeviationItem'
import DeviationItemGroup from './DeviationItemGroup'

import GroupSpecificDeviationContentAbout from './GroupSpecificDeviationContentAbout'

const groupData = [
	{ gtitle: 'Все', key: 'all' },
	{ gtitle: 'План кормления', key: 'feedplan' },
	{ gtitle: 'Камеры и освещение', key: 'camera' },
	{ gtitle: 'Рационы', key: 'ration' },
]

const GroupSpecificDeviationContent = ({ id, startDate, endDate, open }) => {
	const [currentGroup, setCurrentGroup] = React.useState('all')

	const [deviationsList, setDeviationList] = useState([])
	
  const filteredDeviationList = deviationsList.filter((el) => {return el.type === currentGroup})

	useEffect(() => {
		axAPI
			.get(
				`captured-deviations?start=${startDate}&end=${
					endDate + 86400000
				}&group=${id}`
			)
			.then(response => {
				response.data.length !== 0
					? setDeviationList(response.data)
					: setDeviationList([])
			})
	}, [startDate, endDate])
  
	const chipItemStyle = { mr: '16px' }

	return (
		<Box display='flex' flexDirection='column'>
			<Box display='flex' flexDirection='row' marginBottom={'27px'}>
				{groupData.map(group => (
					<ChipItem
						key={group.key}
						onClick={() => setCurrentGroup(group.key)}
						label={group.gtitle}
						sx={
							group.key === currentGroup
								? {
										...chipItemStyle,
										backgroundColor: '#5222D0',
										color: '#fff',
								  }
								: chipItemStyle
						}
					/>
				))}
			</Box>
			<Box>
        {currentGroup === 'all' 
        ? deviationsList.map(item => (
					<DeviationItemGroup item={item} key={item.id} />
				))
         : filteredDeviationList.map(item => (
					<DeviationItemGroup item={item} key={item.id} />
				))}
				
			</Box>
		</Box>
	)
}

export default GroupSpecificDeviationContent
