import { useState } from 'react'

import {
	Box,
	IconButton,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material'

import { ReactComponent as GroupDeleteIcon } from '../../assets/group_delete_icon.svg'
import { ReactComponent as PlusPopupIcon } from '../../assets/plus_popup_icon.svg'
import { COLORS } from '../../styles/colors'
import { PlanTableCell } from '../../ui/PlanTableCell'
import CreatePlanTableCell from './CreatePlanTableCell'

import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { setFeedPlanEventActivityByIndex } from '../../store/feedPlanSlice'
import { PlanEventTypeEnum } from '../../models'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { TypographySecondary } from '../../ui/TypographySecondary'

const CreatePlanListDistributionContent = () => {
	const dispatch = useAppDispatch()

	const { feedPlanData } = useAppSelector(store => store.feedPlanReducer)
	const { groupList } = useAppSelector(store => store.groupsReducer)
	const [totalCols, setTotalCols] = useState(0)

	const renderPlanDataCells = (groupId, groupWeight) => {
		const dataCells = []
		let foundCells = 0
		feedPlanData.plan_data.forEach((el, index) => {
			if (
				el.active === true &&
				el.group === groupId &&
				el.event === PlanEventTypeEnum.Distribution
			) {
				foundCells++
				dataCells.push(
					<CreatePlanTableCell
						key={`renderPlanDataSells_${index}`}
						groupId={groupId}
						time={el.time}
						weight={el.weight_percent}
						food_weight={groupWeight}
						index={index}
					/>
				)
			}
		})

		for (let i = foundCells; i < totalCols; i++) {
			dataCells.push(
				<CreatePlanTableCell
					key={`renderPlanDataSells_${i}`}
					groupId={groupId}
					additionIndex={i}
				/>
			)
		}
		return dataCells
	}
	const changePlanColumnsComposition = (columnNumber, addColumn: boolean) => {
		if (totalCols === 1 && !addColumn) return

		groupList.forEach(group => {
			let counter = 0

			for (const [index, planEvent] of Object.entries(feedPlanData.plan_data)) {
				if (
					planEvent.event === PlanEventTypeEnum.Distribution &&
					planEvent.group === group.id
				) {
					if (!planEvent.active && planEvent.modified && addColumn) {
						dispatch(
							setFeedPlanEventActivityByIndex({ index: index, active: true })
						)
						break
					} else if (planEvent.active) {
						counter += 1
						if (counter === columnNumber) {
							dispatch(
								setFeedPlanEventActivityByIndex({ index: index, active: false })
							)
							break
						}
					}
				}
			}
		})
		setTotalCols(totalCols + (addColumn ? 1 : -1))
	}

	if (feedPlanData && groupList) {
		const distinctTimeColumns = groupList.map(
			group =>
				feedPlanData.plan_data.filter(
					el =>
						el.active === false &&
						el.group === group.id &&
						el.event === PlanEventTypeEnum.Distribution
				).length
		)

		const currentMaxColumns = Math.max(...distinctTimeColumns)

		if (currentMaxColumns > totalCols) setTotalCols(currentMaxColumns)
	}

	const tableColsRender = []
	for (let i = 1; i < totalCols + 1; i++) {
		tableColsRender.push(
			<PlanTableCell
				key={`createPlanCellDistributionColumn${i}`}
				sx={{
					width: '230px',
					borderBottom: `1px solid ${COLORS.secondaryFontOpacity} !important`,
				}}
			>
				{`Раздача  ${i.toString()}`}
				{i === 1 ? (
					<></>
				) : (
					<IconButton onClick={() => changePlanColumnsComposition(i, false)}>
						<GroupDeleteIcon />
					</IconButton>
				)}
			</PlanTableCell>
		)
	}

	const sxThead = {
		borderBottom: `1px solid ${COLORS.secondaryFontOpacity} !important`,
		width: '100%',
	}

	return (
		<Box sx={{ pt: '24px' }}>
			<TableContainer style={{ maxWidth: '2000px' }}>
				<Table
					sx={{
						overflowX: 'auto',
						flex: '0 0 35%',
						width: 'max-content',
						[`& .${tableCellClasses.root}`]: {
							borderBottom: 'none',
						},
					}}
				>
					<TableHead sx={sxThead}>
						<TableRow>
							<PlanTableCell>Группа</PlanTableCell>
							<PlanTableCell>Рацион</PlanTableCell>
							{tableColsRender}
							<TableCell>
								<PlusPopupIcon
									style={{ cursor: 'pointer', float: 'right' }}
									onClick={() =>
										changePlanColumnsComposition(totalCols + 1, true)
									}
								/>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{groupList.map(group => {
							return (
								<TableRow sx={{}} key={`distr${group.key}_${group.id}`}>
									<TableCell
										component='th'
										scope='row'
										align='left'
										style={{ width: '200px' }}
									>
										<Box
											display='flex'
											flexDirection='column'
											justifyContent='center'
											sx={{
												borderLeft: `2px solid ${COLORS.icon}`,
												pl: '12px',
												height: '54px',
											}}
										>
											<Box
												display='flex'
												flexDirection='row'
												alignItems='center'
												sx={{ pb: '12px' }}
											>
												<TypographyPrimary sx={{ whiteSpace: 'nowrap' }}>
													{group.title}
												</TypographyPrimary>
												<TypographySecondary
													sx={{ ml: '3px', whiteSpace: 'nowrap' }}
												>
													{group.barntitle}
												</TypographySecondary>
											</Box>
											<TypographyPrimary sx={{ color: COLORS.secondary }}>
												{group.cattles_qty}
											</TypographyPrimary>
										</Box>
									</TableCell>
									<TableCell align='left' style={{ width: '200px' }}>
										<Box>
											{group.rationtitle === '' && group.food_weight === 0 ? (
												<Box>
													<TypographyPrimary
														sx={{
															whiteSpace: 'nowrap',
															color: COLORS.secondaryFont,
														}}
													>
														Рацион не выбран
													</TypographyPrimary>
												</Box>
											) : (
												<Box>
													<TypographyPrimary
														sx={{ whiteSpace: 'nowrap', pb: '12px' }}
													>
														{group.rationtitle}
													</TypographyPrimary>
													<TypographyPrimary
														sx={{
															whiteSpace: 'nowrap',
															color: COLORS.secondaryFont,
														}}
													>
														Общий вес: {`${group.food_weight} кг`}
													</TypographyPrimary>
												</Box>
											)}
										</Box>
									</TableCell>
									{renderPlanDataCells(group.id, group.food_weight)}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default CreatePlanListDistributionContent
