import {
	Button,
	CardContent,
	IconButton,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

import Dialog from '@mui/material/Dialog'

import { ReactComponent as CrossCloseLogo } from '../../assets/cross_close_logo.svg'

import { TypographyHeaderSecondary } from '../../ui/TypographyHeaderSecondary'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { COLORS } from '../../styles/colors'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { TypographySecondary } from '../../ui/TypographySecondary'

export interface SimpleDialogProps {
	open: boolean
	title: string
	dry: number
	totalWeight: any
	components: any[]
	onClose: () => void
}

function SimpleDialog(props: SimpleDialogProps) {
	const { onClose, open, title, dry, components, totalWeight } = props
	const handleClose = () => {
		onClose()
	}
	if (!components) return <></>
	return (
		<Dialog
			onClose={handleClose}
			open={open}
			maxWidth='lg'
			fullWidth
			scroll='body'
			PaperProps={{
				style: { borderRadius: '12px', height:'500px', width:'40%' },
			}}
		>
			<CardContent>
				<Box
					display='flex'
					flexDirection='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<TypographyHeader sx={{ pl: '8px' }}>Состав рациона</TypographyHeader>
					<IconButton onClick={handleClose}>
						<CrossCloseLogo />
					</IconButton>
				</Box>
				<TypographyHeaderSecondary sx={{ pt: '16px', pb: '24px', pl: '8px' }}>
					Рацион: {title === null ? <></> : title}
				</TypographyHeaderSecondary>
				<TypographyPrimary sx={{ pl: '8px' }}>СВ: {dry}%</TypographyPrimary>
				<TableContainer sx={{ pt: '24px', ml: '-8px' }}>
					<Table
						sx={{
							ml: '18px',
							width: '97%',
							[`& .${tableCellClasses.root}`]: {
								borderBottom: 'none',
							},
						}}
					>
						<TableHead sx={{}}>
							<TableRow>
								<TableCell
									sx={{
										fontSize: '12px',
										lineHeight: '15px',
										color: COLORS.icon,
										borderBottom: `1px solid ${COLORS.secondaryFontOpacity} !important`,
										textAlign: 'left',
										pl: '0px',
									}}
								>
									Состав
								</TableCell>
								<TableCell
									sx={{
										fontSize: '12px',
										lineHeight: '15px',
										color: COLORS.icon,
										borderBottom: `1px solid ${COLORS.secondaryFontOpacity} !important`,
									}}
									align='right'
								>
									Вес %
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{components.map(component => (
								<TableRow key={component.itemKey}>
									<TableCell
										sx={{
											fontSize: '12px',
											lineHeight: '15px',
											color: COLORS.icon,
											pl: '0px',
										}}
									>
										{component.item}
									</TableCell>
									<TableCell
										sx={{
											fontSize: '12px',
											lineHeight: '15px',
											color: COLORS.icon,
										}}
										align='right'
									>
										{Number(component.coef)} %
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Dialog>
	)
}

export default function PlanCardCompound(props) {
	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}
if (props.ration === null) return <></>

	return (
		<Box>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ pt: '24px' }}
			>
				<TypographyHeaderSecondary sx={{ pr: '8px' }}>
					Рацион: {props.ration === null ? 'Рацион не указан' : props.ration}
				</TypographyHeaderSecondary>
				{props.components.length === 0 ? 
				<TypographySecondary
				sx={{ color: COLORS.black, verticalAlign: 'bottom' }}
			>
				Рацион не указан
			</TypographySecondary>
			:
			<Button
			onClick={handleClickOpen}
			sx={{
				textTransform: 'none',
				borderRadius: '0px',
				height: '15px',
				pl: '0px',
				textDecoration: 'underline',
				color: COLORS.black,
				'&:hover': { backgroundColor: 'transparent' },
			}}
		>
			<TypographySecondary
				sx={{ color: COLORS.black, verticalAlign: 'bottom' }}
			>
				Состав
			</TypographySecondary>
		</Button>
				}
			</Box>
			<SimpleDialog
				open={open}
				onClose={handleClose}
				dry={props.dry}
				title={props.ration}
				components={props.components}
				totalWeight={props.totalWeight}
			/>
		</Box>
	)
}
