// import axios from "axios";
// import { serverName } from "../variables";

// const axAPI = axios.create({
//     withCredentials: true,
//     baseURL: serverName
// })

// axAPI.interceptors.request.use((config) => {
//     const apiToken = localStorage.getItem('apiToken')
//     if (apiToken) {
//         config.headers.Authorization = 'Bearer ' + apiToken
//     }
//     return config
// })

// axAPI.interceptors.response.use((response) => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//         localStorage.removeItem('apiToken')
//         window.location.href = '/login'
//     }
//     return Promise.reject(error)
// });

// export default axAPI


import axios from "axios";
import { serverName } from "../variables";

const axAPI = axios.create({
  withCredentials: true,
  baseURL: serverName
});

axAPI.interceptors.request.use((config) => {
  const apiToken = localStorage.getItem("apiToken");
  if (apiToken) {
    config.headers.Authorization = "Bearer " + apiToken;
  }
  return config;
});

axAPI.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = localStorage.getItem("refreshToken")
      if (refreshToken) {
        try {
          const response = await axios.post(serverName + "auth/jwt/refresh/", {
            refresh: refreshToken,
          })
          
          const { access, refresh } = response.data
          localStorage.setItem("apiToken", access)
          localStorage.setItem("refreshToken", refresh)
          return axAPI(originalRequest)
        } catch (e) {
          localStorage.removeItem("apiToken")
          localStorage.removeItem("refreshToken")
          window.location.href = "/login"
          return Promise.reject(error)
        }
      } else {
        localStorage.removeItem("apiToken")
        localStorage.removeItem("refreshToken")
        window.location.href = "/login"
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default axAPI;
