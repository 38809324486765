import { Chip } from "@mui/material";
import { experimentalStyled as styled } from '@mui/material/styles';

export const ChipItem = styled(Chip)(({ theme }) => ({
    backgroundColor: '#DBDBDB', //theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    borderRadius: 12,
    color: theme.palette.text.primary,
    '&:hover': {
        backgroundColor: '#5222D0',
        color: '#fff'
    },
}));