import React, { useState, useEffect, useCallback } from 'react'

import { IconButton, InputAdornment, TextField } from '@mui/material'

import { ReactComponent as GroupDeleteIcon } from '../../assets/group_delete_icon.svg'

import { COLORS } from '../../styles/colors'
import { TypographyPrimary } from '../../ui/TypographyPrimary'

type TextFieldProps = {
    key: string;
    label?: string;
    type?: "number" | "time"
    sx?: any;
    width?: string
    value?: string | number;
    foodWeight?: any;
    maxValue?: number
    onBlur?: any;
    onClose?: any;
    isStart?: boolean;
    autoFocus?: boolean;
    isHead?: boolean;
    isError?: boolean;
}

const ButtonDelete = (props: any) => {    
    return <InputAdornment position="end" sx={{ml: '0px'}} >
      <IconButton
        sx={{ backgroundColor: 'transparent', 
         width: '30px', 
         mb: '25px',
         ml: '0px',
         pr: '0px'
        }}
        disableFocusRipple
        disableRipple
        onClick={ props.onClick }
      >
        <GroupDeleteIcon />
      </IconButton>
    </InputAdornment>
}



const InputField = (props: TextFieldProps) => {
    let timeMaskValue = '__:__'
    const [inputValue, setInputValue] = useState( 
        props.value ? props.value : (props.type === 'time' ? timeMaskValue : '')
    )
    const [position, setPosition] = useState(0)

    const inputRef: any = React.useRef<HTMLInputElement>();


    if(props.type === 'time') {
        if(props.value === '' && inputValue === '__:__') {
            setInputValue('')
        }
    }


    const inputChangeHandler = useCallback((el) => {
       let value = el.target.value.replace(/[^0-9,.]/g, "");
        let currentPositon = el.target.selectionStart
        let firstNumValue = Number(value.slice(0, 1))
        let hourValue = Number(value.slice(0, 2))
        let minuteValue = Number(value.slice(2, 4))
        if (props.type==='number' && props.maxValue) {
            if (Number(value) > props.maxValue) value = props.maxValue
        }
        if (props.type==="time") {
            if(firstNumValue > 2 || hourValue > 23) value = '2359'
            if(minuteValue > 59) value = `${hourValue}59`
            if (value.length < 4) value += "_____".slice(1, 6-value.length)
            let i = 0
            value = "##:##".replace(/#/g, _ => value[i++])
            if (currentPositon===3) currentPositon++

        }  else if (props.type==="number") {
            value = value
        }
        setInputValue(value)
        setPosition(currentPositon > 5 ? 5 : currentPositon)
    }, [])

    useEffect(() => {       
        if (inputRef) inputRef.current.selectionStart = inputRef.current.selectionEnd = position;
    }, [position]);

  let sx = {
    width: props.width ? props.width : '120px',
    
    m: '4px',
    ml: '0px',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor:  COLORS.main,
      },
    },
     "& .MuiOutlinedInput-notchedOutline legend": { display: props.label === '' ? "none" : '', }
  }

  const enterPressHandler = (e) => {
    if (e.key === 'Enter') {
        let tmpCurrentValue = inputRef.current.value
        if(tmpCurrentValue === '__:__') {tmpCurrentValue = ""}
        else {
            tmpCurrentValue = tmpCurrentValue.replaceAll('_', '0')
        }
        props.onBlur(tmpCurrentValue)
    }
  }

  const inputOnBlurHandler = () => {
    let tmpCurrentValue = inputRef.current.value
    if(tmpCurrentValue === '__:__') {tmpCurrentValue = ""}
    else {
        tmpCurrentValue = tmpCurrentValue.replaceAll('_', '0')
    }
    setInputValue(tmpCurrentValue)
    props.onBlur(tmpCurrentValue)
  }


  const percentForNum = Number(props.foodWeight) / 100
  const valueForHelperText = percentForNum * Number(inputValue)
    return <TextField
        key={ props.key }
        error={ props.isError }
        onChange={ inputChangeHandler }
        onBlur={ inputOnBlurHandler }
        label={props.label}
        value={ inputValue }
        helperText={props.foodWeight ? `${valueForHelperText.toFixed(2)} кг` : '' }
        placeholder={props.type === 'time' ? '__:__' : props.label}
        inputRef={ inputRef }
        fullWidth
        autoFocus={props.autoFocus}
        sx={ props.sx ? props.sx : sx }
        onFocus={event => {
            event.target.select();
          }}
        onKeyPress={enterPressHandler}
        FormHelperTextProps={{
            sx: {
                position: 'absolute', display: 'flex', justifyContent: 'center', width: '88px',
                mt: '35px', pr: '5px'
            }
        }}
        InputProps={{
            sx: {
                pr: '0px',
                borderRadius: '8px',
                // 
            },
            endAdornment:( props.onClose ? 
                <ButtonDelete onClick={ props.onClose }/>
            : (props.label === 'Вес, %' ? <TypographyPrimary sx={{position: 'absolute', ml: '73px'}}>%</TypographyPrimary>
             : (props.isStart ? <TypographyPrimary sx={{position: 'absolute', ml: '71px', mt: '1px'}}>%</TypographyPrimary>  : <></>)))
        }}
        inputProps={{
            style: {
                fontSize: '14px',
                lineHeight: '17px',
                display: 'flex',
                alignItems: 'center',
                textAlign: props.isStart ? "center" : (props.type === 'time' ? 'left' : 'center') ,
                paddingLeft: props.type === 'time' ? '33px' : '' ,
                fontWeight: 400,
                color:  COLORS.black,
                marginRight: '16px',
                paddingTop: props.isHead ? '10px' : '',
                paddingBottom: props.isHead ? '10px' : '',
                
                
            },
            form: {
                style: {
                    backgroundColor: COLORS.mainSecondaryOpacity,
                    height: '18px'
                }
            },
        }}
        InputLabelProps={{
            style: {
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '14px',
                lineHeight: '17px',
                fontWeight: 400,
                color: COLORS.icon,
                
            }
        }}
    />
}

export default InputField