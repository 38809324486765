import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axAPI from '../http'

export const fetchCattlesQuantity = createAsyncThunk(
    'counter/cattles',
    async function(_, {rejectWithValue}) {      
        try {
            const response = await axAPI({
                method: 'GET',
                url: 'groups/cattles_qty',
            })
            
            if (response.status!==200) {
                throw new Error('HTTP request error!')
            }
            return response.data
        } catch(error) {
            let errorMessage = 'Failed to retrive groups!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

const initialState = {
    cattles_qty: 0,
    loading: false,
    error: '',
}

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchCattlesQuantity.fulfilled.type]: (state, action: PayloadAction<number>) => {
            state.loading = false;
            state.error = '';
            state.cattles_qty = action.payload;
        },
        [fetchCattlesQuantity.pending.type]: (state) => {
            state.loading = true;
            state.cattles_qty = 0;
        },
        [fetchCattlesQuantity.rejected.type]: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload
        },
    }
})

export default counterSlice.reducer;