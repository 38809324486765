import { Card, CardContent } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { TypographyHeader } from '../../ui/TypographyHeader'
import { NavLink } from 'react-router-dom';

const FarmFillCard = (props) => {
  return <NavLink to={`${props.id}`}><Card sx={{
    mt: '24px',
    borderRadius: '12px',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)', 
    '&:hover': {boxShadow: '0px 4px 16px 0px rgba(82, 34, 208, 0.24)'}, 
    }}>
    <CardContent>
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' sx={{pt: '8px'}}>
            <Box display='flex' flexDirection='row' alignItems='center'>
                <TypographyHeader>{props.title}</TypographyHeader> 
            </Box>
            <ArrowForwardIosIcon />
        </Box>
    </CardContent>
</Card></NavLink>
}

export default FarmFillCard