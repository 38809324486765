import { useEffect } from "react";

import { Box, Typography } from "@mui/material"
import {ReactComponent as CowCounterLogo} from "../../assets/cow_counter_logo.svg"

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchCattlesQuantity } from '../../store/counterSlice';

const CowCounter = () => {

    const dispatch = useAppDispatch()
    const { cattles_qty } = useAppSelector(state => state.counterReducer)

    useEffect(() => {
        dispatch(fetchCattlesQuantity())
    }, [])

    return <Box sx={{display: 'flex', pl: '16px'}}>
        <CowCounterLogo />
        <Typography sx={{fontSize: 16, fontWeight: 600, paddingBottom: '8px', paddingLeft: '4px'}}>
         Всего голов {cattles_qty}
    </Typography>
    </Box> 
}

export default CowCounter