import { Card, Grid, Stack, Typography, Box } from '@mui/material'

import GroupBarn from './GroupBarn'
import GroupCard from './GroupCard'
import GroupInfo from './GroupInfo'
import { IGroupEntity } from '../../models/IGroup';
import { COLORS } from '../../styles/colors';

import { NavLink } from 'react-router-dom';
import { TypographySecondary } from '../../ui/TypographySecondary';
import { yesterdayDate } from '../feed/GetPoolCards';

const GroupItem = (props: IGroupEntity) => {
  return (
		<NavLink to={props.groupkey}>
			<Card
				sx={{
					width: 'auto',
					height: '360px',
					minWidth: '300px',
					boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					borderRadius: '12px',
					backgroundColor: 'white',
					'&:hover': { boxShadow: '0px 4px 16px 0px rgba(82, 34, 208, 0.24)' },
				}}
			>
				<Grid container direction='column'>
					<Grid item>
						<GroupBarn {...props} />
					</Grid>
					<Grid item>
						<Stack direction='column'>
							<GroupInfo
								reachable={props.foodtable.reachable}
								color={COLORS.success}
								cows={props.cattles_qty}
								farmTable={`${props.foodtable.reachable}% доступно`}
								ration={props.rationtitle}
							/>
						</Stack>
					</Grid>
					<Grid item>
						<Typography
							sx={{
								fontWeight: 600,
								fontSize: '16px',
								lineHeight: '20px',
								paddingLeft: '24px',
								paddingTop: '24px',
								paddingBottom: '16px',
								display: 'flex',
								alignItems: 'baseline',
								justifyContent: 'flex-start',
							}}
						>
							Показатели <TypographySecondary sx={{ pl: '4px' }}>Вчера, {yesterdayDate}</TypographySecondary>
						</Typography>
						<Stack direction='row' justifyContent='space-around' sx={{ pl: '16px', pr: '16px', pb: '16px' }}>
							<Box sx={{ width: '30%' }}>
								<GroupCard bulk='Среднее ПСВ' changeValue={1} count={props.food_dry_per_head} ratio='кг/гол' />
							</Box>
							<Box sx={{ width: '30%' }}>
								<GroupCard bulk='Роздано корма' changeValue={1} count={props.previous_distribution_weight} ratio='кг' />
							</Box>
							<Box sx={{ width: '30%' }}>
								<GroupCard bulk='Остаток корма' changeValue={1} count={props.previous_cleaning_weight} ratio='кг' />
							</Box>
						</Stack>
					</Grid>
				</Grid>
			</Card>
		</NavLink>
	)
}

export default GroupItem