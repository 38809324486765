import { configureStore } from '@reduxjs/toolkit'
import groupsReducer from './groupsSlice'
import groupAnalyticsReducer from './groupAnalyticsSlice'
import authReducer from './authSlice'
import userReducer from './userSlice'
import counterReducer from './counterSlice'
import barnReducer from './barnSlice'
import rationReducer from './rationSlice'
import feedPlanReducer from './feedPlanSlice'
import notificationsSlice from './notificationsSlice'

const store = configureStore({
  reducer: {
    groupsReducer,
    groupAnalyticsReducer,
    authReducer,
    userReducer,
    counterReducer,
    barnReducer,
    rationReducer,
    feedPlanReducer,
    notificationsSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store