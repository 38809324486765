import { useRef } from 'react';

import { Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material'
import { TypographySecondary } from '../../ui/TypographySecondary'
import InputField from '../shared/InputField'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { TypographyHeader } from '../../ui/TypographyHeader';
import { TypographyPrimary } from '../../ui/TypographyPrimary';
import { COLORS } from '../../styles/colors';
import { ButtonUI } from '../../ui/ButtonUI';
import { useAppSelector } from '../../store/hooks';
import { ReactComponent as CrossCloseLogo } from '../../assets/cross_close_logo.svg'

import GroupsApiService from '../../services/groupsApiService';
import CheckApiService from '../../services/checkApiService';
import axAPI from '../../http';
import { EventsEnum } from '../../models';

const GroupHeadEditPopup = (props) => {
  
  const { groupList } = useAppSelector(store => store.groupsReducer)

  const changesList = useRef<{groupId: number, quantity: number}[]>([])

  const saveLocalQuantityChanges = (groupId: number, quantity: number) => {
    const changesIndex = changesList.current.findIndex(el => el.groupId === groupId)
    if (changesIndex === -1) changesList.current.push(
      {
        groupId: groupId,
        quantity: quantity
      }
    )
  }

  const saveChangesAtServer = () => {
		axAPI({
			method: 'POST',
			url: `/groups/qty_change`,
			data: changesList.current,
		}).then(response => {
			if (response.status === 201)
		axAPI({
			method: 'POST',
			url: `/check/${EventsEnum.CATTLE_QUANTITY}`,
		}).then(resonse => {
			if (resonse.status === 201) props.handleClose()
		})
		})

		// GroupsApiService.postGroupsQuantityChange(changesList.current).then(response => {
		// 	if (response.status === 201) return
		// 	CheckApiService.postCheckCattleQuantity().then(resonse => {
		// 		if (resonse.status === 201) props.handleClose()
		// 	})
		// })
	}

  return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			maxWidth='lg'
			fullWidth
			scroll='body'
			PaperProps={{
				style: { borderRadius: '12px' },
			}}
		>
			<DialogContent>
				<Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
					<TypographyHeader>Количество голов в группах</TypographyHeader>
					<CrossCloseLogo onClick={()=> props.handleClose()} style={{ cursor: 'pointer' }} />
				</Box>
				<TypographyPrimary sx={{ pt: '24px' }}>
					При изменении количества голов в группе - введите новые данные. Общий вес раздачи в группе будет автоматически пересчитан.
				</TypographyPrimary>
				<TypographyPrimary sx={{ pt: '24px', color: COLORS.secondary }}>Всего групп: {groupList.length}</TypographyPrimary>
				<Box sx={{ pt: '24px' }}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell sx={{ borderBottom: `1px solid ${COLORS.secondaryFontOpacity}` }}>
										<TypographySecondary>Группы</TypographySecondary>
									</TableCell>
									<TableCell align='left' sx={{ borderBottom: `1px solid ${COLORS.secondaryFontOpacity}` }}>
										<TypographySecondary>Рационы</TypographySecondary>
									</TableCell>
									<TableCell align='center' sx={{ borderBottom: `1px solid ${COLORS.secondaryFontOpacity}` }}>
										<TypographySecondary>Общий вес раздач</TypographySecondary>
									</TableCell>
									<TableCell align='right' sx={{ borderBottom: `1px solid ${COLORS.secondaryFontOpacity}` }}>
										<TypographySecondary>Количество голов</TypographySecondary>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{groupList.map(group => (
									<TableRow key={`qtyChangesTableRow${group.id}`} sx={{ pb: '24px' }}>
										<TableCell sx={{ borderBottom: 'none' }}>
											<Box display='flex' flexDirection='row' alignItems='baseline'>
												<TypographyPrimary sx={{ pr: '4px' }}>{group.title}</TypographyPrimary>
												<TypographySecondary>{group.barntitle}</TypographySecondary>
											</Box>
										</TableCell>
										<TableCell align='left' sx={{ minWidth: '150px', borderBottom: 'none' }}>
											<TypographyPrimary>{group.rationtitle ? group.rationtitle : 'Не заполнен'}</TypographyPrimary>
										</TableCell>
										<TableCell align='center' sx={{ borderBottom: 'none' }}>
											<TypographyPrimary>{group.cattles_qty}0 кг</TypographyPrimary>
										</TableCell>
										<TableCell key={`tableCellQtyChangesInputField${group.id}`} align='right' sx={{ borderBottom: 'none' }}>
											<TypographyPrimary>
												<InputField
													key={`qtyChangesInputField${group.id}`}
													value={group.cattles_qty}
													isHead
													label=''
													type='number'
													sx={{
														width: '160px',
														'& .MuiOutlinedInput-root': {
															'& fieldset': {
																mt: '5px',
															},
															'&.Mui-focused fieldset': {
																borderColor: COLORS.secondaryFontOpacity,
															},
														},
														'& .MuiOutlinedInput-notchedOutline legend': { display: 'none' },
													}}
													onBlur={value => saveLocalQuantityChanges(group.id, value)}
												/>
											</TypographyPrimary>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
				<Box display='flex' justifyContent='center'>
					<ButtonUI sx={{ textTransform: 'none', height: '56px' }} onClick={saveChangesAtServer}>
						Сохранить
					</ButtonUI>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

export default GroupHeadEditPopup;