import React from 'react'
import '../styles/module.LinearProgressBar.css'

const LinearProgressBar = ({reachable}) => {
  return (
		<div className={'mainProgressBarDiv'}>
			<div className={'emptyProgressBar'} style={{ width: '100%' }}>
				<div
					className={'fillingProgressBar'}
					style={{
						left: reachable - 100 + '%',
						transition: '3s',
					}}
				/>
			</div>
		</div>
	)
  
}

export default LinearProgressBar