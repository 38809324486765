import React from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';

import {ReactComponent as WaitingLogo} from '../../assets/waiting_logo.svg'

import { useAppSelector } from '../../store/hooks';
import { IPlanTableProps } from '../../models'
import { COLORS } from '../../styles/colors';
import { TypographySecondary } from '../../ui/TypographySecondary';
import { IFeedTableProps } from '../../models/IFeed'

const PlanNudgeTable = (props: IFeedTableProps) => {

  const { feedPlanData } = useAppSelector(store => store.feedPlanReducer)
  const { groupList }  = useAppSelector(store => store.groupsReducer)

  return <Box sx={{ pt: '24px' }}>
  <TableContainer sx={{p: '12px 18px 12px 16px'}}>
    <Table sx={{
      minWidth: 650,

      [`& .${tableCellClasses.root}`]: {
        borderBottom: "none"
      }
    }} aria-label="simple table">
      <TableHead >
        <TableRow sx={{borderBottom: `1px solid ${COLORS.secondaryFontOpacity}`, }}>
          <TableCell align='left'><TypographySecondary>Группа</TypographySecondary> </TableCell>
          <TableCell align='left' ><TypographySecondary>Плановое время</TypographySecondary> </TableCell>
          <TableCell align='left' ><TypographySecondary>Фактическое время</TypographySecondary> </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.groupsArray.map((el) => (
          <TableRow
            key={ el.group.id }
            sx={{}}
          >
            <TableCell component="th" scope="row" align='left' sx={{width: '350px'}}>
            <Box display='flex' flexDirection='row' alignItems='baseline' >
              { el.group.title} 
              <TypographySecondary sx={{pl: '4px'}}>
                { el.group.barn.title }
              </TypographySecondary>
            </Box>
            </TableCell>
            <TableCell align='left' sx={{pl: '40px', width: '300px'}} >
              { el.plan_time ? el.plan_time : "00:00" }
            </TableCell>
            <TableCell align='left' sx={{pl: '72px'}} >
              { el.fact_dt ? el.fact_dt : <WaitingLogo /> }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
</Box>
}

export default PlanNudgeTable