import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { COLORS } from '../../styles/colors';

import { useAppSelector } from '../../store/hooks';
import { Box } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IRationCheckmarksProps {
  checkedGroups: number[]
  label: string
  sx: any
  selectionCallback?: any
}

export default function RationCheckmarks(props: IRationCheckmarksProps) {

  const { groupList } = useAppSelector(store => store.groupsReducer)

  const checkedGroupsTitles = props.checkedGroups.map(chId => groupList.find(g => g.id === chId).title)

  return (
    <Box>
      <FormControl sx={props.sx}>
        <InputLabel id="demo-multiple-checkbox-label"  
        sx={{
          '&.MuiInputLabel-root' :{
          '&.Mui-focused': {
            color: COLORS.black,
            borderColor: COLORS.secondaryFontOpacity
          }}
        }}
        >{props.label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          sx={{'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.main,
        },

          }}
          value={checkedGroupsTitles}
          input={<OutlinedInput label="Группы" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {groupList.map((group) => (
            <MenuItem key={'groupMenuItem' + group.id} value={group.title} title={group.title} onClick={() => props.selectionCallback(group.id)}>
              <Checkbox sx={{
                '&.Mui-checked': {
                  color: COLORS.main,
                },
              }}
                checked={props.checkedGroups.indexOf(group.id) !== -1} />
              <ListItemText primary={group.title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}