import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import axAPI from '../../http'
import moment from 'moment'
import GroupEatenLegend from './GroupEatenLegend'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { COLORS } from '../../styles/colors'


interface IGroupApexChart {
	id?: number
	r_feed?: number
	start_dt: number
	t_feed: number
	ur_feed?: number
}

const GroupApexChart = (props: {
	id: number
	startDate: number
	endDate: number
}) => {
	const [eatenChartData, setEatenChartData] = useState<IGroupApexChart[]>([])
	const [loading, setLoading] = useState<boolean>(false)



	useEffect(() => {
		setLoading(true)
		axAPI
		.get(
			`cattle-group/${props.id}/foodtable/graph?start=${props.startDate}&end=${props.endDate}`
		)
		.then(response => {
			setEatenChartData(response.data);
		})
		.catch(error => {
			console.log(error);
		})
		.finally(() => {
			setLoading(false);
		});
	}, [props.id, props.startDate, props.endDate])

	const oneDay: number = 24 * 60 * 60 * 1000 // Количество миллисекунд в одном дне
	const start: any = new Date(props.startDate)
	const end: any = new Date(props.endDate)
	const diffDays = Math.round(Math.abs((end - start) / oneDay))

	let data = []

	// Итерируем каждый день и добавляем массивы в data
	for (let i = 0; i < diffDays; i++) {
		const currDay = new Date(start.getTime() + i * oneDay) // Текущий день
		const currDayStart = new Date(
			currDay.getFullYear(),
			currDay.getMonth(),
			currDay.getDate(),
			0,
			0,
			0
		) // Начало текущего дня
		const currDayEnd = new Date(
			currDay.getFullYear(),
			currDay.getMonth(),
			currDay.getDate(),
			23,
			59,
			59,
			999
		) // Конец текущего дня

		const dataArray = eatenChartData.filter(obj => {
			const objDate = new Date(obj.start_dt)
			return objDate >= currDayStart && objDate <= currDayEnd
		})

		// Добавляем объекты в начало и конец массива
		dataArray.unshift({ start_dt: currDayStart.getTime(), t_feed: null })
		dataArray.push({ start_dt: currDayEnd.getTime(), t_feed: null })

		// Объединяем массивы в один
		data = data.concat(dataArray)
	}
	// const dataYasis = data.map(el => el.t_feed !== null ? el.t_feed.toFixed(3) : el.t_feed)
	const dataYasis = eatenChartData.map(el => el.t_feed !== null ? el.t_feed.toFixed(3) : el.t_feed)
	// const dataXasis = data.map(el => new Date(el.start_dt).getTime())
	const dataXasis = eatenChartData.map(el => new Date(el.start_dt).getTime())

	const series: any = [
		{
			name: 'Объём корма',
			data: dataYasis,
		},
	]

	const options: any = {
		mask: false,
		colors: ['#65C466'],
		chart: {
			animations: {
        enabled: false,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    },

			height: 250,
			type: 'area',
			toolbar: {
				show: false,
			},
		},
		fill: {
			type: 'gradient',
			gradient: {
				opacityFrom: 1,
				opacityTo: 0.9,
				stops: [60, 100],
			},
		},
		title: {
			style: {
				fontSize: '12px',
				fontFamily: 'Montserrat',
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: '15px',
				color: '#7F7F84',
			},
			text: 'Объём корма, м³',
		},

		dataLabels: {
			enabled: false,
		},
		grid: {
			strokeDashArray: [6, 3],
			show: true,
			borderColor: '#e4e4e4',
			xaxis: {
				lines: {
					show: true,
					style: {
						colors: ['#DBDBDB'],
						width: 1,
					},
				},
			},
		},
		stroke: {
			width: 0,
		},
		xaxis: {
			title: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				text: 'Время',
			},
			type: 'datetime',
			tooltip: {
				enabled: false,
			},
			labels: {
				datetimeUTC: false,
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				// formatter: function(val, timestamp) {
				// 	if (props.startDate + 86399000 === props.endDate) {
				// 		return moment(new Date(timestamp)).format('HH:mm');
				// 	} else {
				// 		return timestamp
				// 	}
				// },
				datetimeFormatter: {
					year: 'yyyy',
					month: "MMM 'yy",
					day: 'dd/MMM',
					hour: 'HH:mm',
				},
			},
			categories: dataXasis,
		},
		yaxis: {
			tickAmount: 5,
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
			},
		},
		tooltip: {
			custom: ({ series, seriesIndex, dataPointIndex, w }) => {
				const value = parseFloat(series[seriesIndex][dataPointIndex])
				const isAvailable = value !== 0 && value !== null && !isNaN(value)
				const timeValue = moment(
					w.config.xaxis.categories[dataPointIndex]
				).format('HH:mm')
				const mass = (value * 350).toFixed(2)
				return `<div style='width: 170px; height: 90px; background-color: #fff; border-radius:12px; border: none;'>
				<div style='display: flex; flex-direction: column; padding: 16px 8px;'>
					<div style='display: flex; flex-direction: row; margin-bottom: 6.5px; align-items: center;'>
						<div style='width: 12px; height: 12px; border-radius: 50%; background-color: ${
							isAvailable ? '#65C466' : '#EB4D3D'
						}; margin-right: 4px;'></div>
						<p style='margin: 0'>${isAvailable ? 'Корм доступен' : 'Корм недоступен'}</p>
					</div>
					<div style='display: flex; flex-direction: row; margin-bottom: 6.5px; align-items:end'>
						<p style='font-weight: 600; font-size: 16px; line-height: 19.5px; color: #000; margin: 0'>${
							isAvailable ? value : '0'
						}</p>
						<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0; margin-right: 15px;'>м³</p>
						<p style='font-weight: 600; font-size: 16px; line-height: 19.5px; color: #000; margin: 0'>${
							isAvailable ? mass : '0'
						}</p>
						<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0'>кг</p>
					</div>
					<div style='display: flex; flex-direction: row;'>
						<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0'>${timeValue}</p>
					</div>
				</div>
			</div>`
			},
		},
	}

	if (!eatenChartData) return <></>

	return (
		<div style={{ marginLeft: '-6px' }} id='chart'>
			{loading 
			? 
			<div style={{display: 'flex', flexDirection: 'row', justifyContent:'center', alignItems:'center'}}>
			<CircularProgress sx={{ '&.MuiCircularProgress-colorPrimary': { color: COLORS.main,  } }} />
			</div>
			: 
			<>
			<ReactApexChart
				options={options}
				series={series}
				type='area'
				height={250}
			/>
			</>
		}
		<GroupEatenLegend
				id={props.id}
				startDate={props.startDate}
				endDate={props.endDate}
			/>
		</div>
	)
}

export default GroupApexChart
