import React from 'react'
import './styles/popUp.css'

import { CardContent } from '@mui/material'
import Dialog from '@mui/material/Dialog'

type Props = {
	active: boolean
	setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const PopUpRationItem: React.FC<Props> = ({ active, setActive, children }) => {
	return (
		<Dialog open={active} maxWidth='lg' scroll='body'>
			<div className={active ? 'modal active-modal' : 'modal'} onClick={() => setActive(false)}>
				<CardContent>
					<div className={active ? 'modal-content active-modal' : 'modal-content'} onClick={e => e.stopPropagation()}>
						{children}
					</div>
				</CardContent>
			</div>
		</Dialog>
	)
}

export default PopUpRationItem