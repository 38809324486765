import { styled, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import { useEffect, useRef } from 'react'
import { COLORS } from '../../styles/colors'
import { ReactComponent as ArrowNotif } from '../../assets/arrow_notification.svg'
import NotificationButton from '../../ui/NotificationButton'
import NotificationsContent from './NotificationsContent'

// const drawerWidth = '28.4%'
const drawerWidth = '410px'

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	zIndex: 1111,
	background: 'transparent',
	border: '0.1px solid transparent',
	borderRadius: '12px 0 0 12px',
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
	borderRadius: '12px 0 0px 12px',
	background: COLORS.main,
	boxShadow: `0px 4px 16px ${COLORS.main}`,
	border: 'none',
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	// width: `calc(${theme.spacing(7)})`,
	// [theme.breakpoints.up('sm')]: {
	width: `calc(${theme.spacing(1)})`,
	// },
})

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	display: 'flex',
	boxSizing: 'border-box',

	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}))

export default function MiniDrawer({
	openNotifications,
	setOpenNotifications,
	countNotif,
	setCountNotif,
}) {
	const notificationsRef = useRef(null)

	const handleDrawer = () => {
		setOpenNotifications(prevState => !prevState)
	}

	const handleClickOutside = event => {
		if (
			notificationsRef.current &&
			!notificationsRef.current.contains(event.target)
		) {
			setOpenNotifications(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('touchstart', handleClickOutside)
		document.addEventListener('touchend', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('touchstart', handleClickOutside)
			document.addEventListener('touchend', handleClickOutside)
		}
	}, [notificationsRef])

	return (
		<>
			<CssBaseline />
			<Drawer
				variant='permanent'
				anchor='right'
				open={openNotifications}
				sx={{ border: 'none' }}
			>
				<Box
					sx={{
						height: '100vh',
						backgroundColor: `${COLORS.mainOpacity}`,
						backdropFilter: 'blur(11px)',
						overflow: openNotifications ? 'auto' : 'hidden',
						overscrollBehavior:'contain',
						
							overflowX: 'hidden',
							'&::-webkit-scrollbar': {
								width: 4,
							},
							'&::-webkit-scrollbar-track': {
								backgroundColor: '#DBDBDB',
								borderRadius: 12,
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#5222D0',
								borderRadius: 12,
							},
					}}
				>
					<List sx={{ margin: '42px 26px' }} ref={notificationsRef}>
						<NotificationsContent
							open={openNotifications}
							countNotif={countNotif}
							setCountNotif={setCountNotif}
						/>
					</List>
				</Box>
			</Drawer>
			<Box>
				<IconButton
					disableRipple={true}
					sx={{
						position: 'fixed' as 'fixed',
						top: '85px',
						right: openNotifications ? '401px' : '0px',
						cursor: 'pointer',
						background: 'transparent',
						transition: openNotifications ? '0.194s ease-in' : '0.15s ease-in',
						border: 'none',
						zIndex: '9000',
					}}
					onClick={handleDrawer}
				>
					<Box
						sx={{
							border: 'none',
							width: '24px',
							height: '132px',
							background: 'inherit',
							overflow: 'visible',
						}}
					>
						<NotificationButton
							color={
								openNotifications ? `${COLORS.mainOpacity}` : `${COLORS.main}`
							}
						/>
					</Box>
				</IconButton>
				<ArrowNotif
					onClick={handleDrawer}
					style={{
						position: 'fixed' as 'fixed',
						top: '150px',
						zIndex: '100001',
						cursor: 'pointer',
						right: openNotifications ? '407px' : '5px',
						transform: openNotifications ? 'rotate(180deg)' : 'rotate(0deg)',
						transition: '0.2s ease-in-out',
					}}
				/>
			</Box>
		</>
	)
}
