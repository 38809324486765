import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { ThemeProvider } from '@emotion/react'
import { Box } from '@mui/material'

import { fontTheme } from '../styles/font'
import { ButtonUI } from '../ui/ButtonUI'

import CreatePlanListDistribution from '../components/plan/CreatePlanListDistribution'
import CreatePlanListCleaning from '../components/plan/CreatePlanListCleaning'
import CreatePlanListNudge from '../components/plan/CreatePlanListNudge'

import { useAppSelector, useAppDispatch } from '../store/hooks';
import { fetchFeedPlanData } from '../store/feedPlanSlice';
import { fetchGroupList } from '../store/groupsSlice'
import { PlanEventTypeEnum } from '../models'
import axAPI from '../http'

import { notifyUser } from '../store/notificationsSlice'

type CreatePlanPageProps = {
  isFirstTry?: boolean
}

const CreatePlanPage = (props: CreatePlanPageProps) => {

  const theme = fontTheme

  const { feedPlanData } = useAppSelector(store => store.feedPlanReducer)
  const { groupList } = useAppSelector(store => store.groupsReducer)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  async function saveChangesAtServer() {
    axios.all([
      axAPI({
        method: 'PUT',
        url: 'feedplan/event',
        data: feedPlanData.events
      }),
      axAPI({
        method: 'POST',
        url: 'feedplan/plan',
        data: {
          schedule: feedPlanData.plan_data.map(el => {
            if (!el.time || el.time === '00:00') return { ...el, time: '00:00', active: false }
            return { ...el }
          }),
          start_date: null,
        }
      }),
    ])
    .then((response) => {
      dispatch(notifyUser({
        title: 'План кормления',
        description: 'Изменения успешно сохранены',
        isError: false,
      }))
      navigate( props.isFirstTry ? '/fill/1/done' : '/feed/plan', { state: { showNotification: true } })
    })
    .catch(error => {      
      dispatch(notifyUser({
        title: 'План кормления',
        description: 'Ошибка при сохранении плана кормления',
        isError: true,
      }))
      setSaveButtonDisabled(false)
    })
  }

  useEffect(() => {
    if (!groupList || !groupList.length) dispatch(fetchGroupList())
    if (!feedPlanData) dispatch(fetchFeedPlanData({}))
  }, [])

  return <ThemeProvider theme={theme}>
    <Box sx={{ fontFamily: theme.typography.fontFamily, pl: props.isFirstTry ? '0px' : '24px', maxWidth: '100%' }}>
      {
        (feedPlanData && feedPlanData.events.length)
        ? feedPlanData.events.map((el) => {
            let currentEvent
            if (el.id===PlanEventTypeEnum.Distribution) currentEvent = <CreatePlanListDistribution { ...el } isFirstTry={props.isFirstTry} />
            if (el.id===PlanEventTypeEnum.Cleaning) currentEvent = <CreatePlanListCleaning { ...el } isFirstTry={props.isFirstTry} />
            if (el.id===PlanEventTypeEnum.Nudge) currentEvent = <CreatePlanListNudge { ...el } isFirstTry={props.isFirstTry} />
            return <Box key={`elType${el.id}`}>{ currentEvent }</Box>
          })
        : <></>
      }
      <Box display='flex' justifyContent={props.isFirstTry ? 'flex-end' : 'center'}>
        <ButtonUI
          sx={{width: props.isFirstTry ? '600px' : '400px'}}
          disabled={ saveButtonDisabled }
          onClick={ () => { setSaveButtonDisabled(true); saveChangesAtServer() } } >
          { props.isFirstTry ? 'Сохранить и перейти к следующему шагу' : 'Сохранить'}
        </ButtonUI>
      </Box>
    </Box>
  </ThemeProvider>
}



export default CreatePlanPage