import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import React from 'react'
import DeleteButton from '../../ui/DeleteButton'
import NotificationItem from './NotificationItem'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import './styles/notif.css'
import axAPI from '../../http'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { COLORS } from '../../styles/colors'

const NotificationsCurrent = ({
	currentNotif,
	remove,
	setCurrentNotifications,
	setCountNotif,
	fetching,
}) => {
	const removeAllCurrentNotifications = () => {
		setCurrentNotifications([])
		axAPI({
			method: 'PATCH',
			url: `notifications/bulk`,
			data: { id: [], close_today: true, close_old: false },
		}).then(response => {
			if (response.status === 201)
				axAPI({
					method: 'GET',
					url: 'notifications/count',
				}).then(response => {
					setCountNotif(response.data.new_count)
				})
		})
	}
	return (
		<Box sx={{ marginBottom: '16px' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '8px',
				}}
			>
				<Typography
					sx={{
						fontSize: '14px',
						fontWeight: '400',
						lineHeight: '16.8px',
						fontFamily: 'Montserrat',
						color: '#fff',
					}}
				>
					Сегодня
				</Typography>
				<Box onClick={removeAllCurrentNotifications}>
					<DeleteButton children={'Очистить'} />
				</Box>
			</Box>
				<TransitionGroup>
					{currentNotif.map(el => (
						<CSSTransition key={el.id} timeout={500} classNames={'notif'}>
							<NotificationItem
								remove={remove}
								id={el.id}
								title={el.title}
								text={el.text}
								groups={el.groups}
								created_dt={el.created_dt}
							/>
						</CSSTransition>
					))}
				</TransitionGroup>
				{fetching ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress
						sx={{
							'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
						}}
					/>
				</Box>
			) : <></>}
		</Box>
	)
}

export default NotificationsCurrent
