import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import { COLORS } from '../styles/colors';

export const OutlinedButton = styled(Button)(( theme ) => ({
    marginTop: "32px",
    backgroundColor: 'white',
    color: COLORS.main,
    height: '56px', 
    width: '27.7vw',
    textTransform: 'none',
    border:`2px solid ${COLORS.main}` ,
    borderRadius: '12px',
    '&:hover': {backgroundColor: COLORS.main, color: 'white', boxShadow: '0px 4px 16px rgba(82, 34, 208, 0.4)'},
    "&:disabled": {
      color: 'white',
      backgroundColor: COLORS.secondaryFontOpacity,
      border:`1px solid ${COLORS.secondaryFontOpacity}`
    }
}))
