import React from 'react'


const NotificationButton = ({color}) => {
  return (
    <svg
      style={{ pointerEvents: 'all' }}
      viewBox='0 0 24 132'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
        <filter id="blur">
          <feGaussianBlur in="SourceGraphic" stdDeviation="0" />
        </filter>
      <path
        d='M0 66C0 93.3381 24 107.25 24 132L24 0C24 28.875 0 38.6619 0 66Z'
        fill={color}
        filter="url(#blur)"
      />
    </svg>
  )
}


export default NotificationButton
