import axAPI from '../http'
import { IGroupEntity } from '../models';

export default class GroupsApiService {
	static postGroupsQuantityChange = async (data: { groupId: number; quantity: number }[]) => {
		return axAPI({
			method: 'POST',
			url: `/groups/qty_change`,
			data: data,
		})
	}
	static postGroupChartData = async (data: {}) => {
		return axAPI({
			method: 'POST',
			url: `groups/group_psv_chart_data`,
			data: {
				data,
			},
		})
	}
	static putGroupsDataKey = async (groupData: IGroupEntity) => {
		return axAPI({
			method: 'PUT',
			url: `groups/${groupData.key}`,
			data: groupData,
		})
	}
	static postGroupKey = async (firstDate, lastDate, props: IGroupEntity) => {
		return axAPI({
			method: 'POST',
			url: `groups/count/${props.groupkey}`,
			data: {
				start_date: firstDate,
				end_date: lastDate,
			},
		})
	}
	static getGroupList = async () => {
		return axAPI.get('/groups/list')
	}
}
