import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { COLORS } from '../../styles/colors'
import { ReactComponent as Arrow } from '../../assets/icons/nav_arrow_down.svg'
import { Theme, makeStyles } from '@mui/material/styles'

interface ISelectionField {
	id: string
	inputLabel: string
	width?: number | string
	currentValue?: any
	choiceList: { value: any; title: string }[]
	onChange?: any
	sx?: any
	isErrorFocus?: any
	onFocus?: () => void
}

export default function SelectionField(props: ISelectionField) {
	const sx = {
		width: '47%',
		// minWidth: props.width ? props.width : 120,
    '& label.Mui-focused': {
      color: `${COLORS.main}`,
      fontSize:'16px',
    },
		'& .MuiOutlinedInput-root': {
			fontSize: '14px',
			borderRadius: '8px',
			borderColor: props.isErrorFocus
				? COLORS.deviationFont
				: COLORS.hoverInputBorder,
			'&.Mui-focused fieldset': {
				border: `1px solid ${COLORS.main}`,
        color: COLORS.main
			},
      '&:hover fieldset': {
        border: `1px solid ${COLORS.hoverInputBorder}`
      },
		},
		'&.MuiOutlinedInput-notchedOutline': {
			borderColor: props.isErrorFocus
				? COLORS.deviationFont
				: COLORS.secondaryFontOpacity,
		},
		
	}

	const menuProps = {
		PaperProps: {
			style: {
				maxHeight: 250,
			},
		},
	}



	return (
		<Box sx={props.sx ? props.sx : sx}>
			<FormControl sx={{ width: '100%' }}>
				<InputLabel
					id={props.id}
				>
					{props.inputLabel}
				</InputLabel>
				<Select
					IconComponent={Arrow}
					labelId={`select-label-${props.id}`}
					id={`select-${props.id}`}
					value={props.currentValue}
					label={props.inputLabel}
					onChange={(e: SelectChangeEvent) => props.onChange(e.target.value)}
					MenuProps={menuProps}
					sx={{
						'&.Mui-selected': {
							backgroundColor: COLORS.backgroundHover,
							color: COLORS.main,
							'&:hover': {
								backgroundColor: COLORS.backgroundHover,
								color: COLORS.main,
							},
						},
					}}
				>
					{props.choiceList.map(el => (
						<MenuItem
							key={`selectionValue${el.value}`}
							value={el.value}
							sx={{
								color: COLORS.secondaryFont,
								fontSize: '14px',
								fontWeight: '400',
								'&:hover': {
									color: COLORS.main,
								},				
								'&.Mui-selected': {
									backgroundColor: COLORS.backgroundHover,
									color: COLORS.main,
									'&:hover': {
										backgroundColor: COLORS.backgroundHover,
										color: COLORS.main,
									},
								},
							}}
						>
							{el.title}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	)
}
