import { styled } from '@mui/material';
import Button from '@mui/material/Button';

export const ButtonUI = styled(Button)(( theme ) => ({
    marginTop: "32px",
    backgroundColor: '#5222D0',
    color: '#FFFFFF',
    height: '7vh', 
    width: '27.7vw', 
    textTransform: 'none',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: '#5222D0',
      color: '#FFFFFF',
      boxShadow: '0px 4px 16px rgba(82, 34, 208, 0.4)'
    },
    "&:disabled": {
      backgroundColor: 'rgba(219, 219, 219, 1)',
      color: 'rgba(255, 255, 255, 1)'
    }
}))
