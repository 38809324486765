import React from 'react'

import { TableCell } from '@mui/material'
import { Box } from '@mui/system'

import { COLORS } from '../../styles/colors'

import { PlanEventTypeEnum } from '../../models';
import { useAppDispatch } from '../../store/hooks';
import { setFeedPlanEventInputValue } from '../../store/feedPlanSlice'
import TimeField from '../shared/TimeField';
import InputField from '../shared/InputField';

type CreatePlanCellProps = {
  groupId?: number;
  time?: string;
  weight?: number;
  food_weight?: any;
  index?: number;
  callBack?: any;
  additionIndex?: number;
}

const CreatePlanTableCell = (props: CreatePlanCellProps) => {

  const dispatch = useAppDispatch()

  const sx = {
    width: '100px',
    mr: '8px',
    mt: '4px',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.secondaryFontOpacity,
      },
    },
  }

  return <TableCell align='right'>
    <Box display='flex' flexDirection='row'>
      <TimeField
        key={`planTimeTableCell${props.groupId}${props.index}${props.additionIndex ? props.additionIndex : ''}`}
        value={ props.time ? props.time : '' }
        width='100px'
        onBlur={(value) => {
          dispatch(setFeedPlanEventInputValue({ groupId: props.groupId, event: PlanEventTypeEnum.Distribution, key: 'time', value: value, index: props.index }))
        }}
      />
      <InputField
        key={`planWeightTableCell${props.groupId}${props.index}`}
        value={ props.weight ? props.weight : '0' }
        foodWeight={Number(props.food_weight)}
        type={"number"}
        maxValue={100}
        label={"Вес, %"}
        sx={sx}
        onBlur={(value) => {
          dispatch(setFeedPlanEventInputValue({ groupId: props.groupId, event: PlanEventTypeEnum.Distribution, key: 'weight_percent', value: value, index: props.index }))
        }}
      />
    </Box>
  </TableCell>
}

export default CreatePlanTableCell