import formatDistance from "date-fns/locale/ru/_lib/formatDistance";
import formatRelative from "date-fns/locale/ru/_lib/formatRelative";
import localize from "date-fns/locale/ru/_lib/localize";
import match from "date-fns/locale/ru/_lib/match";
import formatLong from "date-fns/locale/ru/_lib/formatLong";
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn'


export const antdMonths = {
  months: [
    "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь"
  ],
  monthsShort: [
    "Янв.",
    "Фев.",
    "Март",
    "Апр.",
    "Май",
    "Июнь",
    "Июль",
    "Авг.",
    "Сент.",
    "Окт.",
    "Нояб.",
    "Дек."
  ]
}

const monthValues = {
  narrow: ["Я", "Ф", "М", "А", "М", "И", "И", "А", "С", "О", "Н", "Д"],
  abbreviated: [
    "Янв.",
    "Фев.",
    "Март",
    "Апр.",
    "Май",
    "Июнь",
    "Июль",
    "Авг.",
    "Сент.",
    "Окт.",
    "Нояб.",
    "Дек."
  ],
  wide: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
  ]
};

export const ruLocale = {
  formatDistance,
  formatLong,
  formatRelative,
  localize: {
    ...localize,
    month: buildLocalizeFn({
      values: monthValues,
      defaultWidth: "wide",
      defaultFormattingWidth: "wide"
    })
  },
  match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};