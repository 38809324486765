import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { COLORS } from '../../styles/colors'
import { CardStandart } from '../../ui/CardStandart'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { TypographySecondary } from '../../ui/TypographySecondary'

import axAPI from '../../http'

type historyTableProps = {
	date: string[]
}

const HistoryTable = (props: historyTableProps) => {
	const urlParams = useParams()

	const [historyData, setHistoryData] = useState([])

	useEffect(() => {
		let url = `/feedplan/history/${props.date[1]}`
		if (urlParams.groupkey) url += `/${urlParams.groupkey}`
		axAPI({
			method: 'GET',
			url: url,
		}).then(response => {
			setHistoryData(response.data)
		})
	}, [urlParams.groupkey, props.date])

	const LookBtn = () => {
		const sx = {
			color: COLORS.main,
			textTransform: 'none',
			textDecoration: 'underline',
			borderRadius: 0,
			p: 0,
			'&:hover': {
				backgroundColor: 'transparent',
			},
		}

		return (
			<Button
				variant='text'
				sx={sx}
				disableRipple
				disableTouchRipple
				disableFocusRipple
			>
				{/* Смотреть */}
			</Button>
		)
	}

	if (!historyData) return <></>

	return (
		<CardStandart
			sx={{ mt: '24px', pl: '24px', pr: '24px', minHeight: '570px' }}
		>
			<TableContainer sx={{ pt: '8px' }}>
				<Table sx={{width:'100%'}}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '200px', pl: '0px' }}>
								<TypographySecondary>Дата изменения</TypographySecondary>
							</TableCell>
							<TableCell sx={{ width: '200px' }} align='left'>
								<TypographySecondary>Группы</TypographySecondary>
							</TableCell>
							<TableCell>
								<TypographySecondary>Изменение</TypographySecondary>
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{historyData.map((data, index) => (
							<TableRow key={index} sx={{width:'100%'}}>
								<TableCell sx={{ verticalAlign: 'text-top', pl: '0px' }}>
									{data.date}
								</TableCell>
								<TableCell sx={{ verticalAlign: 'text-top' }}>
									{data.groups.map(group => (
										<TypographyPrimary>{group.group_title}</TypographyPrimary>
									))}
								</TableCell>
								<TableCell sx={{ verticalAlign: 'text-top', }}>
									{data.description.map(el => (
										<div>{el}</div>
									))}
								</TableCell>
								<TableCell align='right' sx={{ pr: '0px' }}>
									<LookBtn />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</CardStandart>
	)
}

export default HistoryTable
