import { Box, Card, CardContent } from '@mui/material'
import React, { useEffect } from 'react'

import { ReactComponent as AnalyticsDownloadLogo } from '../../assets/analytics_download_logo.svg'
import { fetchFeedPlanData } from '../../store/feedPlanSlice'
import { useAppDispatch } from '../../store/hooks'
import { COLORS } from '../../styles/colors'

import { OutlinedButton } from '../../ui/OutlinedButton'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { TypographySecondary } from '../../ui/TypographySecondary'
import GroupDate from '../group/GroupDate'
import AnalyticsConsumptionList from './AnalyticsConsumptionList'
import AnalyticsDeviationList from './AnalyticsDeviationList'
import AnalyticsSpendingList from './AnalyticsSpendingList'
import axAPI from '../../http'
import moment from 'moment'

import fileSaver from 'file-saver'
import RangeDate from '../shared/RangeDate'
import { firstDayHandler } from '../group/utils'

const AnalyticsPage = () => {
	const [color, setColor] = React.useState(COLORS.secondaryFont)

    const primaryDateState = firstDayHandler()

	const [dateRange, setDateRange] = React.useState({
		startDate: Date.parse(primaryDateState[0]),
		endDate: Date.parse(primaryDateState[1]),
	})

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}

	const handleExportClick = () => {
		axAPI
			.get(
				`analytics/food-intake/export?start=${dateRange.startDate}&end=${dateRange.endDate}`,
				{ responseType: 'arraybuffer' }
			)
			.then(response => {
				const blob = new Blob([response.data], {
					type: 'application/vnd.ms-excel',
				})
				fileSaver.saveAs(blob, `Сводный отчет ${moment(dateRange.startDate).format('DD.MM.YYYY')} - ${moment(dateRange.endDate).format('DD.MM.YYYY')}.xlsx`)
			})
	}

	return (
		<Box sx={{ pt: '16px', mr: '24px' }}>
			<Card
				sx={{
					ml: '16px',
					mr: '32px',
					boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					backgroundColor: 'white',
					borderRadius: '12px',
				}}
			>
				<CardContent>
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<TypographyHeader>Сводный отчет</TypographyHeader>
						<Box display='flex' flexDirection='row'>
							<OutlinedButton
								onClick={handleExportClick}
								onMouseOver={() => setColor(COLORS.main)}
								onMouseLeave={() => setColor(COLORS.secondaryFont)}
								sx={{
									width: '128px',
									height: '40px',
									margin: '0px',
									color: color,
									border: `1px solid ${COLORS.secondaryFontOpacity}`,
									textTransform: 'none',
									fontWeight: 600,
									'&:hover': {
										backgroundColor: 'transparent',
										color: color,
										borderColor: COLORS.main,
										boxShadow: 'none',
									},
								}}
								startIcon={
									<AnalyticsDownloadLogo
										onMouseOver={() => setColor(COLORS.main)}
										onMouseLeave={() => setColor(COLORS.secondaryFont)}
										stroke={color}
									/>
								}
							>
								Экспорт
							</OutlinedButton>
							{/* <Box sx={{ pl: '24px', pr: '16px' }}>
								<GroupDate
									value={feedPlanDate}
									onChange={newDate => setFeedPlanDate(newDate)}
								/>
							</Box> */}
                            <Box sx={{ pl: '24px', pr: '16px' }}>
                            <RangeDate onChangeUpdateDate={onChangeCallback} />
                            </Box>
						</Box>
					</Box>
					<Box sx={{ pt: '32px', pl: '2px' }}>
						<TypographySecondary>
							Включает в себя информацию по всем группам по следующим
							показателям: Количество голов, Поступление корма, СВ, Остаток
							корма, ПСВ
						</TypographySecondary>
					</Box>
				</CardContent>
			</Card>
			{/* <AnalyticsDeviationList />
			<AnalyticsConsumptionList />
			<AnalyticsSpendingList /> */}
		</Box>
	)
}

export default AnalyticsPage
