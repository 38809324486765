import { NavLink } from 'react-router-dom'

import { Box, Card, Divider, Typography } from '@mui/material'
import { ReactComponent as RationEditLogo } from '../../assets/ration_edit_logo.svg'
import { COLORS } from '../../styles/colors'

import { IRationEntity, SchedulerEventDataType } from '../../models'
import { ReactComponent as CrossCloseLogo } from '../../assets/cross_close_logo.svg'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { TypographySecondary } from '../../ui/TypographySecondary'
import { useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import PopUpRationItem from './modal/PopUpRationItem'

const RationItem = (props: IRationEntity & SchedulerEventDataType) => {
	const [modalActive, setModalActive] = useState<boolean>(false)
	const { rationList } = useAppSelector(store => store.rationReducer)

	if (!rationList) return <></>

	if (!props.rationKey) return <></>

	const labelForTitleList = gtitles => {
		const arrayLabel = gtitles.split(', ')
		const onceLabelWithStyle = arrayLabel.map(el => {
			return (
				<Box
					key={props.unique_key}
					style={{
						fontSize: '14px',
						padding: '8px 8px',
						background: '#f7f7f7',
						borderRadius: '12px',
						whiteSpace: 'nowrap',
					}}
				>
					{el}
				</Box>
			)
		})
		return onceLabelWithStyle
	}

	return (
		<Card
			sx={{
				display: 'flex',
				flexDirection: 'column',
				boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
				borderRadius: '12px',
				marginBottom: '12px',
				backgroundColor: 'white',
			}}
		>
			<Box
				flexDirection='row'
				display='flex'
				justifyContent='space-between'
				sx={{ pt: '24px' }}
			>
				<TypographyHeader
					sx={{
						display: 'flex',
						alignItems: 'center',
						pl: '24px',
					}}
				>
					{' '}
					{props.title}
					<TypographySecondary
						sx={{
							pl: '8px',
						}}
					>
						Дата создания {props.created_at.slice(0, 8)}
					</TypographySecondary>
				</TypographyHeader>
				<NavLink to={props.rationKey}>
					<Box sx={{ pr: '24px' }}>
						<RationEditLogo />
					</Box>
				</NavLink>
			</Box>
			<Divider
				sx={{ ml: '24px', mr: '24px', pt: '24px', color: COLORS.secondary }}
			/>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				sx={{ pt: '24px', pr: '24px', width: '40%' }}
			>
				<TypographyPrimary
					sx={{
						display: 'flex',
						alignItems: 'center',
						pl: '24px',
						color: COLORS.secondaryFont,
					}}
				>
					Сухое вещество:
					<Typography
						sx={{
							color: COLORS.black,
							pl: '8px',
						}}
					>
						{props.dry}%
					</Typography>
				</TypographyPrimary>
				{/* <TypographyPrimary
					sx={{
						display: 'flex',
						alignItems: 'center',
						pl: '24px',
						color: COLORS.secondaryFont,
					}}
				>
					Норма остатков:
					<Typography
						sx={{
							color: COLORS.black,
							pl: '8px',
						}}
					>
						{props.balance}%
					</Typography>
				</TypographyPrimary> */}
			</Box>
			<Box
				sx={{
					pt: '24px',
					display: 'flex',
					alignItems: 'center',
					width: '100%',
					pl: '24px',
					color: COLORS.secondaryFont,
				}}
			>
				<Typography
					sx={{
						paddingTop: '6px',
						color: COLORS.secondaryFont,
						fontSize: '14px',
					}}
				>
					Группы:
				</Typography>
				<Box
					sx={{
						color: COLORS.black,
						pl: '8px',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						flexWrap: 'wrap',
						gap: '10px',
					}}
				>
					{props.gtitles ? (
						labelForTitleList(props.gtitles)
					) : (
						<TypographyPrimary
							sx={{ color: COLORS.secondaryFont, paddingTop: '5px' }}
						>
							Не используется в группах
						</TypographyPrimary>
					)}
				</Box>
			</Box>
			<Box sx={{ pt: '24px', pb: '24px' }}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						pl: '24px',
						color: COLORS.secondaryFont,
					}}
				>
					<Typography sx={{ color: COLORS.secondaryFont, fontSize: '14px' }}>
						Состав:
					</Typography>
					<Typography
						sx={{
							color: COLORS.black,
							pl: '8px',
						}}
					>
						<div
							style={{
								color: '#5222D0',
								textDecoration: 'underline',
								cursor: 'pointer',
								fontSize: '14px',
							}}
							onClick={() => setModalActive(true)}
						>
							Просмотреть
						</div>
						<PopUpRationItem active={modalActive} setActive={setModalActive}>
							<>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										marginBottom: '5px',
									}}
								>
									<h2 style={{ fontSize: '20px', fontWeight: 'bolder' }}>
										Состав рациона
									</h2>
									<div onClick={() => setModalActive(false)}>
										<CrossCloseLogo style={{ cursor: 'pointer' }} />
									</div>
								</div>
								<h3
									style={{
										fontSize: '16px',
										fontWeight: 'bolder',
										marginBottom: '18px',
									}}
								>
									Рацион: {props.title}
								</h3>
								<p
									style={{
										fontSize: '14px',
										fontWeight: 'normal',
										marginBottom: '28px',
									}}
								>
									СВ: {props.dry}%
								</p>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										borderBottom: `1px solid ${COLORS.secondaryFontOpacity}`,
									}}
								>
									<p
										style={{
											fontSize: '12px',
											color: `${COLORS.secondaryFont}`,
											lineHeight: '15px',
										}}
									>
										Состав
									</p>
									<p
										style={{
											fontSize: '12px',
											color: `${COLORS.secondaryFont}`,
											paddingRight: '88px',
										}}
									>
										Вес, %
									</p>
								</div>
								<div style={{ marginTop: '18px' }}>
									{props.components.map(component => (
										<div
											key={component.itemKey}
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<p
												style={{
													fontSize: '14px',
													fontWeight: 'normal',
													marginTop: '14px',
												}}
											>
												{component.item}
											</p>
											<p
												style={{
													fontSize: '14px',
													fontWeight: 'normal',
													paddingRight: '88px',
													marginTop: '14px',
												}}
											>
												{Number(component.coef)} %
											</p>
										</div>
									))}
								</div>
							</>
						</PopUpRationItem>
					</Typography>
				</Box>
			</Box>
		</Card>
	)
}

export default RationItem
