import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'

import { styled } from '@mui/material/styles'

import { fetchUserData } from '../store/userSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { Box, CssBaseline, IconButton, InputAdornment } from '@mui/material'

import { ReactComponent as ProfileIcon } from '../assets/profile_icon.svg'
import { ReactComponent as BlindPassLogo } from '../assets/blind_pass_logo.svg'
import { ReactComponent as UnBlindPassLogo } from '../assets/unblind_pass_logo.svg'

import useAuth from '../hook/useAuth'
import { ButtonUI } from '../ui/ButtonUI'
import { fontTheme } from '../styles/font'
import { ThemeProvider } from '@mui/system'
import { COLORS } from '../styles/colors'
import { TypographyPrimary } from '../ui/TypographyPrimary'

const theme = fontTheme

const ProfileItem = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	borderRadius: '12px',
	//color: theme.palette.text.secondary,
}))

function ProfilePage() {
	const authState = useAuth()

	const [oldPass, setOldPass] = useState('')
	const [newPass, setNewPass] = useState('')

	const [showInfoMessages, setShowInfoMessages] = useState(false)

	const [showOldPass, setShowOldPass] = useState(false)
	const [showNewPass, setShowNewPass] = useState(false)

	const [errorState, setErrorState] = useState(true)

	const handleClickShowOldPass = () => setShowOldPass(!showOldPass)
	const handleClickShowNewPass = () => setShowNewPass(!showNewPass)

	const handleMouseDownOldPass = () => setShowOldPass(!showOldPass)
	const handleMouseDownNewPass = () => setShowNewPass(!showNewPass)

	const dispatch = useAppDispatch()
	const { userData } = useAppSelector(state => state.userReducer)
	const { passwordError, passwordUpdated } = useAppSelector(
		state => state.authReducer
	)
	useEffect(() => {
		dispatch(fetchUserData())
	}, [])

	const validators = {
		newPass: () => {
			const newPassValidate = newPass.match(
				/^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,}$/
			)
			const isError = !newPassValidate
			if (errorState !== isError) {
				setErrorState(isError)
			}
		},
	}

	const newPassHandler = e => {
		setNewPass(e.target.value)
		validators.newPass()
	}

	const setPasswordHandler = () => {
		setShowInfoMessages(true)
		authState.setPasswordAndContinue(oldPass, newPass)
	}

	const DetailsData = (title: string, value: string) => {
		return (
			<TypographyPrimary
				sx={{
					color: '#7F7F84',
					pl: '24px',
					pb: '33.5px',
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				{title}:
				<TypographyPrimary sx={{ pl: '8px' }}>{value}</TypographyPrimary>
			</TypographyPrimary>
		)
	}

	// временно, надо переделать
	// элемент управления должен прижиматься относительно нижнего края бокса
	// т.е. паддинги не должны регулировать высоту родительских копонента
	// т.е. правый див и левый див должны быть одинаковы сами по себе
	let button_margin_top = '110px'
	if (showInfoMessages && (passwordError || passwordUpdated)) {
		button_margin_top = '56px'
	}
	if (passwordError && passwordError.length > 35) {
		button_margin_top = '26px'
	}
	// временно, надо переделать

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Grid
				container
				// spacing={2}
				// columns={{ xs: 4, sm: 6, md: 6, lg: 12 }}
				sx={{ fontFamily: theme.typography.fontFamily, display:'flex', flexDirection:'row', gap:'30px'}}
			>
				<Grid
					sx={{ width:'48%', minHeight:'auto' }}
				>
					<ProfileItem
						sx={{
							
							pb: '72px',
							boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
						}}
					>
						<Typography
							sx={{ fontSize: '20px', fontWeight: 700, pt: '24px', pl: '24px' }}
						>
							Личные данные
						</Typography>
						<IconButton
							sx={{ mt: '32px', ml: '24px', backgroundColor: 'transparent' }}
							disableRipple
						>
							<ProfileIcon />
							<Button
								disableFocusRipple
								size='small'
								sx={{
									color: '#000000',
									ml: '16px',
									'&:hover': { backgroundColor: 'transparent' },
								}}
								variant='text'
							>
								Изменить фото профиля
							</Button>
						</IconButton>
						<Typography
							sx={{ fontSize: '16px', fontWeight: 600, pt: '24px', pl: '24px' }}
						>
							{userData.firstname ? userData.firstname : 'Христорождественская'}{' '}
							{userData.lastname ? userData.lastname : 'Абдурахмангаджи'}
						</Typography>
						<Divider sx={{ mt: '32px', mb: '32px', ml: '20px', mr: '16px' }} />
						{DetailsData('Должность', userData.position)}
						{DetailsData('E-mail', userData.email)}
					</ProfileItem>
				</Grid>
				<Grid
					sx={{ width:'48%', minHeight:'auto' }}
				>
					<ProfileItem
						sx={{ boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)' }}
					>
						<Typography
							sx={{ fontSize: '20px', fontWeight: 700, pt: '24px', pl: '24px' }}
						>
							Сменить пароль
						</Typography>
						<TextField
							fullWidth
							id='outlined-basic'
							label='Старый пароль'
							sx={{
								ml: '24px',
								pr: '36px',
								mt: '41px',
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: COLORS.main,
									},
								},
								'& label.Mui-focused': {
									color: COLORS.main,
								},
							}}
							type={showOldPass ? 'text' : 'password'}
							variant='outlined'
							value={oldPass}
							onChange={e => setOldPass(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowOldPass}
											onMouseDown={handleMouseDownOldPass}
										>
											{showOldPass ? <UnBlindPassLogo /> : <BlindPassLogo />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<TextField
							fullWidth
							id='outlined-basic'
							label='Новый пароль'
							value={newPass}
							sx={{
								ml: '24px',
								pr: '36px',
								mt: '36px',
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: COLORS.main,
									},
								},
								'& label.Mui-focused': {
									color: COLORS.main,
								},
							}}
							variant='outlined'
							type={showNewPass ? 'text' : 'password'}
							onChange={e => newPassHandler(e)}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowNewPass}
											onMouseDown={handleMouseDownNewPass}
										>
											{showNewPass ? <UnBlindPassLogo /> : <BlindPassLogo />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<Box
							display='flex'
							flexDirection='row'
							alignItems='center'
							sx={{ pt: '24px', pl: '24px' }}
						>
							<Box
								sx={{
									width: '12px',
									height: '12px',
									backgroundColor: errorState
										? 'rgba(196, 196, 196, 1)'
										: COLORS.success,
									borderRadius: '12px',
								}}
							></Box>
							<TypographyPrimary sx={{ color: '#7F7F84', pl: '8px' }}>
								{' '}
								Не менее 8 символов
							</TypographyPrimary>
						</Box>
						<Box
							display='flex'
							flexDirection='row'
							alignItems='center'
							sx={{ pt: '8px', pl: '24px' }}
						>
							<Box
								sx={{
									width: '12px',
									height: '12px',
									backgroundColor: errorState
										? 'rgba(196, 196, 196, 1)'
										: COLORS.success,
									borderRadius: '12px',
								}}
							></Box>
							<TypographyPrimary sx={{ color: '#7F7F84', pl: '8px' }}>
								Минимум одна заглавная или одна строчная буква
							</TypographyPrimary>
						</Box>

						{showInfoMessages && passwordError ? (
							<Typography
								sx={{
									fontSize: '20px',
									fontWeight: 700,
									pt: '24px',
									pl: '24px',
								}}
							>
								{passwordError}
							</Typography>
						) : (
							''
						)}
						{showInfoMessages && passwordUpdated ? (
							<Typography
								sx={{
									fontSize: '20px',
									fontWeight: 700,
									pt: '24px',
									pl: '24px',
								}}
							>
								{'Пароль успешно изменен'}
							</Typography>
						) : (
							''
						)}

						<ButtonUI
							onClick={setPasswordHandler}
							disabled={!oldPass || !newPass}
							sx={{
								ml: '19px',
								mt: button_margin_top,
								mb: '20px',
								mr: '36px',
								height: '60px',
								width: '35vw',
							}}
						>
							Сохранить изменения
						</ButtonUI>
					</ProfileItem>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default ProfilePage
