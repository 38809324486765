import { useState } from 'react';
import './styles/font.css'
import './App.css';


import { Navigate, Route, Routes } from 'react-router-dom';

import LoginPage from './layouts/LoginPage';

import FeedGroupsPage from './layouts/FeedGroupsPage';
import ProfilePage from './layouts/ProfilePage';
import Layout from './layouts/Layout';

import { RequireAuth } from './hoc/RequireAuth'
import { AuthProvider } from './hoc/AuthProvider'
import { GroupsPage } from './layouts/GroupsPage';
import { RationPage } from './layouts/RationPage';
import ViewPlanPage from './layouts/ViewPlanPage';
import GroupPage from './layouts/GroupPage';
import CreatePlanPage from './layouts/CreatePlanPage';
import SettingPage from './components/settings/SettingPage';
import ManagementPage from './components/settings/ManagementPage';
import AnalyticsPage from './components/analytics/AnalyticsPage';
import FillPage from './layouts/FillPage';
import FarmFillPage from './components/fill/FarmFillPage';
import FarmFillRation from './components/fill/FarmFillRation';
import FarmFillGroups from './components/fill/FarmFillGroups';
import GroupSpecificEdit from './components/group/GroupSpecificEdit';
import FarmFillFeedPlan from './components/fill/FarmFillFeedPlan';
import FarmFillDonePage from './components/fill/FarmFillDonePage';
import HistoryPage from './components/history/HistoryPage';
import DeviationPage from './components/deviations/DeviationPage';
import EditPlanPage from './components/plan/EditPlanPage';


function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to='/feed' replace />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='profile' element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          } />
          <Route path='fill' element={
            <RequireAuth>
              <FillPage />
            </RequireAuth>
          } />
          <Route path='fill/:id' element={
            <RequireAuth>
              <FarmFillPage />
            </RequireAuth>
          } />
          <Route path='fill/:id/ration' element={
            <RequireAuth>
              <FarmFillRation />
            </RequireAuth>
          } />
          <Route path='fill/:id/ration/:rationkey' element={
            <RequireAuth>
              <FarmFillRation />
            </RequireAuth>
          } />
          <Route path='fill/:id/groups' element={
            <RequireAuth>
              <FarmFillGroups />
            </RequireAuth>
          } />
          <Route path='fill/:id/feedplan' element={
            <RequireAuth>
              <FarmFillFeedPlan />
            </RequireAuth>
          } />
           <Route path='fill/:id/done' element={
            <RequireAuth>
              <FarmFillDonePage />
            </RequireAuth>
          } />
          <Route path='settings' element={
            <RequireAuth>
              <SettingPage />
            </RequireAuth>
          } />
         
          <Route path='settings/management/' element={
            <RequireAuth>
              <ManagementPage />
            </RequireAuth>
          } />
          <Route path='feed' element={
            <RequireAuth>
              <FeedGroupsPage />
            </RequireAuth>
          }/> 
           <Route path='feed/deviations' element={
            <RequireAuth>
              <DeviationPage />
            </RequireAuth>
          }/> 
          <Route path='feed/analytics' element={
            <RequireAuth>
              <AnalyticsPage />
            </RequireAuth>
          } />
            <Route path='feed/groups/' element={
            <RequireAuth>
              <GroupsPage />
            </RequireAuth>
          } />
           <Route path='feed/groups/:groupkey' element={
            <RequireAuth>
              <GroupPage />
            </RequireAuth>
          } />
          <Route path='feed/groups/:groupkey/edit' element={
            <RequireAuth>
              <GroupSpecificEdit />
            </RequireAuth>
          } />
          <Route path='feed/ration' element={
            <RequireAuth>
              <RationPage />
            </RequireAuth>
          } />

          <Route path='feed/ration/:rationkey' element={
            <RequireAuth>
              <RationPage />
            </RequireAuth>
          } />
          <Route path='feed/plan' element={
            <RequireAuth>
              <ViewPlanPage />
            </RequireAuth>
          } />
          <Route path='feed/plan/create' element={
            <RequireAuth>
              <CreatePlanPage />
            </RequireAuth>
          } />
           <Route path='feed/plan/edit' element={
            <RequireAuth>
              <EditPlanPage />
            </RequireAuth>
          } />
          <Route path='feed/plan/history' element={
            <RequireAuth>
              <HistoryPage />
            </RequireAuth>
          } />
          <Route path='feed/plan/history/:groupkey' element={
            <RequireAuth>
              <HistoryPage />
            </RequireAuth>
          } />
          <Route path="*" element={<Navigate to='/feed' replace />} />
        </Route>
      </Routes>
    </AuthProvider>
  )
}

export default App;
