import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import {
	Box,
	ClickAwayListener,
	Collapse,
	Divider,
	IconButton,
	List,
	ThemeProvider,
	Tooltip,
	Typography,
} from '@mui/material'

import { Grid } from '@mui/material'
import { ReactComponent as ArrowCloseDone } from '../assets/arrow_close_done.svg'
import { ReactComponent as ArrowOpenDone } from '../assets/arrow_open_done.svg'
import { ReactComponent as EditFeedLogo } from '../assets/edit_feed_logo.svg'
import { ReactComponent as HistoryLogo } from '../assets/history_logo.svg'
import { ReactComponent as SettingLogo } from '../assets/setting_logo.svg'
import GroupDate from '../components/group/GroupDate'
import { COLORS } from '../styles/colors'
import { fontTheme } from '../styles/font'
import { ReactComponent as LoadFeedLogo } from '../assets/load_feed_logo.svg'
import { ReactComponent as QuestionLogo } from '../assets/question_mark.svg'
import { ReactComponent as DoneLogo } from '../assets/done_mark.svg'
import { ReactComponent as DeviationLogo } from '../assets/deviation_logo.svg'
import axAPI from '../http'
import moment from 'moment'
import { TypographyPrimary } from '../ui/TypographyPrimary'
import TestPlanCardView from '../components/plan/TestPlanCardView'
import fileSaver from 'file-saver'

const theme = fontTheme

const FeedTooltip = ( props ) => {
	const [color, setColor] = React.useState(COLORS.icon)
	const [historyColor, setHistoryColor] = React.useState(COLORS.icon)
	const [exportColor, setExportColor] = React.useState(COLORS.icon)
	const handleExportClick = () => {
		axAPI
			.get(
				`feedplans/${props.value.toISOString().slice(0, 10)}/export`,
				{ responseType: 'arraybuffer' }
			)
			.then(response => {
				const blob = new Blob([response.data], {
					type: 'application/vnd.ms-excel',
				})
				fileSaver.saveAs(blob, `План кормления ${moment(props.value).format('DD.MM.YYYY')}.xlsx`)
			})
	}

	return (
		<Box>
			<Box
				display='flex'
				flexDirection='row'
				sx={{ pt: '8px' }}
				alignItems='center'
				onMouseOver={() => setExportColor(COLORS.main)}
				onMouseLeave={() => setExportColor(COLORS.secondaryFont)}
			>
				<LoadFeedLogo 
				fill={exportColor}
				stroke={exportColor}
				onMouseOver={() => setExportColor(COLORS.main)}
				onMouseLeave={() => setExportColor(COLORS.secondaryFont)}
				/>
				<Typography
					sx={{
						color: exportColor,
						pl: '10px',
						fontWeight: 400,
						fontSize: '17px',
						lineHeight: '14px',
						cursor:'pointer',
					}}
					onClick={handleExportClick}
				>
					Экспорт
				</Typography>
			</Box>
			<NavLink to='edit'>
				<Box
					display='flex'
					flexDirection='row'
					alignItems='center'
					marginTop={'-10px'}
					onMouseOver={() => setColor(COLORS.main)}
					onMouseLeave={() => setColor(COLORS.secondaryFont)}
				>
					<EditFeedLogo
						fill={color}
						onMouseOver={() => setColor(COLORS.main)}
						onMouseLeave={() => setColor(COLORS.secondaryFont)}
					/>
					<Typography
						sx={{
							color: color,
							pl: '10px',
							fontWeight: 400,
							fontSize: '17px',
							lineHeight: '14px',
							'&:hover': { color: COLORS.main },
						}}
					>
						Редактировать план
					</Typography>
				</Box>
			</NavLink>
			<NavLink to='history'>
				<Box
					display='flex'
					flexDirection='row'
					sx={{ pb: '12px' }}
					alignItems='center'
					onMouseOver={() => setHistoryColor(COLORS.main)}
					onMouseLeave={() => setHistoryColor(COLORS.secondaryFont)}
				>
					<HistoryLogo
						stroke={historyColor}
						onMouseOver={() => setHistoryColor(COLORS.main)}
						onMouseLeave={() => setHistoryColor(COLORS.secondaryFont)}
					/>
					<Typography
						sx={{
							color: historyColor,
							pl: '10px',
							fontWeight: 400,
							fontSize: '17px',
							lineHeight: '14px',
							'&:hover': { color: COLORS.main },
						}}
					>
						История изменений
					</Typography>
				</Box>
			</NavLink>
		</Box>
	)
}

const ViewPlanPage = () => {
	const [open, setOpen] = React.useState(false)
	const [listOpen, setListOpen] = React.useState(false)
	const [feedPlanDate, setFeedPlanDate] = React.useState<Date | null>(
		new Date()
	)
	const [testPlanData, setTestPlanData] = useState<any[]>()

	useEffect(() => {
		axAPI
			.get(`feedplans/${feedPlanDate.toISOString().slice(0, 10)}`)
			.then(resonse => setTestPlanData(resonse.data))
	}, [feedPlanDate])

	if (!testPlanData) return <></>

	const now = new Date()
	const hours = now.getHours().toString().padStart(2, '0')
	const minutes = now.getMinutes().toString().padStart(2, '0')
	const seconds = now.getSeconds().toString().padStart(2, '0')

	const timeString: string = `${hours}:${minutes}:${seconds}`

	const day = now.getDate().toString().padStart(2, '0')
	const month = (now.getMonth() + 1).toString().padStart(2, '0')
	const year = now.getFullYear().toString()

	const feedPlanDateString = moment(feedPlanDate).format('DD-MM-YYYY')
	const dateString = `${day}-${month}-${year}`

	const currentDataRation = testPlanData.filter(el => el.time > timeString)
	const completedDataRation = testPlanData.filter(el => el.time <= timeString)

	const isPastTime = feedPlanDateString < dateString
	const isTodayTime = feedPlanDateString === dateString
	const isFutureTime = feedPlanDate > new Date()
	const settingsArea = () => {
		return (
			<Box
				display='flex'
				flexDirection='row'
				sx={{ pt: '16px' }}
				justifyContent='flex-end'
				alignItems='baseline'
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						pr: '24px',
					}}
				>
					<GroupDate
						value={feedPlanDate}
						onChange={newDate => setFeedPlanDate(newDate)}
					/>
					<Box sx={{ pl: '10px', pt: '6px' }}>
						<ClickAwayListener onClickAway={() => setOpen(false)}>
							<Box>
								<Tooltip
									placement='bottom-start'
									componentsProps={{
										tooltip: {
											sx: {
												boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
												backgroundColor: 'white',
												marginTop: '12px',
											},
										},
									}}
									PopperProps={{
										disablePortal: true,
									}}
									onClose={() => setOpen(false)}
									open={open}
									disableFocusListener
									disableHoverListener
									disableTouchListener
									title={<FeedTooltip value={feedPlanDate}/>}
								>
									<IconButton sx={{ mb: '4px' }} onClick={() => setOpen(!open)}>
										<SettingLogo stroke={COLORS.secondaryFont} />
									</IconButton>
								</Tooltip>
							</Box>
						</ClickAwayListener>
					</Box>
				</Box>
			</Box>
		)
	}
	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ fontFamily: theme.typography.fontFamily, pl: '16px' }}>
				<Grid
					container
					spacing={1}
					columns={{ xs: 6, sm: 6, md: 6, lg: 6 }}
					display='flex'
					flexDirection='column'
					justifyContent='space-between'
				>
					<List sx={{ ml: '8px', pb: '12px' }}>
						{testPlanData ? (
							<>
								<Box
									display='flex'
									flexDirection='row'
									alignItems='baseline'
									sx={{
										height: '65px',
										justifyContent:
											completedDataRation.length === 0 ? 'space-between' : '',
									}}
								>
									{completedDataRation.length === 0 ? (
										<Box></Box>
									) : (
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												width: '100%',
												gap: '8px',
											}}
										>
											<TypographyPrimary
												sx={{ color: COLORS.secondaryFont, cursor: 'pointer' }}
												onClick={() => {
													setListOpen(!listOpen)
												}}
											>
												Завершенные
											</TypographyPrimary>
											{isTodayTime ? (
												<Typography
													onClick={() => {
														setListOpen(!listOpen)
													}}
													sx={{
														fontSize: '16px',
														lineHeight: '20px',
														fontWeight: '600',
														color: COLORS.secondaryFont,
														cursor: 'pointer',
													}}
												>
													{completedDataRation.length}
												</Typography>
											) : (
												<></>
											)}
											{isPastTime ? (
												<Typography
													onClick={() => {
														setListOpen(!listOpen)
													}}
													sx={{
														fontSize: '16px',
														lineHeight: '20px',
														fontWeight: '600',
														color: COLORS.secondaryFont,
														cursor: 'pointer',
													}}
												>
													{completedDataRation.length +
														currentDataRation.length}
												</Typography>
											) : (
												<></>
											)}
											{isFutureTime ? (
												<Typography
													onClick={() => {
														setListOpen(!listOpen)
													}}
													sx={{
														fontSize: '16px',
														lineHeight: '20px',
														fontWeight: '600',
														color: COLORS.secondaryFont,
														cursor: 'pointer',
													}}
												>
													0
												</Typography>
											) : (
												<></>
											)}

											<Box
												sx={{
													pt: '8px',
													cursor: 'pointer',
												}}
												onClick={() => {
													setListOpen(!listOpen)
												}}
											>
												{listOpen ? <ArrowCloseDone /> : <ArrowOpenDone />}
											</Box>
											{completedDataRation
												.map(el =>
													el.feed_facts.some(
														group => group.has_fact_data_demand === true
													)
												)
												.some(el => el === true) && isFutureTime !== true ? (
												<Box
													sx={{
														backgroundColor: COLORS.process,
														borderRadius: '12px',
														p: '4px 9px 4px 9px',
													}}
												>
													<Typography
														sx={{
															color: COLORS.health,
															fontWeight: 400,
															fontSize: '12px',
															lineHeight: '15px',
														}}
													>
														Введите данные
													</Typography>
												</Box>
											) : (
												<></>
											)}
											{testPlanData
												.map(el =>
													el.feed_facts.some(
														group => group.has_deviation === true
													)
												)
												.some(el => el === true) && isFutureTime !== true ? (
												<Box
													sx={{
														backgroundColor: COLORS.deviation,
														borderRadius: '12px',
														p: '4px 9px 4px 9px',
													}}
												>
													<Box
														sx={{
															color: COLORS.deviationFont,
															fontWeight: 400,
															fontSize: '12px',
															lineHeight: '15px',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															gap: '4px',
														}}
													>
														<DeviationLogo />
														{`Обнаружено отклонение`}
													</Box>
												</Box>
											) : (
												<></>
											)}
										</Box>
									)}
									{settingsArea()}
								</Box>
								<Collapse
									in={isPastTime ? !listOpen : listOpen}
									unmountOnExit
									easing={{
										enter: 'cubic-bezier(.42, 0, 1, 1)',
										exit: 'linear',
									}}
								>
									{isTodayTime ? (
										completedDataRation.map(el => (
											<TestPlanCardView
												{...el}
												isToday={isTodayTime}
												isPast={isPastTime}
												isFuture={isFutureTime}
												feedPlanDate={feedPlanDate}
												timeString={timeString}
												key={el.id}
											/>
										))
									) : (
										<></>
									)}
									{isPastTime ? (
										testPlanData.map(el => (
											<TestPlanCardView
												{...el}
												isToday={isTodayTime}
												isPast={isPastTime}
												isFuture={isFutureTime}
												feedPlanDate={feedPlanDate}
												timeString={timeString}
												key={el.id}
											/>
										))
									) : (
										<></>
									)}
									{isFutureTime ? <></> : <></>}
									<Divider
										sx={{
											mt: '4px',
											mr: '32px',
											border: `1px solid ${COLORS.main}`,
											borderRadius: '12px',
											mb: '8px',
										}}
									/>
								</Collapse>
							</>
						) : (
							<>
								<Box flexDirection='row' sx={{ height: '65px', pl: '10px' }}>
									{settingsArea()}
								</Box>
								{testPlanData.map(el => (
									<TestPlanCardView
										{...el}
										isToday={isTodayTime}
										isPast={isPastTime}
										timeString={timeString}
										feedPlanDate={feedPlanDate}
										isFuture={isFutureTime}
										key={el.id}
									/>
								))}
							</>
						)}
					</List>

					{isTodayTime ? (
						currentDataRation.map(el => (
							<TestPlanCardView
								{...el}
								isToday={isTodayTime}
								isPast={isPastTime}
								timeString={timeString}
								feedPlanDate={feedPlanDate}
								isFuture={isFutureTime}
								key={el.id}
							/>
						))
					) : (
						<></>
					)}
					{isPastTime ? <></> : <></>}

					{isFutureTime ? (
						testPlanData.map(el => (
							<TestPlanCardView
								{...el}
								isToday={isTodayTime}
								isPast={isPastTime}
								timeString={timeString}
								feedPlanDate={feedPlanDate}
								isFuture={isFutureTime}
								key={el.id}
							/>
						))
					) : (
						<></>
					)}
					{testPlanData
						.map(el =>
							el.feed_facts.some(group => group.has_fact_data_demand === true)
						)
						.some(el => el === true) &&
					isTodayTime === true &&
					listOpen === false &&
					currentDataRation.length === 0 ? (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								paddingTop: '15vh',
							}}
						>
							<QuestionLogo />
							<Typography
								sx={{
									fontSize: '14px',
									lineHeight: '120%',
									fontWeight: '400',
									color: COLORS.secondaryFont,
								}}
							>
								Введите недостающие данные
							</Typography>
						</Box>
					) : (
						<></>
					)}
					{testPlanData
						.map(el =>
							el.feed_facts.some(group => group.has_fact_data_demand === true)
						)
						.every(el => el === false) &&
					isTodayTime === true &&
					listOpen === false &&
					currentDataRation.length === 0 ? (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								paddingTop: '15vh',
							}}
						>
							<DoneLogo />
							<Typography
								sx={{
									fontSize: '14px',
									lineHeight: '120%',
									fontWeight: '400',
									color: COLORS.secondaryFont,
								}}
							>
								Все задачи на сегодня выполнены
							</Typography>
						</Box>
					) : (
						<></>
					)}
				</Grid>
			</Box>
		</ThemeProvider>
	)
}

export default ViewPlanPage
