import React, { useEffect } from 'react';

import { useParams, NavLink, useLocation } from 'react-router-dom';

import MontserratTtf from '../styles/fonts/Montserrat-Medium.ttf'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchBarnList } from '../store/barnSlice';
import { fetchGroupList } from '../store/groupsSlice'

import { drawerWidth } from '../variables';
import UserMenu from './UserMenu';
import { COLORS } from '../styles/colors';
import { TypographySecondary } from '../ui/TypographySecondary';

const theme = createTheme({
  palette: {
    background: {
        default: '#F7F7F7'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: '28px',
          fontWeight: 'bold'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          backgroundColor: '#fff',
          color: '#000',
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Montserrat'), local('Montserrat-Medium'), url(${MontserratTtf}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      `
  },
    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: '0.75rem',
          boxShadow: 'none'
        }
      }
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
},
})

const Header = () => {

  const dispatch = useAppDispatch()
  const { barnList } = useAppSelector(store => store.barnReducer)
  const { groupList } = useAppSelector(store => store.groupsReducer)

  useEffect(() => {
    dispatch(fetchBarnList())
    dispatch(fetchGroupList())
  }, [])

  let header_title = 'Кормовой стол'

  const urlParams = useParams()
  const location = useLocation()

  const urlFillPaths = [
    '/fill', '/fill/1/', '/fill/1', '/fill/1/ration', '/fill/1/ration/', '/fill/1/groups', '/fill/1/plan', '/fill/1/ration/create', '/fill/1/feedplan','/fill/1/feedplan/', '/fill/1/done/', '/fill/1/done'
]


  if (urlParams.groupkey) {
    header_title = ''
  }
  if (location.pathname === '/profile') {
    header_title = 'Профиль'
  }
  if (urlFillPaths.includes(location.pathname)) {
    header_title = ''
  }
  if (location.pathname === '/feed/groups') {
    header_title = 'Группы'
  }
  if (location.pathname === '/feed/ration') {
    header_title = 'Рацион'
  }
  if (location.pathname === '/feed/plan') {
    header_title = 'План кормления'
  }
  if (location.pathname === '/feed/analytics') {
    header_title = 'Аналитика'
  }
  if (location.pathname === '/feed/plan/create') {
    header_title = 'Создание плана кормления'
  }
  if (location.pathname === '/feed/plan/edit' || location.pathname === '/feed/plan/edit/') {
    header_title = 'Редактирование плана кормления'
  }
   if (location.pathname === '/feed/deviations') {
			header_title = 'Обнаруженные отклонения'
		}
  if (location.pathname === '/feed/plan/history' || location.pathname === '/feed/plan/history/') {
    header_title = 'История изменений'
  }
  if (location.pathname === `/feed/plan/history/${urlParams.groupkey}`) {
    header_title = 'История изменений'
  }
  if (location.pathname === '/settings') {
    header_title = 'Настройки'
  }
  if (location.pathname === '/settings/management') {
    header_title = 'Управление пользователями'
  }

  let today = new Date().toISOString().slice(0, 10).split('-').reverse().join('.')

  let hour = new Date().getHours()

  let minute = new Date().getMinutes()
  return (
		<ThemeProvider theme={theme}>
			<AppBar position='absolute' color='transparent' sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, height: '64px' }}>
				<Container maxWidth={false} sx={{ ml: '0px', mr: '0px', pr: '0px !important', width: '100%' }}>
					<Toolbar sx={{ backgroundColor: theme.palette.background.default }}>
						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, fontFamily: theme.typography.fontFamily }}>
							{location.pathname === `/feed/groups/${urlParams.groupkey}` ? (
								<NavLink to={`/feed/groups`}>
									<Box sx={{ ml: '-53px', mt: '3px' }}>
										<ArrowBackIosIcon sx={{ color: COLORS.icon, mt: '1.5px' }} />
									</Box>
								</NavLink>
							) : (
								''
							)}
							{location.pathname === `/feed/groups/${urlParams.groupkey}/edit` ? (
								<NavLink to={`/feed/groups/${urlParams.groupkey}`}>
									<Box sx={{ ml: '-53px', mt: '3px' }}>
										<ArrowBackIosIcon sx={{ color: COLORS.icon, mt: '1.5px' }} />
									</Box>
								</NavLink>
							) : (
								''
							)}
							{location.pathname === `/feed/deviations` ? (
								<NavLink to={`/feed/`}>
									<Box sx={{ ml: '-43px', mt: '3px' }}>
										<ArrowBackIosIcon sx={{ color: COLORS.icon, mt: '1.5px' }} />
									</Box>
								</NavLink>
							) : (
								''
							)}
              	{location.pathname === `/feed/plan/edit` ? (
								<NavLink to={`/feed/plan`}>
									<Box sx={{ ml: '-43px', mt: '3px' }}>
										<ArrowBackIosIcon sx={{ color: COLORS.icon, mt: '1.5px' }} />
									</Box>
								</NavLink>
							) : (
								''
							)}

							<Typography variant='h4' noWrap sx={{ ml: '-20px' }}>
								{groupList.map(group => (`/feed/groups/${group.key}` === location.pathname ? group.title : ''))}
								{/* {groupList.map(group => (`/feed/groups/${group.id.toString()}` === `/feed/groups/${group.groupkey}` ? group.title : ''))} */}
								{groupList.map(group => (`/feed/groups/${group.id.toString()}/edit` === location.pathname ? 'Редактирование группы' : ''))}
								{header_title === 'Создание плана кормления' ? (
									<NavLink to='/feed/plan'>
										<ArrowBackIosIcon sx={{ color: COLORS.icon }} />
									</NavLink>
								) : (
									''
								)}
								{header_title === 'История изменений' && location.pathname === `/feed/plan/history/${urlParams.groupkey}` ? (
									<NavLink to='/feed/plan'>
										<ArrowBackIosIcon sx={{ color: COLORS.icon }} />
									</NavLink>
								) : (
									''
								)}
								{header_title === 'История изменений' &&
								(location.pathname === `/feed/plan/history` || location.pathname === `/feed/plan/history/`) ? (
									<NavLink to='/feed/plan'>
										<ArrowBackIosIcon sx={{ color: COLORS.icon }} />
									</NavLink>
								) : (
									''
								)}
								{header_title === 'Управление пользователями' ? (
									<NavLink to='/settings'>
										<ArrowBackIosIcon sx={{ color: COLORS.black }} />
									</NavLink>
								) : (
									''
								)}

								{header_title}
								{header_title === 'Кормовой стол' ? (
									<Box>
										<TypographySecondary>
											Сегодня, {today} {hour < 10 ? `0${hour}` : hour}:{minute < 10 ? `0${minute}` : minute}
										</TypographySecondary>
									</Box>
								) : (
									''
								)}
							</Typography>
						</Box>

						<UserMenu />
					</Toolbar>
				</Container>
			</AppBar>
		</ThemeProvider>
	)
}

export default Header