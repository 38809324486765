import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Box, CssBaseline, ThemeProvider } from '@mui/material'

import { fontTheme } from '../styles/font'

import { ReactComponent as GridChangeLogo } from '../assets/grid_change_logo.svg'
import { ReactComponent as TableChangeLogo } from '../assets/table_change_logo.svg'
import { ReactComponent as PlusPopupIcon } from '../assets/plus_popup_icon.svg'

import RationList from '../components/ration/RationList';
import RationEditPopup from '../components/ration/RationEditPopup';

const theme = fontTheme

const RationPage = () => {

  const navigate = useNavigate()

  const [grid, setGrid] = useState(true)
  
  const [strokeColor, setStrokeColor] = useState('#5222D0')
  const [strokeTableColor, setStrokeTableColor] = useState('#7F7F84')

  const handleGrid = () => {
    setGrid(true)
    setStrokeColor('#5222D0')
    setStrokeTableColor('#7F7F84')
  }

  const handleTable = () => {
    setGrid(false)
    setStrokeColor('#7F7F84')
    setStrokeTableColor('#5222D0')
  }

  return <ThemeProvider theme={theme}>
    <CssBaseline />
    <Box sx={{ fontFamily: theme.typography.fontFamily, pl: '16px', pr: '40px' }}>
      <Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems='flex-end'>
        <Box display='flex' flexDirection='row'>
          <Box><PlusPopupIcon onClick={()=>{navigate('/feed/ration/create')}} /></Box>
          <Box sx={{ pl: '24px' }}><GridChangeLogo onClick={handleGrid} stroke={strokeColor} /></Box>
          <Box sx={{ pl: '24px' }}><TableChangeLogo onClick={handleTable} stroke={strokeTableColor} /></Box> 
        </Box>
      </Box>
      <RationList grid={grid} />
      <RationEditPopup />
    </Box>
  </ThemeProvider>
}

export { RationPage }