import React from 'react'

import Drawer from '@mui/material/Drawer'
import { drawerWidth } from '../variables'

import { ReactComponent as SmartFarmLogo } from '../assets/smart_farm_logo.svg'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import SidebarMainMenu from './SidebarMainMenu'
import { Box } from '@mui/material'
import SidebarSettings from './SidebarSettings'
import { NavLink, useLocation } from 'react-router-dom'
import { COLORS } from '../styles/colors'

const theme = createTheme({
	components: {
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: '#5222D0',
					padding: '2rem 1rem',
					borderRadius: '0px 12px 12px 0px',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				h4: {
					color: '#fff',
					fontWeight: 'bold',
				},
			},
		},
	},
})

const urlFillPaths = [
	'/fill',
	'/fill/1/',
	'/fill/1',
	'/fill/1/ration',
	'/fill/1/ration/',
	'/fill/1/groups',
	'/fill/1/plan',
	'/fill/1/ration/create',
	'/fill/1/feedplan',
	'/fill/1/feedplan/',
	'/fill/1/done/',
	'/fill/1/done',
]

const Sidebar = ({ setOpenNotifications, countNotif }) => {
	const location = useLocation()

	return (
		<ThemeProvider theme={theme}>
			{urlFillPaths.includes(location.pathname) ? (
				<Box sx={{ pt: '24px', pl: '40px' }}>
					<SmartFarmLogo fill={COLORS.main} />
				</Box>
			) : (
				<Drawer
					sx={{
						width: drawerWidth,

						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: drawerWidth,
							boxSizing: 'border-box',
							display: 'flex',
							justifyContent: 'space-between',
						},
					}}
					variant='permanent'
					anchor='left'
				>
					<Box display='flex' flexDirection='column'>
						<NavLink to='/feed'>
							<SmartFarmLogo fill='white' />
						</NavLink>
						<SidebarMainMenu />
					</Box>

					<Box sx={{ position: 'sticky', pb: '24px' }}>
						<SidebarSettings countNotif={countNotif} setOpenNotifications={setOpenNotifications} />
					</Box>
				</Drawer>
			)}
		</ThemeProvider>
	)
}

export default Sidebar
