import React, { useEffect, useState } from 'react'
import { Box, Button, ClickAwayListener, CssBaseline, Grid, IconButton, ThemeProvider, Tooltip, Typography } from '@mui/material';

import GroupItem from '../components/group/GroupItem';
import GroupHeadEditPopup from '../components/group/GroupHeadEditPopup';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchBarnList } from '../store/barnSlice';
import { fetchGroupList } from '../store/groupsSlice'

import { IGroupEntity } from '../models/IGroup';

import {ReactComponent as SettingLogo} from '../assets/setting_logo.svg'
import { ReactComponent as EditFeedLogo } from '../assets/edit_feed_logo.svg'

import { fontTheme } from '../styles/font';
import { COLORS } from '../styles/colors';
import { ChipItem } from '../ui/ChipItem';
import { TypographyPrimary } from '../ui/TypographyPrimary';
import axAPI from '../http';


const theme = fontTheme

const GroupTooltip = ( ) => {
  const [color, setColor] = React.useState(COLORS.icon)
  return <Box >
      <Box display='flex' flexDirection='row' sx={{pt: '6px' }} alignItems='center' onMouseOver={() => setColor(COLORS.main)} onMouseLeave={() => setColor(COLORS.secondaryFont)}>
        <EditFeedLogo fill={color} onMouseOver={() => setColor(COLORS.main)} onMouseLeave={() => setColor(COLORS.secondaryFont)} />
        <TypographyPrimary sx={{ color: color, pl: '10px', fontWeight: 400, fontSize: '16px', lineHeight: '17px', '&:hover': {color: COLORS.main}, textAlign: 'left', width: '170px' }}>Редактировать количество голов</TypographyPrimary>
      </Box>
  </Box>
}


const GroupsPage = () => {
  const [open, setOpen] = React.useState(false);
  const [next, setNext] = React.useState(false);

  const handleClose = () => {
    setOpen(false)
    setNext(false)
  };


  const handleCloseTooltip = () => {
    setOpen(false)
    setNext(true)
  }


  const [currentBarn, setCurrentBarn] = useState('-1')

  const dispatch = useAppDispatch()
  const { barnList } = useAppSelector(store => store.barnReducer)
  const { groupList } = useAppSelector(store => store.groupsReducer)

  useEffect(() => {
    dispatch(fetchBarnList())
    dispatch(fetchGroupList())
  }, [])

  const chipItemStyle = { marginTop: '12px', marginRight: '5px', marginLeft: '3px', marginBottom: '6px',  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{pr: '24px', pl: '12px'}} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
       <Box>
         {barnList.map(barn => {
        return <ChipItem
          label={barn.title}
          onClick={e => setCurrentBarn(barn.id.toString())}
          key={'barn'+barn.id.toString()}
          sx={
            barn.id.toString() === currentBarn
            ? { ...chipItemStyle, backgroundColor: '#5222D0', color: '#fff' }
            : chipItemStyle
          }
        />
      })}
        </Box> 
        <Box sx={{ pl: '10px', pt: '6px' }}>
            <ClickAwayListener onClickAway={ () => setOpen(false) }>
              <Box>
                <Tooltip
                  placement="bottom-start"
                  componentsProps={{ 
                    tooltip: { 
                      sx: { 
                        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)', backgroundColor: 'white', 
                        borderRadius: '8px', width: '270px', height: '65px' }}}}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={ () => setOpen(false) }
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={<Button 
                    onClick={handleCloseTooltip} 
                    sx={{
                      textTransform: 'none',
                      textAlign: 'left',
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}><GroupTooltip /></Button>}
                >
                  <IconButton onClick={() => setOpen(!open)}>
                    <SettingLogo stroke={COLORS.secondaryFont}  />
                  </IconButton>
                </Tooltip>
              </Box>
            </ClickAwayListener>
          </Box>
          <GroupHeadEditPopup open={next} handleClose={handleClose} /> 
      </Box>
      <Grid container spacing={3} columns={{ xs: 4, sm: 10, md: 12, lg: 12 }} sx={{pr: '32px', pl: '16px', pb: '16px'}}>
        {
          groupList.filter((group: IGroupEntity) => {
            return currentBarn === '-1' || group.barn === Number(currentBarn) 
          }).map((group: IGroupEntity) => {
            return <Grid item xs={6} sm={5} md={6} lg={6} key={group.groupkey} sx={{height: '390px'}}>
              <GroupItem { ...group } key={'group'+group.groupkey} />
            </Grid>
          })
        }
      </Grid>
    </ThemeProvider>
  )
}

export { GroupsPage }