import { Box } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import axAPI from '../../http'

const GroupEatenLegend = (props: { id; startDate; endDate }) => {
	const [eatenLegendData, setEatenLegendData] = useState([])
	useEffect(() => {
		let isMounted = true
		axAPI
			.get(
				`cattle-group/${props.id}/foodtable/events?start=${props.startDate}&end=${props.endDate}`
			)
			.then(response => {
				setEatenLegendData(response.data)
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				return () => { isMounted = false }
			});
	}, [props.id, props.startDate, props.endDate])

	if (!eatenLegendData) return <></>

	return (
		<Box>
			<Box>
				{eatenLegendData.map(data => (
					<div
						key={data.id}
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '24px',
							width: 'auto',
						}}
					>
						<p
							style={{
								fontFamily: 'Montserrat',
								fontWeight: '600',
								fontSize: '16px',
							}}
						>
							{moment(data.created_dt).format('HH:mm')}
						</p>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<p
								style={{
									fontFamily: 'Montserrat',
									fontWeight: '400',
									fontSize: '14px',
									marginBottom: '8px',
								}}
							>
								{data.title}
							</p>
							{/* <p style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: '12px', color: 'rgba(127, 127, 132, 1)' }}>1ч 40мин</p> */}
						</div>
					</div>
				))}

				{/* <div style={{ minWidth: 'auto', height: '1px', background: 'rgba(219, 219, 219, 1)', marginTop: '16px', marginBottom: '24px' }}></div> */}
			</Box>
			{/* <Box style={{ display: 'flex', flexDirection: 'column' }}>
					<p style={{ fontSize: '14px', fontWeight: '400', marginBottom: '8px' }}>Время кормления составило: 7ч 50мин</p>
					<p style={{ fontSize: '14px', fontWeight: '400', marginBottom: '8px' }}>Корм был недоступен: 3ч 20мин</p>
					<p style={{ fontSize: '14px', fontWeight: '400', marginBottom: '8px' }}>Всего корма роздано: 4000кг</p>
					<p style={{ fontSize: '14px', fontWeight: '400' }}>Время корма съедено: 18.2 м3</p>
				</Box> */}
		</Box>
	)
}

export default GroupEatenLegend
