import { Box } from '@mui/material'
import React from 'react'
import CreatePlanPage from '../../layouts/CreatePlanPage'
import { TypographyHeaderExtra } from '../../ui/TypographyHeaderExtra'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import FarmFillBack from './FarmFillBack'
import FarmFillStepIndex from './FarmFillStepIndex'

const FarmFillFeedPlan = () => {
  return (
    <Box sx={{pr: '150px'}}>
        <FarmFillBack prevStep='/groups' />
        <FarmFillStepIndex stepNum='3' fullfied='3' />

        <Box sx={{pt: '60px', pb: '40px'}}>
        <TypographyHeaderExtra>
            План кормления
        </TypographyHeaderExtra>
        <TypographyPrimary sx={{pt: '24px'}}>
          Создайте план кормления для каждой группы.
        </TypographyPrimary>
        </Box>
        <CreatePlanPage isFirstTry />
    </Box>
  )
}

export default FarmFillFeedPlan