import { Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'

import { ReactComponent as GroupCowLogo } from '../../assets/group_cow_logo.svg'
import { ReactComponent as FoodIcon } from '../../assets/food_icon.svg'
import { ReactComponent as FarmTable } from '../../assets/farm_table.svg'
import { COLORS } from '../../styles/colors'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { TypographySecondary } from '../../ui/TypographySecondary'
import LinearProgressBar from './utils/LinearProgressBar'
import moment from 'moment'

type GroupInfoProps = {
	reachable?: number
	unreachable?: number
	barntitle: string
	rationtitle: string
	cattles_qty: number
	description?: string
	weight_per_head: number
	lastDistribution: number
	lastNudge: number
}

const GroupSpecificData = ({
	cattles_qty,
	barntitle,
	description,
	rationtitle,
	weight_per_head,
	reachable,
	lastDistribution,
	lastNudge
}: GroupInfoProps) => {
	return (
		<Stack direction='column'>
			<Stack
				direction='row'
				alignItems='center'
				sx={{ paddingLeft: '24px', paddingTop: '16px' }}
			>
				<GroupCowLogo />{' '}
				<TypographyPrimary sx={{ pl: '4px' }}>
					Голов в группе: {cattles_qty}
				</TypographyPrimary>
			</Stack>
			<Stack sx={{ paddingLeft: '24px', paddingTop: '16px' }}>
				<TypographyPrimary>{barntitle}</TypographyPrimary>
			</Stack>
			<Stack sx={{ paddingLeft: '24px', paddingTop: '16px' }}>
				<TypographyPrimary>
					Описание: {description ? description : 'Очень хорошие коровы'}
				</TypographyPrimary>
			</Stack>
			<Divider
				sx={{ ml: '24px', mr: '24px', pt: '16px', color: COLORS.secondary }}
			/>
			<Stack
				sx={{
					display: ' flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					paddingLeft: '24px',
					paddingTop: '16px',
					paddingRight:'24px'
				}}
			>
				<Box sx={{display:'flex', flexDirection:'row'}}>
					<FoodIcon />
					<TypographyPrimary sx={{ pl: '4px' }}>
						Рацион: {rationtitle}
					</TypographyPrimary>
				</Box>
				<Box
					sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
				>
					<TypographyPrimary>Норма ПСВ: {weight_per_head}</TypographyPrimary>
					<TypographySecondary sx={{ pl: '4px' }}>
						кг/голову
					</TypographySecondary>
				</Box>
			</Stack>
			<Stack
				direction='row'
				alignItems='center'
				sx={{ paddingLeft: '24px', paddingTop: '16px' }}
			>
				<FarmTable />{' '}
				<TypographyPrimary sx={{ pl: '4px' }}>
					{`Кормовой стол: ${reachable}% корма доступно`}
				</TypographyPrimary>
			</Stack>
			{/* <Divider
				variant='middle'
				sx={{
					borderBottomWidth: 5,
					borderColor: COLORS.success,
					paddingLeft: '24px',
					paddingRight: '26px',
					paddingTop: '7px',
					marginLeft: '24px',
					mb: '12px',
				}}
			/> */}
			<LinearProgressBar reachable={reachable} />
			<Stack sx={{ paddingLeft: '24px', paddingTop: '8px' }}>
				<TypographySecondary>{`Последняя раздача: ${moment(lastDistribution).format('HH:mm, DD.MM.YYYY')}`}</TypographySecondary>
				<TypographySecondary>{`Последнее подталкивание: ${moment(lastNudge).format('HH:mm, DD.MM.YYYY')}`}</TypographySecondary>
			</Stack>
		</Stack>
	)
}

export default GroupSpecificData
