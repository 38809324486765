import { SxProps } from '@mui/material';
import React from 'react'
import { ChipItem } from '../../ui/ChipItem';

import { ReactComponent  as RationHoverCrossLogo } from '../../assets/ration_hover_cross_logo.svg'
import { ReactComponent  as RationCrossLogo } from '../../assets/ration_cross_logo.svg'

type RationChipItemProps = {
  label: string;
  onDelete: () => void;
  sx: SxProps;
}

const RationChipItem = (props: RationChipItemProps) => {
  const [hoverHighlight, setHoverHighlight] = React.useState(false)

  return (
    <ChipItem 
      label={props.label}
      onDelete={props.onDelete}
      sx={props.sx}
      deleteIcon={hoverHighlight ? <RationHoverCrossLogo /> : <RationCrossLogo />}
      onMouseOver={() => setHoverHighlight(true)}
      onMouseLeave={() => setHoverHighlight(false)}
    />
  )
}

export default RationChipItem