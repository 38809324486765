import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom'

import { Navigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { ReactComponent as BlindPassLogo } from '../assets/blind_pass_logo.svg'
import { ReactComponent as UnBlindPassLogo } from '../assets/unblind_pass_logo.svg'

import useAuth from '../hook/useAuth';
import { Grid, IconButton, InputAdornment, styled } from "@mui/material";
import { ButtonUI } from "../ui/ButtonUI";
import { AlertText } from "../ui/AlertText";
import { useAppSelector } from "../store/hooks";
import { fontTheme } from "../styles/font";
import { ThemeProvider } from "@mui/system";
import { OutlinedButton } from "../ui/OutlinedButton";
import { COLORS } from "../styles/colors";
import { useState } from "react";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

interface IFormInput {
    email: String
    password: String
}

const theme = fontTheme

const LoginPage = () => {

    const authState = useAuth();
    const navigate = useNavigate();

    const { authRejected } = useAppSelector(state => state.authReducer)

    const { register, handleSubmit, watch } = useForm<IFormInput>();

    const onSubmit: SubmitHandler<IFormInput> = data => {
        authState.signInAndContinue(data.email, data.password)
    }

    const watchEmail = watch('email')
    const watchPass = watch('password')

    const [showPass, setShowPass] = useState(false)
    const handleClickShowPass = () => setShowPass(!showPass)

    const [forgetPassword, setForgetPassword] = useState(false)


    if (authState.token) {
        return <Navigate to='/feed' replace />
    }

    return (
			<ThemeProvider theme={theme}>
				<Container component='main' sx={{ fontFamily: theme.typography.fontFamily, margin: 'auto' }}>
					<CssBaseline />
					<Box
						sx={{
							marginTop: '10%',
							display: 'flex',
							ml: 'auto',
							mr: 'auto',
							padding: '32px 24px',
							flexDirection: 'column',
							alignItems: 'flex-start',
							backgroundColor: 'white',
							width: 'min-content',
                            minWidth:'448px',
							height: 'min-content',
                            minHeight:'432px',
							borderRadius: 2,
							boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
						}}
					>
						<Box sx={{ display: ' flex', flexDirection: 'row', marginBottom: '44px', alignItems: 'center' }}>
							{forgetPassword ? (
								<ArrowBackIosNewOutlinedIcon
									onClick={() => setForgetPassword(false)}
									sx={{ cursor: 'pointer', marginRight: '18px', color: '#7F7F84' }}
								/>
							) : (
								<></>
							)}

							<Typography
								sx={{
									fontWeight: 700,
									fontSize: 28,
								}}
								component='h1'
							>
								{forgetPassword ? 'Забыли пароль' : 'Вход'}
							</Typography>
						</Box>

						<Box>
							{forgetPassword ? (
								<Typography sx={{fontSize:'14px', fontWeight:'400', }}>Для смены пароля обратитесь к администратору.</Typography>
							) : (
								<Box component='form' onSubmit={handleSubmit(onSubmit)}>
									<TextField
										sx={{
											mb: '20px',
											borderRadius: '12px',
											width: '100%',
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: COLORS.main,
												},
											},
											'& label.Mui-focused': {
												color: COLORS.main,
											},
										}}
										id='email'
										label='E-mail или имя пользователя'
										autoFocus
										autoComplete='email'
										{...register('email')}
										InputProps={{
											style: { fontSize: '14px', borderRadius: '8px', margin: '0px', padding: '0px' },
										}}
										InputLabelProps={{
											style: { fontSize: '14px' },
										}}
									/>
									<TextField
										sx={{
											mb: 5,
											borderRadius: '12px',
											width: '100%',
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: COLORS.main,
												},
											},
											'& label.Mui-focused': {
												color: COLORS.main,
											},
										}}
										margin='normal'
										id='password'
										type={showPass ? 'text' : 'password'}
										label='Пароль'
										autoComplete='password'
										{...register('password')}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton aria-label='toggle password visibility' onMouseDown={handleClickShowPass}>
														{showPass ? <UnBlindPassLogo /> : <BlindPassLogo />}
													</IconButton>
												</InputAdornment>
											),
											style: { fontSize: '14px', borderRadius: '8px', margin: '0px', padding: '0px' },
										}}
										InputLabelProps={{
											style: { fontSize: '14px' },
										}}
									/>
									{authRejected ? (
										<Box
											sx={{
												backgroundColor: 'rgba(235, 77, 61, 0.2)',
												height: 'min-content',
												width: '27.7vw',
												borderRadius: '12px',
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											<AlertText>Вы ввели неверный e-mail/имя пользователя или пароль</AlertText>
										</Box>
									) : (
										''
									)}

									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<OutlinedButton
											type='submit'
											sx={{ textTransform: 'none', marginBottom: '16px' }}
											disabled={watchEmail && watchPass ? false : true}
										>
											Войти
										</OutlinedButton>
										<Typography
											onClick={() => setForgetPassword(true)}
											sx={{
												cursor: 'pointer',
												display: 'flex',
												justifyContent: 'center',
												mt: 1,
												color: 'rgb(82, 34, 208)',
												fontWeight: 400,
												fontSize: 14,
											}}
										>
											Забыли пароль?
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
				</Container>
			</ThemeProvider>
		)
}

export default LoginPage