import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { OutlinedButton } from '../../ui/OutlinedButton';
import { TypographyHeader } from '../../ui/TypographyHeader';
import GroupHeadEditPopup from '../group/GroupHeadEditPopup';

import CheckApiService from '../../services/checkApiService';
import axAPI from '../../http';
import { EventsEnum } from '../../models';

const GroupHeadPopup = () => {
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(false);


  useEffect(() => {
		// CheckApiService.getCheckCattleQuantity()
		// .then(resonse => {
		//   if (resonse.status !== 200) setOpen(true)
		// })
		axAPI({
		  method: 'GET',
		  url: `/check/${EventsEnum.CATTLE_QUANTITY}`,
		  validateStatus: status => (status === 404 || status === 200)
		}).then(resonse => {
		  if (resonse.status !== 200) setOpen(true)
		})
	}, [])


  const noChangesNeeded = () => {
		// CheckApiService.postCheckCattleQuantity()
		// .then(resonse => {
		//   if (resonse.status === 201) setOpen(false)
		// })
		axAPI({
		  method: 'POST',
		  url: `/check/${EventsEnum.CATTLE_QUANTITY}`,
		}).then(resonse => {
		  if (resonse.status === 201) setOpen(false)
		})
	}

  return (
      <Dialog
        scroll='body'
        open={open}
        onClose={ noChangesNeeded }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {borderRadius: '12px'}
        }}
      >
        <DialogContent sx={{textAlign: 'center'}}>
            <TypographyHeader>Изменилось количество голов в группе?</TypographyHeader>
        </DialogContent>
        <DialogActions sx={{mb: '28px'}}>
          <OutlinedButton sx={{mt: '0px', ml: '20px', mr: '10px'}} onClick={noChangesNeeded}>Нет</OutlinedButton>
          <OutlinedButton sx={{mt:' 0px', mr: '20px', ml: '10px'}} onClick={ () => setNext(true) }>Да</OutlinedButton>
        </DialogActions>
        <GroupHeadEditPopup open={next} handleClose={ () => setOpen(false) } /> 
      </Dialog>
  );
}

export default GroupHeadPopup;
