import { COLORS } from '../../../styles/colors'
export const getDaysArr = (start: Date, end: Date) => {
	let arr: Date[] = []
	for (let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
		arr.push(new Date(dt))
	}
	return arr
}
export const firstDayHandler = (startDate?, endDate?) => {
	const nowDate = new Date(Date.now())
	const nowMonth = nowDate.getMonth()
	const currentYear = nowDate.getFullYear()
	const currentMonth = nowMonth + 1
	const prevMonth = nowMonth === 0 ? 12 : nowMonth + 1
	const currentDay = nowDate.getDate()
	const currentDate = `${currentYear}-${currentMonth < 10 ? '0' + currentMonth : currentMonth}-${currentDay < 10 ? '0' + currentDay : currentDay}`
	const prevDate = `${currentYear}-${prevMonth < 10 ? '0' + prevMonth : prevMonth}-${currentDay < 10 ? '0' + currentDay : currentDay}`
	return [prevDate, currentDate]
}
export const dayListHandler = (startDate?, endDate?) => {
	const nowDate = new Date(Date.now())
	const nowMonth = nowDate.getMonth()
	const currentYearNumber = nowDate.getFullYear()
	const currentMonthNumber = nowMonth + 1
	const currentDayNumber = nowDate.getDate()
	const prevMonthNumber = nowMonth === 0 ? 12 : nowMonth + 1
	const prevYearStr = prevMonthNumber > currentMonthNumber ? currentYearNumber - 1 : currentYearNumber
	const prevMonthStr = prevMonthNumber < 10 ? '0' + prevMonthNumber : prevMonthNumber
	const currentMonthStr = currentMonthNumber < 10 ? '0' + currentMonthNumber : currentMonthNumber
	const currentDayStr = currentDayNumber < 10 ? '0' + currentDayNumber : currentDayNumber
	const prevDate = `${prevYearStr}-${prevMonthStr}-${currentDayStr}`
	const currentDate = `${currentYearNumber}-${currentMonthStr}-${currentDayStr}`
	const result = getDaysArr(new Date(startDate ? startDate : prevDate), new Date(endDate ? endDate : currentDate))
	return result
}
export const getOptionsHeadChart = data => {
	return {
		maintainAspectRatio: false,
		plugins: {
			tooltip: {
				yAlign: 'bottom' as const,
				xAlign: 'center' as const,
				displayColors: false as const,
				backgroundColor: '#FFFFFF' as const,
				bodyColor: '#000000' as const,
				titleColor: '#7f7f84' as const,
				cornerRadius: 12,
				borderColor: 'rgba(219, 219, 219, 1)' as const,
				titleFont: {
					size: 12,
					weight: 'normal' as const,
				},
			},
			legend: {
				onClick: function (e) {
					e.stopPropagation()
				},
				position: 'bottom' as const,
				align: 'start' as const,
				labels: {
					usePointStyle: true,
					pointStyle: 'circle' as const,
					font: {
						family: 'Montserrat' as const,
					},
				},
			},
			datalabels: {
				display: false,
			},
		},
		interaction: {
			intersect: false,
			mode: 'index' as const,
		},
		scales: {
			y: {
				ticks: {
					font: {
						family: 'Montserrat' as const,
					},
				},
				max: 120,
				min: 0,
			},
			x: {
				ticks: {
					font: {
						family: 'Montserrat' as const,
					},
					callback: function (value, index, ticks) {
						return data.labels[index].slice(0, 2)
					},
				},
				title: {
					display: true,
					text: 'Дни',
					color: '#7F7F84' as const,
					font: {
						family: 'Montserrat' as const,
					},
				},
			},
		},
	}
}
export const getDataHeadChart = (labels, data) => {
	return {
		labels: labels,
		datasets: [
			{
				label: 'Кол-во голов',
				data: data,
				fill: false,
				backgroundColor: 'white',
				borderColor: COLORS.main,
				borderWidth: 1,
				pointRadius: 7,
				hoverRadius: 9,
				pointHoverBackgroundColor: COLORS.main,
			},
		],
	}
}
