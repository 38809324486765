import { useEffect, useState } from 'react'

const InputFormField = props => {
	const [value, setValue] = useState('')
	useEffect(() => {
		if (props.value !== undefined && props.value !== null) {
			setValue(props.value.toString())
		}
	}, [props.value])

	const handleInputChange = event => {
		const inputValue = event.target.value.replace(/\D/g, '')
		setValue(inputValue)
	}

	return (
		<div style={{ position: 'relative' }}>
			<input
				type='text'
				placeholder='минуты'
				value={value}
				onChange={handleInputChange}
				style={{
					width: '120px',
					height: '50px',
					border: '1px solid',
					borderColor: '#DBDBDB',
					paddingLeft: '5px',
					paddingRight: value ? '20px' : '5px',
					borderRadius: '12px',
					transition: 'border-color 0.1s ease-in-out',
					textAlign: 'center',
				}}
				onFocus={e => (e.target.style.borderColor = 'black')}
				onBlur={e => {
					const newValue = e.currentTarget.value
					e.target.style.borderColor = '#DBDBDB'
					if (props.type === 'distr') {
						props.setDeviationArray(prevData => ({
							...prevData,
							deviationDistribution: Number(newValue)
						}))
						
					}
					if (props.type === 'cleaning') {
						props.setDeviationArray(prevData => ({
							...prevData,
							deviationCleaning: Number(newValue)
						}))
						
					}
					if (props.type === 'nudge') {
						props.setDeviationArray(prevData => ({
							...prevData,
							deviationNudge: Number(newValue)
						}))
						
					}

					setValue(newValue)
				}}
			/>
			{value && (
				<span
					style={{
						position: 'absolute',
						top: '50%',
						right: '20px',
						transform: 'translateY(-50%)',
						pointerEvents: 'none',
						color: 'gray',
						fontSize: '0.8rem',
					}}
				>
					мин
				</span>
			)}
		</div>
	)
}

export default InputFormField
