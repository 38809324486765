import { Box, IconButton } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import {ReactComponent as FillBackArrow} from '../../assets/fill_back_arrow.svg'
import {ReactComponent as DoneLogo} from '../../assets/done_logo.svg'

import { CardStandart } from '../../ui/CardStandart'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { ButtonUI } from '../../ui/ButtonUI'
import { OutlinedButton } from '../../ui/OutlinedButton'

const FarmFillDonePage = () => {
  const navigate = useNavigate()

  return (
    <CardStandart sx={{mr: '150px', width: '50%', margin: '5% 0 0 20%', '&:hover': {boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',} }}>
        <IconButton onClick={() => navigate('/fill/1/feedplan')} sx={{m: '8px'}} ><FillBackArrow /></IconButton>
        <Box display='flex' justifyContent='center'>
            <DoneLogo />
        </Box>
        <TypographyHeader sx={{display: 'flex', justifyContent: 'center', pb: '16px'}}>Поздравляем!</TypographyHeader>
        <Box sx={{textAlign: 'center'}}>
            <TypographyPrimary sx={{whiteSpace: 'pre-line',}}>
                Вы заполнили данные по рационам, группам и плану кормления. {'\n'} Теперь Вам доступны все функции системы “SMART FARM КОРМОВОЙ СТОЛ”.
            </TypographyPrimary>
            <TypographyPrimary sx={{pt: '24px'}}>
                Хорошей работы!
            </TypographyPrimary>
            <OutlinedButton sx={{mb: '32px'}} onClick={() => navigate('/feed')}>
               Начать работу 
            </OutlinedButton>
        </Box>
    </CardStandart>
  )
}

export default FarmFillDonePage