import {
	Box,
	Card,
	CardContent,
	Grid,
	ThemeProvider,
	Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import axAPI from '../../http'

import { IGroupEntity } from '../../models/IGroup'
import { fontTheme } from '../../styles/font'
import AnalyticsRemainderChart from '../analytics/AnalyticsRemainderChart'
import AnalyticsSpendingChart from '../analytics/AnalyticsSpendingChart'
import { yesterdayDate } from '../feed/GetPoolCards'
import GroupSpecificConsuptionChart from './GroupSpecificConsuptionChart'
import GroupSpecificDeviationContent from './GroupSpecificDeviationContent'
import GroupSpecificDeviationList from './GroupSpecificDeviationList'
import GroupSpecificFeedPlan from './GroupSpecificFeedPlan'
import GroupSpecificHeadChart from './GroupSpecificHeadChart'
import GroupSpecificIndicators from './GroupSpecificIndicators'
import GroupSpecificInfo from './GroupSpecificInfo'
import GroupSpecificList from './GroupSpecificList'
import GroupSpecificListOpen from './GroupSpecificListOpen'
import { firstDayHandler } from './utils'

const GroupSpecific = (props: IGroupEntity) => {
	const primaryDateState = firstDayHandler()
	const [dateRange, setDateRange] = useState({
		startDate: Date.parse(primaryDateState[0]),
		endDate: Date.parse(primaryDateState[1]),
	})
	const [deviationsList, setDeviationList] = useState([])
	const countDev = deviationsList.length

	useEffect(() => {
		axAPI
			.get(
				`captured-deviations?start=${dateRange.startDate}&end=${
					dateRange.endDate + 86400000
				}&group=${props.id}`
			)
			.then(response => {
				response.data.length !== 0
					? setDeviationList(response.data)
					: setDeviationList([])
			})
	}, [dateRange])


	const theme = fontTheme
	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ fontFamily: theme.typography.fontFamily }}>
				<Grid
					container
					spacing={3}
					columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
					display='flex'
					flexDirection='row'
					justifyContent='space-between'
				>
					<Grid item xs={6} sm={6} md={8} lg={8}>
						<GroupSpecificInfo
							cattles_qty={props.cattles_qty}
							barntitle={props.barntitle}
							description={props.description}
							rationtitle={props.rationtitle}
							weight_per_head={props.weight_per_head}
							reachable={props.foodtable.reachable}
							unreachable={props.foodtable.unreachable}
							lastDistribution={props.last_distribution_dt}
							lastNudge={props.last_nudge_dt}
						/>
					</Grid>

					<Grid item xs={6} sm={6} md={4} lg={4}>
						<Card
							sx={{
								maxwidth: '520px',
								minHeight: '310px',
								maxHeight: '310px',
								boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
								backgroundColor: 'white',
								borderRadius: '12px',
							}}
						>
							<CardContent>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'baseline',
										justifyContent: 'space-between',
									}}
								>
									<Typography
										sx={{
											fontSize: 20,
											fontWeight: 700,
										}}
									>
										Показатели
									</Typography>
									<Typography
										sx={{ fontSize: 14, fontWeight: 400, color: '#7F7F84' }}
									>
										Вчера, {yesterdayDate}{' '}
									</Typography>
								</Box>
								<Grid
									container
									justifyContent='space-between'
									spacing={2}
									sx={{ pt: '16px' }}
								>
									<Grid item xs={6}>
										<GroupSpecificIndicators
											bulk='Среднее ПСВ'
											changeValue={1}
											count={props.food_dry_per_head}
											ratio='кг/голову'
										/>
									</Grid>
									<Grid item xs={6}>
										<GroupSpecificIndicators
											bulk='Роздано корма'
											changeValue={1}
											count={props.previous_distribution_weight}
											ratio='кг'
										/>
									</Grid>
									<Grid item xs={6}>
										<GroupSpecificIndicators
											bulk='КС пустой в ср'
											changeValue={1}
											count={123}
											ratio='мин/гр'
										/>
									</Grid>
									<Grid item xs={6}>
										<GroupSpecificIndicators
											bulk='Остаток корма'
											changeValue={1}
											count={props.previous_cleaning_weight}
											ratio='кг'
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Box sx={{ pt: '24px' }}>
					<GroupSpecificListOpen id={props.id} title='Поедаемость корма' />
				</Box>
				<Box sx={{ pt: '24px' }}>
					<GroupSpecificDeviationList
						id={props.id}
						countDev={countDev}
					/>
				</Box>
				<Box sx={{ pt: '24px' }}>
					<GroupSpecificFeedPlan id={props.id} title={props.title} />
				</Box>
				{/* <Box sx={{ pt: '24px' }}>
					<GroupSpecificHeadChart groupkey={props.groupkey} />
				</Box>
				<Box sx={{ pt: '24px' }}>
					<GroupSpecificConsuptionChart groupkey={props.groupkey} />
				</Box> */}

				{/* <Box sx={{ pt: '24px', pb: '24px' }}>
					<GroupSpecificList
						title='Расход корма и остатки'
						content={
							<Box sx={{ pt: '12px', pb: '60px' }}>
								{' '}
								<AnalyticsSpendingChart /> <AnalyticsRemainderChart />{' '}
							</Box>
						}
					/>
				</Box> */}
			</Box>
		</ThemeProvider>
	)
}

export default GroupSpecific
