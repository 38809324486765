import { Stack, Typography, Box } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import React from 'react'
import { IGroupEntity } from '../../models/IGroup';

const GroupBarn = (props: IGroupEntity) => {
    return <Stack direction='row' spacing={2} justifyContent='space-between'>
    <Stack direction='row' spacing={0} alignItems='baseline'>
      <Typography sx={{fontWeight: 700, fontSize: '20px', lineHeight: '24px', paddingLeft: '24px', paddingTop: '24px'}}>{props.title}</Typography>
    <Typography sx={{fontWeight: 400, color: "#7F7F84",  fontSize: '14px', lineHeight: '17px', paddingTop: '24px', paddingLeft: '8px',}}>{props.barntitle}</Typography>
    </Stack>
    <Box sx={{paddingTop: '24px', paddingRight: '16px'}}>
      <ArrowForwardIosIcon sx={{ color: "#7F7F84"}}/>
    </Box>
  </Stack>
  }

export default GroupBarn