import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axAPI from '../http'

import { IFeedPlanState, FeedPlanDataType, FeedPlanFactDataType, IDataOnChangeHandlerParameters } from '../models'


export const fetchFeedPlanData = createAsyncThunk(
    'feedplan/data',
    async function(params: { date?: Date }, {rejectWithValue}) {
        try {
            const response = await axAPI({
                method: 'GET',
                url: !params.date ? 'feedplan/' : `feedplan/${params.date.toISOString().split('T')[0]}`,
            })
            
            if (response.status!==200) {
                throw new Error('HTTP request error!')
            }
            return response.data
        } catch(error) {
            let errorMessage = 'Failed to retrive feed plan!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

export const feedPlanFactDataOnChange = createAsyncThunk(
    'feedplan/create_update_fact',
    async function(data: IDataOnChangeHandlerParameters, {rejectWithValue}) {      
        
        const requestData = {
          weight_input: data.weight
        }
        
        try {
            const url = `feedplans/${data.feedPlanDate}/${data.feedPlanId}/groups/${data.feedGroupId}`
            const response = await axAPI({
                method: "PATCH",
                url: url,
                data: requestData,
            })
            
            if (response.status!==200 && response.status!==201) {
                throw new Error('HTTP request error!')
            }
            return { index: data.factIndex, callBack: data.callBack, ...response.data }
        } catch(error) {
            let errorMessage = 'Failed to update feed plan fact data!'
            if (error instanceof Error) {
                errorMessage = error.message
            }
            return rejectWithValue(errorMessage)
        }
    }
)

const initialState: IFeedPlanState = {
    loading: false,
    error: '',
}

export const feedPlanDataSlice = createSlice({
    name: 'feedplan',
    initialState,
    reducers: {
        setFeedPlanEventInputValue(state, action) {
            if (action.payload.index !== undefined) {
                state.feedPlanData.plan_data[action.payload.index][action.payload.key] = action.payload.value
                state.feedPlanData.plan_data[action.payload.index].modified = true
            } else if (action.payload.value) {
                const newPlanEvent = {
                    active: true,
                    event: action.payload.event,
                    group: action.payload.groupId,
                    modified: true,
                }
                newPlanEvent[action.payload.key] = action.payload.value
                state.feedPlanData.plan_data.push(newPlanEvent)
            }
        },
        setGroupEventFeedPlanActivity(state, action) {
            let wasFound = false
            state.feedPlanData.plan_data.forEach(event => {
                if (event.group === action.payload.groupId && event.event === action.payload.event) {
                    event.active = action.payload.active
                    event.modified = true
                    wasFound = true
                }
            })
            if (!wasFound) {
                state.feedPlanData.plan_data.push({
                    active: true,
                    event: action.payload.event,
                    group: action.payload.groupId,
                    modified: true,
                })
            }
        },
        setFeedPlanEventActivityByIndex(state, action) {           
            state.feedPlanData.plan_data[action.payload.index].active = action.payload.active
            state.feedPlanData.plan_data[action.payload.index].modified = true
        },
        addNewFeedPlanEvent(state, action) {
            const newPlanEvent = {
                active: true,
                event: action.payload.event,
                group: action.payload.group,
                modified: true,
            }
            newPlanEvent[action.payload.key] = action.payload.value
            state.feedPlanData.plan_data.push(newPlanEvent)
        },
        setFeedPlanEventDeviation(state, action) {
            const index = state.feedPlanData.events.findIndex(el => el.id === action.payload.eventId)
            state.feedPlanData.events[index].deviation = action.payload.deviation
        },
        setFeedPlanActivityByEventType(state, action) {
            state.feedPlanData.plan_data.forEach((event, index) => {
                if (event.event === action.payload.event) {
                    if (action.payload.active) {
                        if (event.modified && event.wasActive) event.active = true
                    } else {
                        event.active = false
                        event.modified = true
                    }
                }
            })
        }
    },
    extraReducers: {
        [fetchFeedPlanData.fulfilled.type]: (state, action: PayloadAction<FeedPlanDataType>) => {
            state.loading = false;
            state.error = '';
            state.feedPlanData = action.payload
            state.feedPlanData.plan_data.forEach((el) => el.wasActive = el.active)
        },
        [fetchFeedPlanData.pending.type]: (state) => {
            state.loading = true;
            state.error = '';
        },
        [fetchFeedPlanData.rejected.type]: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        [feedPlanFactDataOnChange.fulfilled.type]: (state, action: PayloadAction<FeedPlanFactDataType>) => {
            if (action.payload.index) {
                state.feedPlanData.fact_data[action.payload.index].weight = action.payload.weight
                state.feedPlanData.fact_data[action.payload.index].dry = action.payload.dry
            } else {
                state.feedPlanData.fact_data.push(action.payload)
            }
            // if (action.payload.callBack) setTimeout(action.payload.callBack, 10)
        },
    },
})

export const {
    setFeedPlanEventInputValue,
    setGroupEventFeedPlanActivity,
    setFeedPlanEventDeviation,
    setFeedPlanEventActivityByIndex,
    addNewFeedPlanEvent,
    setFeedPlanActivityByEventType
} = feedPlanDataSlice.actions
export default feedPlanDataSlice.reducer
