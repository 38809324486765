import { Box } from '@mui/system'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

type FarmFillBackProps = {
    prevStep: '/' | '/ration' | '/groups'
}

const FarmFillBack = (props: FarmFillBackProps) => {
  return (
    <NavLink to={`/fill/1${props.prevStep}`}>
        <Box display='flex' flexDirection='row'>
            <Box><ArrowBackIosIcon color='action' /></Box>
            <TypographyPrimary sx={{pt: '4px'}}>Вернуться к предыдущему шагу</TypographyPrimary>
        </Box>
    </NavLink>
  )
}

export default FarmFillBack