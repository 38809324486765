import React, { useEffect, useState } from 'react'
import axAPI from '../http'
import Box from '@mui/material/Box/Box'
import CssBaseline from '@mui/material/CssBaseline/CssBaseline'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'
import Toolbar from '@mui/material/Toolbar/Toolbar'
import NotificationSide from '../components/notifications/NotificationSide'
import { Outlet } from 'react-router-dom'

const MainPage = () => {
	const [openNotifications, setOpenNotifications] = useState(false)
	const [countNotif, setCountNotif] = useState(0)
	useEffect(() => {
		axAPI.get(`notifications/count`).then(response => {
			setCountNotif(response.data.new_count)
		})
	}, [])
	return (
		<>
			<Box sx={{ display: 'flex', position: 'relative' }}>
				<CssBaseline />
				<Sidebar
					setOpenNotifications={setOpenNotifications}
					countNotif={countNotif}
				/>
				<Header />
				<Box
					component='main'
					sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
				>
					<Toolbar />
					<Outlet />
				</Box>
			</Box>
			<NotificationSide
				openNotifications={openNotifications}
				setOpenNotifications={setOpenNotifications}
				countNotif={countNotif}
				setCountNotif={setCountNotif}
			/>
		</>
	)
}

export default MainPage
