import Box from '@mui/material/Box/Box'
import React, { useEffect, useState } from 'react'
import NotifBox from './NotifBox'
import axAPI from '../../http'
import EditPlanPageDistribution from './EditPlanPageDistribution'
import EditPlanPageNudge from './EditPlanPageNudge'
import EditPlanPageCleaning from './EditPlanPageCleaning'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { COLORS } from '../../styles/colors'
import { ButtonUI } from '../../ui/ButtonUI'
import { ButtonCancel } from '../../ui/ButtonCancel'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../store/hooks'
import { notifyUser } from '../../store/notificationsSlice'

interface IFeedPlanType {
	active: boolean
	deviation: number
	distributed_food_offset: number
	id: number
	time: string
	type: string
	weight: number
	feed_fact: IFeedFact[]
}
interface IFeedFact {
	date: string
	food_weight: number
	plan_time: string
	plan_weight: number
	plan_weight_percent: number
	group: IFeedFactGroup
}
interface IFeedFactGroup {
	cattles_qty: number
	id: number
	title: string
	barn: IFeedFactGroupBarn
	ration: IFeedFactGroupRation
}

interface IFeedFactGroupBarn {
	company: number
	id: number
	title: string
}
interface IFeedFactGroupRation {
	title: string
	id: number
	components: IFeedFactGroupRationComponents[]
}
interface IFeedFactGroupRationComponents {
	item: string
	coef: number
	itemKey: string
}
interface IDeviation {
	deviationDistribution: number
	deviationCleaning: number
	deviationNudge: number
}
const EditPlanPage = () => {
	const [feedPlan, setFeedPlan] = useState<any[]>()
	const [feedPlanDistribution, setFeedPlanDistribution] =
		useState<IFeedPlanType[]>()
	const [feedPlanCleaning, setFeedPlanCleaning] = useState<IFeedPlanType[]>()
	const [feedPlanNudge, setFeedPlanNudge] = useState<IFeedPlanType[]>()
	const [adapterFeedPlan, setAdapterFeedplan] = useState<any[]>([])
	const [adapterNudgePlan, setAdapterNudgePlan] = useState<any[]>([])
	const [feedPlanDate, setFeedPlanDate] = useState<Date | null>(new Date())
	const [finallArrayForPost, setFinallArrayForPost] = useState<any[]>([])
	const [deviationArray, setDeviationArray] = useState<IDeviation>({
		deviationDistribution: 0,
		deviationCleaning: 0,
		deviationNudge: 0,
	})
	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);
	
	useEffect(() => {
		axAPI
			.get(`feedplans/${tomorrow.toISOString().slice(0, 10)}`)
			.then(response => {
				setFeedPlan(response.data)
				const distribution = response.data.filter(
					el => el.type === 'distribution'
				)
				const cleaning = response.data.filter(el => el.type === 'cleaning')
				const nudge = response.data.filter(el => el.type === 'nudge')
				setFeedPlanDistribution(distribution)
				setFeedPlanCleaning(cleaning)
				setFeedPlanNudge(nudge)
				setDeviationArray(prevData => ({
					...prevData,
					deviationDistribution:
						distribution.length > 0 ? distribution[0].deviation : 0,
					deviationCleaning: cleaning.length > 0 ? cleaning[0].deviation : 0,
					deviationNudge: nudge.length > 0 ? nudge[0].deviation : 0,
				}))
			})
	}, [feedPlanDate])

	const isErrorState = adapterNudgePlan?.some(el => el.errorValuesCell === true)
	useEffect(() => {
		if (isErrorState === false && adapterNudgePlan.length > 0) {
			const newArray = []
			for (let i = 0; i < adapterNudgePlan.length; i++) {
				for (let j = 0; j < adapterNudgePlan[i].time.length; j++) {
					if (
						adapterNudgePlan[i].time[j] !== '' &&
						adapterNudgePlan[i].weightPercent[j] !== ''
					) {
						const newObj = {
							type: 'distribution',
							deviation: deviationArray.deviationDistribution,
							group_id: adapterNudgePlan[i].group_id,
							time: adapterNudgePlan[i].time[j],
							weight_percent: adapterNudgePlan[i].weightPercent[j],
						}
						newArray.push(newObj)
					}
				}
			}

			for (let i = 0; i < adapterNudgePlan.length; i++) {
				for (let j = 0; j < adapterNudgePlan[i].timeCleaning.length; j++) {
					if (adapterNudgePlan[i].timeCleaning[j] !== '') {
						const newObj = {
							type: 'cleaning',
							deviation: deviationArray.deviationCleaning,
							group_id: adapterNudgePlan[i].group_id,
							time: adapterNudgePlan[i].timeCleaning[j],
						}
						newArray.push(newObj)
					}
				}
			}

			for (let i = 0; i < adapterNudgePlan.length; i++) {
				for (let j = 0; j < adapterNudgePlan[i].timeNudge.length; j++) {
					if (
						adapterNudgePlan[i].deviationTimeNudge === undefined &&
						adapterNudgePlan[i].timeNudge[j] !== ''
					) {
						const newObj = {
							type: 'nudge',
							deviation: deviationArray.deviationNudge,
							group_id: adapterNudgePlan[i].group_id,
							time: adapterNudgePlan[i].timeNudge[j],
						}
						newArray.push(newObj)
					}
				}
			}
			setFinallArrayForPost(newArray)
		}
	}, [adapterNudgePlan, isErrorState, deviationArray])

	const navigate = useNavigate()

	const cancelButton = () => {
		navigate(`/feed/plan/`)
	}
	const dispatch = useAppDispatch()
	const saveChangeAtServer = () => {
		axAPI({
			method: 'POST',
			url: '/feedplans/',
			data: { plans: finallArrayForPost },
		}).then(response => {
			navigate('/feed/plan', { state: { showNotification: true } })

			dispatch(notifyUser({
        title: 'План кормления',
        description: 'Изменения успешно сохранены',
        isError: false,
      }))
    })
    .catch(error => {      
      dispatch(notifyUser({
        title: 'План кормления',
        description: 'Ошибка при сохранении плана кормления',
        isError: true,
      }))
      
    })
	}
	if (!feedPlanDistribution) return <></>

	return (
		<>
			{!feedPlan ||
			!feedPlanDistribution ||
			!feedPlanNudge ||
			!feedPlanCleaning ||
			!adapterFeedPlan ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress
						sx={{
							'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						
						display: 'flex',
						flexDirection: 'column',
						gap: '24px',
						// padding: '0 40px 0 0px',
						padding: '0px',
						margin:'0px',
						// width: 'calc(100vw - 20%)',
						width:'79vw'
						
					}}
				>
					<NotifBox />
					<EditPlanPageDistribution
						setDeviationArray={setDeviationArray}
						setFeedPlanDistribution={setFeedPlanDistribution}
						feedPlanDistribution={feedPlanDistribution}
						setAdapterFeedplan={setAdapterFeedplan}
						setAdapterNudgePlan={setAdapterNudgePlan}
					/>
					<EditPlanPageCleaning
						setDeviationArray={setDeviationArray}
						setFeedPlanCleaning={setFeedPlanCleaning}
						feedPlanCleaning={feedPlanCleaning}
						adapterFeedPlan={adapterFeedPlan}
						adapterNudgePlan={adapterNudgePlan}
						setAdapterNudgePlan={setAdapterNudgePlan}
					/>
					<EditPlanPageNudge
						setDeviationArray={setDeviationArray}
						feedPlanNudge={feedPlanNudge}
						adapterNudgePlan={adapterNudgePlan}
						setAdapterNudgePlan={setAdapterNudgePlan}
						adapterFeedPlan={adapterFeedPlan}
						setFeedPlanNudge={setFeedPlanNudge}
					/>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'end',
							marginTop: '-24px',
						}}
					>
						<ButtonCancel onClick={cancelButton}>Отменить</ButtonCancel>
						<ButtonUI onClick={saveChangeAtServer} disabled={isErrorState === false ? false : true}>
							Сохранить
						</ButtonUI>
					</Box>
				</Box>
			)}
		</>
	)
}

export default EditPlanPage
