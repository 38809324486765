import { Card, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axAPI from '../../http'
import { IGroupEntity } from '../../models/IGroup'
import GroupSpecificData from './GroupSpecificData'

type GroupInfoProps = {
	reachable?: number
	unreachable?: number
	barntitle: string
	rationtitle: string
	cattles_qty: number
	description?: string
	weight_per_head: number
	lastDistribution: number
	lastNudge: number
}


const GroupSpecificInfo = ({unreachable, reachable, barntitle, rationtitle, cattles_qty, description, weight_per_head, lastDistribution, lastNudge}: GroupInfoProps) => {
	return (
		<Card
			sx={{
				minHeight: '310px',
				minWidth: '300px',
				boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
				backgroundColor: 'white',
				borderRadius: '12px',
			}}
		>
			<Grid container direction='column'>
				<Grid item>
					<Typography
						sx={{
							fontWeight: 700,
							fontSize: '20px',
							lineHeight: '24px',
							pt: '16px',
							pl: '24px',
						}}
					>
						Общая информация
					</Typography>
				</Grid>
				<Grid item>
					<Stack direction='column'>
						<GroupSpecificData
							cattles_qty={cattles_qty}
							barntitle={barntitle}
							description={description}
							rationtitle={rationtitle}
							weight_per_head={weight_per_head}
							reachable={reachable}
							lastDistribution={lastDistribution}
							lastNudge={lastNudge}
						/>
					</Stack>
				</Grid>
			</Grid>
		</Card>
	)
}

export default GroupSpecificInfo
