import * as React from 'react'

import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { Box, Typography } from '@mui/material'
import { TypographyPrimary } from '../../ui/TypographyPrimary'

import { ReactComponent as InfoNotificationLogo } from '../../assets/vector_ok_logo.svg'
import { ReactComponent as WarningNotificationLogo } from '../../assets/warning_notification.svg'

import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { closeNotification } from '../../store/notificationsSlice'

export default function Notification() {
	const { open, title, description, isError } = useAppSelector(
		store => store.notificationsSlice
	)
	const dispatch = useAppDispatch()

	const handleClose = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return
		}

		dispatch(closeNotification())
	}

	return (
		<Box sx={{ height: '80px', width: '460px' }}>
			<Snackbar
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
			>
				<Box
					sx={{
						backgroundColor: 'white',
						borderRadius: '8px',
						p: '19px',
						boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					}}
					display='flex'
					flexDirection='row'
					alignItems='flex-start'
				>
					{isError ? <WarningNotificationLogo /> : <InfoNotificationLogo />}
					<Box
						display='flex'
						flexDirection='column'
						sx={{ pl: '11px', pr: '32px' }}
					>
						<Typography
							sx={{
								fontWeight: 600,
								fontSize: '16px',
								lineHeight: '20px',
								pb: '10px',
							}}
						>
							{title}
						</Typography>
						<TypographyPrimary>{description}</TypographyPrimary>
					</Box>
					<Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
						<CloseIcon fontSize='small' />
					</Box>
				</Box>
			</Snackbar>
		</Box>
	)
}
