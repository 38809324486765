import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TypographyHeader } from '../../ui/TypographyHeader';
import { Box, Divider, IconButton } from '@mui/material';
import { TypographyPrimary } from '../../ui/TypographyPrimary';
import { ButtonUI } from '../../ui/ButtonUI';
import { RationTextField } from '../ration/RationTextField';
import { UserEditField } from '../shared/UserEditField';
import { COLORS } from '../../styles/colors';
import SelectionField from '../shared/SelectionField';
import axAPI from '../../http';

import {ReactComponent as CrossCloseLogo} from '../../assets/cross_close_logo.svg'

import { useAppDispatch } from '../../store/hooks'
import { notifyUser } from '../../store/notificationsSlice'
import { PhoneEditField } from '../shared/PhoneEditField';

type UserPopupProps = {
    userId: number;
    open: boolean;
    title: string;
    action: string;
    handleClose: () => void;
}

const UserPopup = (props: UserPopupProps) => {

  const dispatch = useAppDispatch()

  const blankState = {
    role: -1,
    id: null,
    username: '',
    fullname: '',
    lastname: '',
    firstname: '',
    password: '',
    position: '',
    email: '',
    mobile_phone: '',
    superuser: false
  }
  const [userData, setUserData] = useState(blankState)

  const blankRolesList = []
  const [rolesChoiceList, setRolesChoiceList] = useState(blankRolesList)

  const updateUserDataHandler = (field, value) => {
    const tmpData = { ...userData }
    tmpData[field] = value
    
    setUserData(tmpData)
  }

  const [errorState, setErrorState] = useState({
    fullname: false,
    username: false,
    phoneNum: false,
    email: false,
    position: false
  })

  const validators = {
    fullname: () => {
      const isError = userData.fullname.split(' ').length < 2
      if(errorState.fullname !== isError) { 
       setErrorState({...errorState, fullname: isError}) 
      }
    },
    email: () => {
      const emailValidate =  userData.email
     
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    
      const isError = !emailValidate
      if(errorState.email !== isError) {
       setErrorState({...errorState, email: isError}) 
      }
    },
    phoneNum: () => {
      const phoneValidate = userData.mobile_phone.match(/(?:\+|\d)[\d\-\(\) ]{9,}\d/g)
      const isError = !phoneValidate
      if(errorState.phoneNum !== isError) {
       setErrorState({...errorState, phoneNum: isError}) 
      }
    },
    position: () => {
      const isError = userData.position.length < 2
      if(errorState.position !== isError) {
       setErrorState({...errorState, position: isError}) 
      }
    },
  } 

  useEffect(() => {
    axAPI({
      method: 'GET',
      url: `auth/roles`,
    }).then(response => {
      const rolesSet = response.data.map(role => {
        return {
          title: role.title,
          value: role.id,
        }
      })
      setRolesChoiceList([...blankRolesList, ...rolesSet])
    })
    if (props.userId) {
      updateUserDataHandler('id', props.userId)
      axAPI({
        method: 'GET',
        url: `auth/users/${props.userId}/`,
      }).then(response => {
        setUserData(
          { ...response.data,
            fullname: `${response.data.lastname} ${response.data.firstname}`,
            role: response.data.role ? response.data.role : -1,
            password: '',
          }
        )
      })
    }
  }, [props.userId])

  const updateUserDataAtServer = (currentUserId: Number) => {
    const updateData = {
      ...userData,
      lastname: userData.fullname.split(' ')[0],
      firstname: userData.fullname.split(' ')[1],
      role: userData.role === -1 ? null : userData.role,
      username: userData.email,
      email: userData.email,
    }
    if (!updateData.username) updateData.username = updateData.email

    axAPI({
      method: 'PUT',
      url: `auth/users/${currentUserId}/`,
      data: updateData,
    }).then(response => {
      dispatch(notifyUser({
        title: userData.fullname,
        description: 'Изменения успешно сохранены',
        isError: false,
      }))
      setUserData(blankState)      
      props.handleClose()
    }).catch(error => {
      let errorText = ''
      if (error.response.data.password) {
        errorText += error.response.data.password[0]
      } else if (error.response.data.username) {
          errorText = 'Пользователь с таким e-mail уже существует'
      } else if (error.response.data.email) {
        errorText += error.response.data.email[0]
      }

      dispatch(notifyUser({
        title: userData.fullname,
        description: errorText ? errorText :'Ошибка при сохранении данных',
        isError: true,
      }))
    })
  }

  const saveDataAtServer = () => {
    if (!userData.id) {
      axAPI({
        method: 'POST',
        url: `auth/users/`,
        data: { username: userData.email, password: userData.password },
      }).then(response => {
        updateUserDataHandler('id', response.data.id)
        updateUserDataAtServer(response.data.id)
      }).catch(error => {
        let errorText = ''
        if (error.response.data.password) {
          errorText += error.response.data.password[0]
        } else if (error.response.data.username) {
            errorText = 'Пользователь с таким e-mail уже существует'
        } else if (error.response.data.email) {
          errorText += error.response.data.email[0]
        }

        dispatch(notifyUser({
          title: userData.fullname,
          description: errorText ? errorText :'Ошибка при сохранении данных',
          isError: true,
        }))
      })
    } else {
      updateUserDataAtServer(userData.id)
    }

    if (props.userId && userData.password) {
      // Change password for an existing user
      axAPI({
        method: 'POST',
        url: `auth/force_password`,
        data: { id: userData.id, password: userData.password},
      }).then(responce => {
        if (responce.status===202) {
          dispatch(notifyUser({
            title: userData.fullname,
            description: 'Пароль пользователя успешно изменен',
            isError: false,
          }))
        }
      }).catch(error => {
        let errorText = ''
        try {
          errorText += error.response.data.password[0]
        } catch {
          // NOTHING TODO  
        }
        dispatch(notifyUser({
          title: userData.fullname,
          description: errorText ? errorText : 'Ошибка при изменении пароля пользователя',
          isError: true,
        }))
      })
    }
  }


  return <Dialog 
    open={props.open} 
    maxWidth='lg' 
    scroll='body' 
    PaperProps={{
      style: {borderRadius: '12px'}
    }}>
      <DialogTitle sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <TypographyHeader>{ props.title } пользователя</TypographyHeader>
        <IconButton onClick={props.handleClose}><CrossCloseLogo /></IconButton>
      </DialogTitle>
      <DialogContent>
          <Box sx={{pr: '12px'}}>
              <TypographyPrimary sx={{fontWeight: 600, fontSize: '16px', lineHeight: '20px', pt: '8px'}}>Общая информация</TypographyPrimary>
              <Box display='flex' flexDirection='row'>
                <UserEditField
                  isErrorFocus={errorState.fullname}
                  onBlur={() => validators.fullname()}
                  onFocus={() => {
                    setErrorState({...errorState, fullname: false})
                  }}
                  helperText='Укажите Фамилию и Имя'
                  value={ `${userData.fullname}` }
                  id={`userFirstLastName${props.userId}`}
                  label='Фамилия Имя'
                  onChange={ (e) => {
                    updateUserDataHandler('fullname', e.target.value)
                  } }
                />
              </Box>
              
              <Box display='flex' flexDirection='row' >
                  <UserEditField
                    isErrorFocus={errorState.email} 
                    onBlur={() => validators.email()}
                    onFocus={() => {
                      setErrorState({...errorState, email: false})
                    }}
                    helperText='Укажите электронную почту'
                    value={ userData.email }
                    id={`useremail${props.userId}`}
                    isError={(!props.userId && !userData.email)}
                    label='Электронная почта'
                    onChange={ (e) => updateUserDataHandler('email', e.target.value) }
                  />
                  <PhoneEditField
                    isErrorFocus={errorState.phoneNum} 
                    onBlur={() => validators.phoneNum()}
                    onFocus={() => {
                      setErrorState({...errorState, phoneNum: false})
                    }}
                    helperText='Укажите номер телефона'
                    marginLeft='24px'
                    value={ userData.mobile_phone }
                    id={`usermobile_phone${props.userId}`}
                    label='Номер телефона'
                    onChange={ (value) => updateUserDataHandler('mobile_phone', value) }
                  />
              </Box>
              <Box display='flex' flexDirection='row' alignItems='center'>
                  <UserEditField
                      isErrorFocus={errorState.position} 
                      onBlur={() => validators.position()}
                      onFocus={() => {
                        setErrorState({...errorState, position: false})
                      }}
                      helperText='Укажите должность'
                      key={`userPosition${props.userId}`}
                      value={ userData.position }
                      id='position'
                      label='Должность'
                      onChange={ (e) => updateUserDataHandler('position', e.target.value) }
                    />
                  <Box sx={{ml: '24px', pt: '6px'}}>
                    <SelectionField
                      id={`userRole${props.userId}`}
                      choiceList={ rolesChoiceList }
                      currentValue={ userData.role }
                      onChange={ (value) => updateUserDataHandler('role', value) }
                      width={ 500 }
                      inputLabel='Роль пользователя'
                    />
                  </Box>
              </Box>
              <Divider sx={{pt: '32px'}} />
              <TypographyPrimary
                sx={{fontWeight: 600, fontSize: '16px', lineHeight: '20px', pt: '32px'}}
              >
                { `${props.action} пароля` }
              </TypographyPrimary>
              <UserEditField
                isPassword
                sx={{width: '500px', mt: '32px', '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: COLORS.secondaryFontOpacity,
                      },
                    }}}
                id={`userPassword${props.userId}`}
                isError={(!props.userId && !userData.password)}
                onChange={ (value) => updateUserDataHandler('password', value.target.value) }
                label='Новый пароль'
              />
          </Box>
          <Box display='flex' justifyContent='center'>
            <ButtonUI
              sx={{height: '56px'}}
              onClick={ () => saveDataAtServer() }
              disabled={ (!props.userId && !userData.password) || (userData.role === null || userData.role < 0 ) }
            >
              { 'Сохранить' }
            </ButtonUI>
          </Box>
      </DialogContent>
  </Dialog>
}

export default UserPopup