import { useState } from 'react'

import { Card, CardContent, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { ReactComponent as GroupDeleteIcon } from '../../assets/group_delete_icon.svg'
import { ReactComponent as PlanEditPlusLogo } from '../../assets/plan_edit_plus_logo.svg'

import CreatePlanListNudgeContent from './CreatePlanListNudgeContent'
import InputField from '../shared/InputField'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { PlanEventType, PlanEventTypeEnum } from '../../models'
import { setFeedPlanEventDeviation, setFeedPlanActivityByEventType } from '../../store/feedPlanSlice'
import { COLORS } from '../../styles/colors';
import { TypographyHeaderSecondary } from '../../ui/TypographyHeaderSecondary'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { TypographyPrimary } from '../../ui/TypographyPrimary'

const CreatePlanListNudge = (props: PlanEventType) => {

  const dispatch = useAppDispatch()
  const { feedPlanData } = useAppSelector(state => state.feedPlanReducer)

  const [planListIsEmpty, setPlanListIsEmpty] = useState(
    feedPlanData.plan_data.filter(event => !event.active && event.event === PlanEventTypeEnum.Nudge).length === 0
  )

  if (planListIsEmpty) {
    return <Card sx={{ p: '0px', mt: '24px', mr: props.isFirstTry ? '0px' : '36px', border: `1px solid ${COLORS.icon}`, height: '54px' }}>
      <CardContent onClick={() => {
            dispatch(setFeedPlanActivityByEventType({ active: true, event: PlanEventTypeEnum.Nudge }))
            setPlanListIsEmpty(false)
          }}
      >
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>
          </Box>
          <TypographyHeaderSecondary sx={{ color: COLORS.icon }}>
            Подталкивание корма
          </TypographyHeaderSecondary>
          <PlanEditPlusLogo />
        </Box>
      </CardContent>
    </Card>

  }

  return <Box sx={{ pt: '24px' }}>
    <Card sx={{ maxWidth: props.isFirstTry? '100%' : '97%', boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)', backgroundColor: 'white', pl: '18px', borderRadius: '12px' }}>
      <CardContent>
        <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{ pr: '16px' }} alignItems='center' >
          <TypographyHeader >{'Подталкивание'}</TypographyHeader>
          <GroupDeleteIcon onClick={() => {
              dispatch(setFeedPlanActivityByEventType({ active: false, event: PlanEventTypeEnum.Nudge }))
              setPlanListIsEmpty(true)
          }}/>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          sx={{ pt: '24px', }}
        >
          <TypographyPrimary sx={{ pr: '16px' }}>
            Допустимое отклонение по времени
          </TypographyPrimary>
          <InputField
            key='inputFieldDeviationNudge'
            label='Минуты'
            type='number'
            value={props.deviation.toString()}
            onBlur={(value) => dispatch(setFeedPlanEventDeviation({ deviation: value, eventId: PlanEventTypeEnum.Nudge }))}
            sx={{
              width: '120px',
              height: '54px',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: COLORS.secondaryFontOpacity,
                },
              },
            }}
          />
        </Box>
        <CreatePlanListNudgeContent />
      </CardContent>
    </Card>

  </Box>
}

export default CreatePlanListNudge