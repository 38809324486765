import {
	Box,
	CardContent,
	Divider,
	TextField,
	Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ReactComponent as TranslationLogo } from '../../assets/translation_logo.svg'
import { ReactComponent as GroupSpecificEditLogo } from '../../assets/group_specific_edit.svg'

import { fetchGroupList } from '../../store/groupsSlice'
import { fetchRationList } from '../../store/rationSlice'
import { fetchBarnList } from '../../store/barnSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { notifyUser } from '../../store/notificationsSlice'

import { COLORS } from '../../styles/colors'
import { ButtonCancel } from '../../ui/ButtonCancel'
import { ButtonUI } from '../../ui/ButtonUI'
import { CardStandart } from '../../ui/CardStandart'
import { TypographyHeader } from '../../ui/TypographyHeader'
import SelectionField from '../shared/SelectionField'

import { IGroupEntity } from '../../models'
import axAPI from '../../http'
import CustomTextField from '../shared/CustomTextField'
import { updateGroupsData } from '../../store/groupsSlice' 

type GroupSpecificEditProps = {
	groupId?: any
	isFirstTry?: boolean
}

const GroupSpecificEdit = (props: GroupSpecificEditProps) => {
	const urlParams = useParams()
	const navigate = useNavigate()

	const dispatch = useAppDispatch()
	const { groupList } = useAppSelector(store => store.groupsReducer)
	const { rationList } = useAppSelector(store => store.rationReducer)
	const { barnList } = useAppSelector(store => store.barnReducer)

  const [groupData, setGroupData] = useState<IGroupEntity>()

  useEffect(() => {
    if (!groupList || groupList.length === 0) dispatch(fetchGroupList())
    if (!rationList || rationList.length === 0) dispatch(fetchRationList())
    if (!barnList) dispatch(fetchBarnList())
    setGroupData(groupList.find(
      group => group.key.toString() === urlParams.groupkey
    ))
  }, [groupList])
  const setObjectValue = (field, value) => {
    const tmp = { ...groupData }
    tmp[field] = value
    setGroupData(tmp)
  }

const saveChangesAtServer = () => {
  const data = {
    key: groupData.id,
    title: groupData.title,
    description: groupData.description,
    cattles_qty: groupData.cattles_qty,
    weight_per_head: groupData.weight_per_head,
    ration_id: groupData.ration,
    barn: groupData.barn
  }
  axAPI({
    method: 'PUT',
    url: `groups/${groupData.key}`,
    data: data,
  }).then(response => {
    if (response.status === 200) {
      dispatch(
        notifyUser({
          title: groupData.title,
          description: 'Изменения успешно сохранены',
          isError: false,
        })
      )
			dispatch(updateGroupsData(groupData))
      navigate(`/feed/groups/${groupData.key}`)
    }
  })
}

	const cancelButton = () => {
		navigate(`/feed/groups/${groupData.key}`)
	}
if (!groupData) return <></>
	return (
		<Box
			sx={{
				pr: props.isFirstTry ? '8px' : '30px',
				pl: '16px',
				mt: '24px',
				width: '100%',
			}}
		>
			{/* {urlParams.groupkey ? (
				<ButtonUI
					sx={{
						width: '150px',
						height: '40px',
						textTransform: 'none',
					}}
				>
					<TranslationLogo />{' '}
					<Typography sx={{ fontSize: '14px', pl: '10px' }}>
						Трансляция
					</Typography>
				</ButtonUI>
			) : (
				<></>
			)} */}

			<CardStandart
				sx={{
					mt: props.isFirstTry ? '0px' : '16px',
					width: '100%',
					boxShadow: props.isFirstTry
						? 'none'
						: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					'&:hover': {
						boxShadow: props.isFirstTry
							? 'none'
							: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					},
				}}
			>
				<CardContent sx={{ p: '24px', width: '100%' }}>
					{!props.isFirstTry ? (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '8px',
							}}
						>
							<TypographyHeader
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									height: '16px',
								}}
							>
								{groupData.title}
							</TypographyHeader>
							<Typography
								sx={{
									fontSize: '12px',
									fontWeight: '400',
									lineHeight: '15px',
									color: '#7F7F84',
								}}
							>
								{groupData.barntitle}
							</Typography>
							{/* <IconButton sx={{ pl: '8px' }}>
								<GroupSpecificEditLogo />
							</IconButton> */}
						</Box>
					) : (
						<></>
					)}
					<Divider sx={{ pt: '18px', width: '100%' }} />
					<Typography
						sx={{
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '20px',
							pt: '24px',
              mb: '24px'
						}}
					>
						Общая информация
					</Typography>
					<Box display='flex' flexDirection='column' gap='24px'>
						<CustomTextField
							label={'Название группы'}
							value={groupData.title}
							width={'47%'}
							error={!groupData.title}
							helperText={!groupData.title ? 'Поле не может быть пустым' : ''}
							onChange={e => {
								setObjectValue('title', e.target.value)
							}}
						/>

						<CustomTextField
							label={'Количество голов'}
							value={groupData.cattles_qty}
							type={'number'}
							width={'47%'}
							error={!groupData.cattles_qty}
							helperText={
								!groupData.cattles_qty ? 'Поле не может быть пустым или отрицательным' : ''
							}
							onChange={e => {
								let inputValue = e.target.value
								if (inputValue < 0) {
									inputValue = ''
								} else {
									setObjectValue('cattles_qty', e.target.value)
								}
							}}
						/>

						<TextField
							multiline
							variant='outlined'
							label='Описание'
							value={groupData.description}
							sx={{
								'& label': {
									fontSize: groupData.description ? '16px' : '14px',
								},
								'& label.Mui-focused': {
									color: `${COLORS.main}`,
									fontSize:'16px',
								},
								'& .MuiInputBase-input': {
									fontSize: '14px',
									paddingLeft: '20px',
								},
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px',
									width: '100%',
									'& fieldset': {
										border: `1px solid ${COLORS.inputBorder}`
									},
									'&:hover fieldset': {
										border: `1px solid ${COLORS.hoverInputBorder}`
									},
									'&.Mui-focused fieldset': {
										border: `1px solid ${COLORS.main}`
									},
								},
							}}
							onChange={e => {
								setObjectValue('description', e.target.value)
							}}
							inputProps={{
								style: { fontSize: '14px', paddingLeft: '10px' },
							}}
						/>
					</Box>
					<Divider sx={{ pt: '24px', width: '100%' }} />
					<Typography
						sx={{
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '20px',
							pt: '24px',
              pb: '24px',
						}}
					>
						Рацион
					</Typography>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<SelectionField
							id={groupData.groupkey}
							inputLabel={'Рацион'}
							width={'47%'}
							currentValue={groupData.ration}
							choiceList={
								rationList
									? rationList.map(el => {
											return {
												value: el.id,
												title: el.title,
											}
									  })
									: []
							}
							onChange={value => setObjectValue('ration', value)}
						/>

						<CustomTextField
							width={'46%'}
							label={'Норма ПСВ, кг/голову'}
							type={'number'}
							value={groupData.weight_per_head}
							error={!groupData.weight_per_head}
							helperText={
								!groupData.weight_per_head ? 'Поле не может быть пустым или отрицательным' : ''
							}
							onChange={e => {
								let inputValue = e.target.value
								if (inputValue < 0) {
									inputValue = ''
								} else {
									setObjectValue('weight_per_head', e.target.value)
								}
							}}
						/>
					</Box>
					<Divider sx={{ pt: '20px', width: '100%' }} />
					<Box
						display='flex'
						justifyContent='flex-end'
						alignItems='center'
					>
						{props.isFirstTry ? (
							<></>
						) : (
							<ButtonCancel
								onClick={cancelButton}
								sx={{
									fontWeight: 600,
									fontSize: '14px',
									lineHeight: '17px',
									textTransform: 'none',
								}}
							>
								Отменить
							</ButtonCancel>
						)}
						<ButtonUI
							sx={{
								width: '400px',
								height: '56px',
								fontWeight: 600,
								fontSize: '14px',
								lineHeight: '17px',
							}}
							onClick={saveChangesAtServer}
							disabled={
								!groupData.cattles_qty ||
								!groupData.weight_per_head ||
								!groupData.ration ||
								!groupData.title
							}
						>
							Сохранить
						</ButtonUI>
					</Box>
				</CardContent>
			</CardStandart>
		</Box>
	)
}

export default GroupSpecificEdit
