import { COLORS } from '../../styles/colors'
import InputField from './InputField'

interface ITimeFieldProps {
  key: string
  value?: string | number
  sx?: any
  width?: string
  onBlur?: any
  onClose?: any
  autoFocus?: boolean
}

const TimeField = (props: ITimeFieldProps) => {

  return <InputField
    key={props.key}
    label={"Время"}
    onBlur={ props.onBlur }
    onClose={ props.onClose }
    type={"time"}
    value={ props.value ? props.value : '' }
    sx={props.sx ? props.sx : ''}
    width={props.width}
    autoFocus={props.autoFocus}
  />
}

export default TimeField