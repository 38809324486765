import Box from '@mui/material/Box/Box'
import React from 'react'
import { ReactComponent as BarchartInfoLogo } from '../../assets/barchart_info_logo.svg'
import { COLORS } from '../../styles/colors'
import Typography from '@mui/material/Typography/Typography'

const NotifBox = () => {
	const today = new Date()
	today.setDate(today.getDate() + 1)
	const day = today.getDate().toString().padStart(2, '0')
	const month = (today.getMonth() + 1).toString().padStart(2, '0')
	const year = today.getFullYear()
	const nextDayFormatted = `${day}.${month}.${year}`

	return (
		<Box
			sx={{
				maxWidth: '100%',
				maxHeight: '72px',
				background: ' white',
				borderRadius: ' 12px',
				boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
			}}
		>
			<Box
				sx={{
					padding: '26px',
					display: 'flex',
					flexDirection: ' row',
					alignItems: 'center',
				}}
			>
				<BarchartInfoLogo stroke={COLORS.secondaryFont} />
				<Typography
					sx={{
						marginLeft: '18px',
						fontSize: '14px',
						fontWeight: '400',
						lineHeight: '16.8px',
					}}
				>
					{`Внесенные изменения будут применены начиная с завтра, ${nextDayFormatted}
					00:00.`}
				</Typography>
			</Box>
		</Box>
	)
}

export default NotifBox
