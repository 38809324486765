import { NavLink } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Divider } from '@mui/material';

import { ReactComponent as TableEditLogo } from '../../assets/table_edit_logo.svg'

import { getRationComponentsPresentation } from '../../utils'
import { IRationEntity } from '../../models';
import { COLORS } from '../../styles/colors';

const RationTable = ({ tableData }) => {
  return <Box sx={{ pt: '24px' }}>
    <TableContainer sx={{boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)', backgroundColor: 'white', borderRadius: '12px', pl: '12px', pr: '12px'}}>
      <Table sx={{
        minWidth: 650,
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none"
        }
      }} aria-label="simple table">
        <TableHead sx={{ borderBottom: `1px inset ${COLORS.secondaryFontOpacity}`}}>
          <TableRow>
            <TableCell>Рацион</TableCell>
            <TableCell >Дата создания</TableCell>
            <TableCell >СВ (%)</TableCell>
            <TableCell >Состав</TableCell>
            <TableCell >Группы</TableCell>
            <TableCell align='center' >Действия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((tableRow: IRationEntity) => (
            <TableRow
              key={tableRow.key}
              sx={{}}
            >
              <TableCell component="th" scope="row">
                {tableRow.title}
              </TableCell>
              <TableCell >{tableRow.created_at}</TableCell>
              <TableCell >{tableRow.dry}</TableCell>
              <TableCell >{getRationComponentsPresentation(tableRow.components)}</TableCell>
              <TableCell >{tableRow.gtitles}</TableCell>
              <TableCell align='center' >
                <NavLink to={tableRow.key}>
                  <TableEditLogo />
                </NavLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
}

export default RationTable