import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { COLORS } from '../../styles/colors';

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number, isSumMore: boolean, isHundred: boolean }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{'& .MuiLinearProgress-bar': {
            backgroundColor: props.isSumMore ? COLORS.deviationFont : COLORS.main && !props.isHundred ? COLORS.main : COLORS.success
        }, backgroundColor: props.isSumMore ? COLORS.deviationFont : COLORS.secondaryFontOpacity  && !props.isHundred ? COLORS.secondaryFontOpacity : COLORS.success}} />
      </Box>
    </Box>
  );
}

 const RationProgressBar = (props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={props.value} isSumMore={props.isSumMore} isHundred={props.isHundred} />
    </Box>
  );
}

export default RationProgressBar