import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2'

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Context} from 'chartjs-plugin-datalabels';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { COLORS } from '../styles/colors';

import axAPI from "../http"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
)

ChartJS.register(ChartDataLabels)

export const options = {
    layout: {
        padding: {
            top: 20,
        }
    },
    plugins: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: false,
        },
        legend: {
            display: false,
            onClick: function (e) {
                e.stopPropagation();
            },
            position: 'top' as const,
            align: 'end' as const,
        },
        datalabels: {
            color: (ctx) => (ctx.dataset.data[ctx.dataIndex] === 0 ? COLORS.icon : '' || ctx.dataset.data[ctx.dataIndex] > 0 ? COLORS.success : COLORS.deviationFont ) ,
            anchor: (ctx: Context) => (ctx.dataset.data[ctx.dataIndex] >= 0 ? 'end' : 'start'),
            align: (ctx: Context) => (ctx.dataset.data[ctx.dataIndex] >= 0 ? 'top' : 'bottom'),
            formatter: (value, ctx) => (value === 0 ? 'Пустой' : value),
            font: {
                family: 'Montserrat' as const,
              },
            padding: {
                bottom: -15,
            }
        },
        tooltip: {
            enabled: false
        }
    },
    responsive: true,
    barPercentage: 0.65,
    borderWidth: (ctx) => (ctx.dataset.data[ctx.dataIndex] === 0 ? 5 : 0  ),
    scales: {
        x: {
            stacked: true,
            ticks: {
                font: {
                  family: 'Montserrat' as const,
                }
              },
        },
        y: {
            stacked: true,
            max: 100,
            min: -100,
            grace: 50,
            ticks: {
                font: {
                  family: 'Montserrat' as const,
                }
              },
        },
    },
}


const StackedBarChart = () => {
    const [chartData, setChartData] = useState({})
    useEffect(() => {
        updateChartData()
    }, [])

    const updateChartData = () => {
        axAPI.get("foodtable/info")
        .then(response => {
            setChartData(response.data)
        })
    }

    setTimeout(updateChartData, 60*1000)

    const data = {
        labels: chartData["labels"],
        datasets: [
            {
                label: 'Зона доступности корма',
                data: chartData["reachable"],
                backgroundColor: (ctx) => (ctx.dataset.data[ctx.dataIndex] < 1 ? COLORS.icon : '#65C466' ),
                borderRadius: 5,
                minBarLength: 1.5,
            },
            {
                label: 'Зона недоступности корма',
                data: chartData["unreachable"],
                backgroundColor: '#EB4D3D',
                borderRadius: 5,
            },
        ],
    };
    
    return <Bar height={'62px'} options={options} data={data} />
}

export default StackedBarChart