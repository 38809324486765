import { Box, Card, Divider } from '@mui/material'
import React from 'react'

import { TypographyPrimary } from '../../ui/TypographyPrimary';
import { TypographyHeaderExtra } from '../../ui/TypographyHeaderExtra';
import FarmFillGroupList from './FarmFillGroupList';
import FarmFillStepIndex from './FarmFillStepIndex';
import FarmFillBack from './FarmFillBack';

const FarmFillGroups = () => {
  return (
    <Box sx={{pr: '150px'}}>
      <FarmFillBack prevStep='/ration' />
      <FarmFillStepIndex stepNum='2' fullfied='2' />

      <Box sx={{pt: '60px'}}>
        <TypographyHeaderExtra>
            Группы
        </TypographyHeaderExtra>
        <TypographyPrimary sx={{pt: '24px'}}>
            Создайте рационы, которые используются на ферме.
        </TypographyPrimary>
        <Box sx={{pt: '40px'}}>
          <FarmFillGroupList />   
        </Box>
      </Box>
    </Box>
  )
}

export default FarmFillGroups