import React from 'react'

import { Box, Grid, Typography } from '@mui/material'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'

import ViewPlanListCleaning from './ViewPlanListCleaning'
import ViewPlanListDistribution from './ViewPlanListDistribution'
import ViewPlanListNudge from './ViewPlanListNudge'

import { COLORS } from '../../styles/colors'

import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'
import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as DeviationLogo } from '../../assets/deviation_logo.svg'
import { ReactComponent as OkVerifyLogo } from '../../assets/ok_verify_logo.svg'

import PlanCardCompound from './PlanCardCompound'
import { TypographyHeader } from '../../ui/TypographyHeader'
import { TypographyPrimary } from '../../ui/TypographyPrimary'
import { IFeed, IFeedFact, IFeedFactGroup } from '../../models/IFeed'

const TestPlanCardView = (props: IFeed) => {
	const { isFuture, isPast, isToday } = props
	const [open, setOpen] = React.useState(false)
	const uniqueKey = `${props.id}${props.time.replace(':', '')}`
	const totalDistributionWeight = props.weight
	const isDemandData = props.feed_facts.some(
		el => el.has_fact_data_demand === true
	)
	const isDeviations = props.feed_facts.some(el => el.has_deviation === true)

	const groupsTitle = props.feed_facts
	.map((el: IFeedFact) => el.group.title).join(', ')
	
	return (
		<Grid
			item
			xs={6}
			sm={6}
			md={6}
			lg={6}
			sx={{ pb: '4px', pt: '20px' }}
			key={`PlanCardViewGrid_${props.id}_${uniqueKey}`}
		>
			<List
				component='nav'
				aria-labelledby='nested-list-subheader'
				sx={{
					boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					backgroundColor: 'white',
					borderRadius: '12px',
					mr: '32px',
					mb: '12px',
					pb: '24px',
					'&:hover': {
						boxShadow: open
							? '0px 4px 16px 0px rgba(0, 0, 0, 0.08)'
							: '0px 4px 16px 0px rgba(82, 34, 208, 0.24)',
					},
					'&:active': { boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)' },
				}}
			>
				<ListItemButton
					disableRipple
					disableTouchRipple
					sx={{
						'&:hover': { backgroundColor: 'transparent' },
						display: 'flex',
						justifyContent: 'space-between',
						mt: '8px',
						mb: '8px',
					}}
					onClick={() => {
						setOpen(!open)
					}}
				>
					<Box sx={{ ml: '8px' }}>
						<Box display='flex' flexDirection='row' sx={{ width: '100%' }}>
							<TypographyHeader>
								{props.type === 'cleaning'
									? `Уборка корма ${props.time.slice(0, 5)}`
									: ''}{' '}
								{props.type === 'distribution'
									? `Раздача корма ${props.time.slice(0, 5)}`
									: ''}{' '}
								{props.type === 'nudge'
									? `Подталкивание ${props.time.slice(0, 5)}`
									: ''}
							</TypographyHeader>
							{isDemandData ? (
								<Box
									sx={{
										backgroundColor: COLORS.process,
										display: 'flex',
										alignItems: 'center',
										ml: '16px',
										borderRadius: '12px',
										p: '4px 9px 4px 9px',
									}}
								>
									<Typography
										sx={{
											color: COLORS.health,
											fontWeight: 400,
											fontSize: '12px',
											lineHeight: '15px',
										}}
									>
										Введите данные
									</Typography>
								</Box>
							) : (
								<></>
							)}
							{isDeviations ? (
								<Box
									sx={{
										backgroundColor: COLORS.deviation,
										display: 'flex',
										alignItems: 'center',
										ml: '16px',
										borderRadius: '12px',
										p: '4px 9px 4px 9px',
									}}
								>
									<Box
										sx={{
											color: COLORS.deviationFont,
											fontWeight: 400,
											fontSize: '12px',
											lineHeight: '15px',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<Box sx={{ mr: '8px' }}>
											<DeviationLogo />
										</Box>
										{`Обнаружено отклонение`}
									</Box>
								</Box>
							) : (
								<></>
							)}
							{isPast && !isDeviations && !isDemandData ? (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										ml: '16px',
									}}
								>
									<OkVerifyLogo />
								</Box>
							) : (
								<></>
							)}
							{isToday &&
							!isDeviations &&
							!isDemandData &&
							props.time < props.timeString ? (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										ml: '16px',
									}}
								>
									<OkVerifyLogo />
								</Box>
							) : (
								<></>
							)}
							{isFuture ? (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										ml: '16px',
									}}
								></Box>
							) : (
								<></>
							)}
						</Box>
					</Box>
					{open ? (
						<ArrowOpenDone
							onClick={() => {
								setOpen(!open)
							}}
							style={{ marginBottom: 'auto' }}
						/>
					) : (
						<ArrowCloseDone
							onClick={() => {
								setOpen(!open)
							}}
							style={{ marginBottom: 'auto' }}
						/>
					)}
				</ListItemButton>
				<Box display='flex' flexDirection='row' sx={{ mt: '16px', pl: '24px' }}>
					<TypographyPrimary sx={{ color: COLORS.secondaryFont }}>
						Допустимое отклонение во времени выполнения:
					</TypographyPrimary>
					<TypographyPrimary sx={{ pl: '16px' }}>
						&plusmn; {props.deviation} минут
					</TypographyPrimary>
				</Box>
				<Box display='flex' flexDirection='row' sx={{ mt: '24px', pl: '24px' }}>
					<TypographyPrimary sx={{ color: COLORS.secondaryFont }}>
						Группы:
					</TypographyPrimary>
					<TypographyPrimary sx={{ pl: '16px' }}>
						{groupsTitle === null ? 'Группы не заданы' : groupsTitle}
					</TypographyPrimary>
				</Box>
				<Box sx={{ pl: '24px' }}>
					{props.type === 'distribution' ? (
						props.feed_facts[0].group.ration === null 
						? <TypographyPrimary sx={{ mt: '24px', color: COLORS.secondaryFont }}>
						Рацион не выбран
					</TypographyPrimary>
						:
							<PlanCardCompound
							ration={props.feed_facts[0].group.ration.title}
							totalWeight={totalDistributionWeight}
							components={props.feed_facts[0].group.ration.components}
							dry={props.feed_facts[0].group.ration.dry}
						/>
					) : (
						<></>
					)}
					{props.type !== 'distribution'
					 ? (
						<></>
					) : (
						<Box display='flex' flexDirection='row' sx={{ mt: '24px' }}>
							<Typography
								sx={{
									fontWeight: 400,
									fontSize: '14px',
									lineHeight: '17px',
									color: COLORS.secondaryFont,
								}}
							>
								{'Общий вес раздачи:'}
							</Typography>
							<Typography
								sx={{
									fontWeight: 400,
									fontSize: '14px',
									lineHeight: '17px',
									pl: '16px',
									color: 'black',
								}}
							>
								{`${totalDistributionWeight} кг`}
							</Typography>
						</Box>
					)}
				</Box>

				<Collapse
					key={`PlanCardViewCollapse${props.id}${props.time}`}
					in={open}
					timeout='auto'
					unmountOnExit
				>
					<List component='div' disablePadding>
						{
							<Box>
								{props.type === 'cleaning' ? (
									<ViewPlanListCleaning
										planId={props.id}
										key={`ViewPlanListCleaning${uniqueKey}`}
										isFuture={props.isFuture}
										uniqueKey={uniqueKey}
										groupsArray={props.feed_facts}
										feedPlanDate={props.feedPlanDate}
									/>
								) : (
									<></>
								)}
								{props.type === 'distribution' ? (
									<ViewPlanListDistribution
										planId={props.id}
										key={`ViewPlanListDistribution${uniqueKey}`}
										uniqueKey={uniqueKey}
										groupsArray={props.feed_facts}
										isFuture={props.isFuture}
										feedPlanDate={props.feedPlanDate}
									/>
								) : (
									<></>
								)}
								{props.type === 'nudge' ? (
									<ViewPlanListNudge
										key={`ViewPlanListNudge${uniqueKey}`}
										uniqueKey={uniqueKey}
										groupsArray={props.feed_facts}
										isFuture={props.isFuture}
										feedPlanDate={props.feedPlanDate}
									/>
								) : (
									<></>
								)}
							</Box>
						}
					</List>
				</Collapse>
			</List>
		</Grid>
	)
}

export default TestPlanCardView
