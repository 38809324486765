import { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';

import {ReactComponent as WaitingLogo} from '../../assets/waiting_logo.svg'

import { PlanFeedField } from './PlanFeedField';
import { useAppSelector } from '../../store/hooks';
import { IPlanTableProps, PlanFeedFieldName } from '../../models'
import { COLORS } from '../../styles/colors';
import { TypographySecondary } from '../../ui/TypographySecondary';
import { IFeedTableProps } from '../../models/IFeed'
import moment from 'moment'

const PlanDistributionTable = (props: IFeedTableProps) => {
  const { feedPlanData } = useAppSelector(store => store.feedPlanReducer)
  const { groupList }  = useAppSelector(store => store.groupsReducer)
  const [ updateTrigger, setUpdateTrigger ] = useState(1)
  return <Box sx={{ pt: '24px' }}>
  <TableContainer sx={{p: '12px 18px 12px 8px'}}>
    <Table sx={{
      minWidth: 650,
      [`& .${tableCellClasses.root}`]: {
        borderBottom: "none"
      }
    }} aria-label="simple table">
      <TableHead sx={{borderBottom: "none"}}>
        <TableRow sx={{borderBottom: `1px solid ${COLORS.secondaryFontOpacity}`,}}>
          <TableCell align='left' > <TypographySecondary>Группа</TypographySecondary></TableCell>
          <TableCell align='center' ><TypographySecondary>Вес раздачи</TypographySecondary></TableCell>
          <TableCell align='center' ><TypographySecondary>Плановое время</TypographySecondary></TableCell>
          <TableCell align='center' ><TypographySecondary>Фактическое время</TypographySecondary></TableCell>
          {/* <TableCell align='center' sx={{pl: '32px'}} ><TypographySecondary>Влажность</TypographySecondary></TableCell> */}
          <TableCell align='right' ><TypographySecondary>Фактический вес раздачи</TypographySecondary></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { props.groupsArray.map((el) => <TableRow
            key={ el.group.id }
          >
            <TableCell component="th" scope="row" align='left' sx={{minWidth: '110px', textAlign: 'left', }} >
              <Box display='flex' flexDirection='row' alignItems='baseline' >
                { el.group.title } 
                <TypographySecondary sx={{pl: '4px'}}>
                  { el.group.barn.title }
                </TypographySecondary>
              </Box>
            </TableCell>
            <TableCell align='center'  >
              <Box display='flex' flexDirection='row' alignItems='baseline' sx={{pl: '24px'}}>
                { el.food_weight } 
                <TypographySecondary sx={{pl: '4px'}}>кг</TypographySecondary>
              </Box>
              </TableCell>
            <TableCell align='center' >
              { el.plan_time }
            </TableCell>
            <TableCell align='center' >
              {el.fact_dt ? moment(el.fact_dt).format("HH:mm") : <WaitingLogo />}
            </TableCell>
            <TableCell
              key={`viewPlanListDistributionTableCellDry${props.uniqueKey}${el.group.id}`}
              align='right'
            >
              {/* <PlanFeedField
                type='number'
                maxValue={100}
                label={'%'}
                key={`distributionViewPlanFeedTableFieldDry${props.uniqueKey}${el.record_id}`}
                value={ el.dry }
                feedPlanDate={ props.feedPlanDate }
                recordId={ el.record_id }
                fieldName={ PlanFeedFieldName.DRY }
                componentTimeType={props.componentTimeType}
                metaFlags={props.metaFlags}
                dispatchUpdate={ () => { setUpdateTrigger(2) } }
              />
            </TableCell>
            <TableCell
              key={`viewPlanListDistributionTableCellWeight${props.uniqueKey}${el.record_id}`}
              align='left'
            > */}
              <PlanFeedField
                isFutureDate = {props.isFuture}
                type='number'
                label={'кг'}
                key={`distributionViewPlanFeedTableFieldWeight${props.uniqueKey}${el.group.id}`}
                value={ el.weight }
                feedPlanDate={ props.feedPlanDate }
                groupId={ el.group.id }
                fieldName={ PlanFeedFieldName.WEIGHT }
                planId={props.planId}
                metaFlags={el.has_fact_data_demand}
                dispatchUpdate={ () => { setUpdateTrigger(2) } }
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
</Box>
}

export default PlanDistributionTable