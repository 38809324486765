import { Box, Typography } from '@mui/material'
import React from 'react'
import { Item } from '../../ui/Item'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { COLORS } from '../../styles/colors'
import { TypographySecondary } from '../../ui/TypographySecondary'
import {
	sxCountTypography,
	sxTypography,
} from './utils/specificIndicatorsStyles'

type GroupCardProps = {
	changeValue: number
	bulk: string
	count: number
	ratio: string
}

const GroupSpecificIndicators = (props: GroupCardProps) => {
	return (
		<Item
			sx={{
				height: '106px',
				boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.16)',
				borderRadius: '8px',
			}}
		>
			<Typography sx={sxTypography}>
				{/* <ArrowDropUpIcon sx={{color: COLORS.success}}/>
    {props.changeValue} */}
			</Typography>
			<Typography
				sx={{
					marginTop: '15px',
					fontSize: '14px',
					fontWeight: 400,
					display: 'flex',
					color: 'black',
				}}
			>
				{props.bulk}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
				}}
			>
				<Typography sx={sxCountTypography}>{props.count}</Typography>
				<TypographySecondary sx={{ pl: '4px' }}>
					{props.ratio}
				</TypographySecondary>
			</Box>
		</Item>
	)
}

export default GroupSpecificIndicators
