import { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';
import { PlanFeedField } from './PlanFeedField';

import {ReactComponent as WaitingLogo} from '../../assets/waiting_logo.svg'

import moment from 'moment'
import { useAppSelector } from '../../store/hooks';
import { IPlanTableProps, PlanFeedFieldName } from '../../models'
import { COLORS } from '../../styles/colors';
import { TypographySecondary } from '../../ui/TypographySecondary';
import { IFeed, IFeedFact, IFeedTableProps } from '../../models/IFeed'

const ViewPlanListCleaning = (props: IFeedTableProps) => {
  const { feedPlanData } = useAppSelector(store => store.feedPlanReducer)
  const { groupList }  = useAppSelector(store => store.groupsReducer)
  const [updateTrigger, setUpdateTrigger] = useState(1)
  type columnsType = {
    name: string;
    title: string;
    align: "center" | "right" | "left" | "inherit" | "justify"
  }

  const columns: columnsType[] = [
    { name: 'group', title: 'Группа', align: 'left' },
    { name: 'time_plan', title: 'Плановое время', align: 'right' },
    { name: 'time_fact', title: 'Фактическое время', align: 'right' },
    { name: 'balance', title: 'Остаток', align: 'center' }
  ]

  return <Box sx={{ pt: '24px' }}>
    <TableContainer sx={{p: '0px 24px 12px 18px'}}>
      <Table sx={{
        minWidth: 650,
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none"
        }
      }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{borderBottom: `1px solid ${COLORS.secondaryFontOpacity}`}}>
            {columns.map((tableCol, index) => (
              <TableCell 
                sx={{fontSize: '12px', lineHeight: '15px', color: COLORS.icon, pl: tableCol.name === 'balance' ? '120px' : ''}} 
                align={tableCol.align} 
                key={index} >
                 <TypographySecondary>{tableCol.title}</TypographySecondary> 
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.groupsArray.map((el, index) => (
            <TableRow
              sx={{ }}
              key={index}
            >
              <TableCell component="th" scope="row" align='left'  >
                <Box display='flex' flexDirection='row' alignItems='baseline' >
                  { el.group.title }
                  <TypographySecondary sx={{pl: '4px'}}>
                    { el.group.barn.title }
                  </TypographySecondary>
                </Box>
              </TableCell>
              <TableCell align='center' sx={{textAlign: 'right', pr: '48px'}} >{ el.plan_time }</TableCell>
              <TableCell align='center' sx={{textAlign: 'right', pr: '72px'}} >
                { el.fact_dt ? moment(el.fact_dt).format("HH:mm") : <WaitingLogo /> }
              </TableCell>
              <TableCell
                align='right'
                key={`viewPlanListCleaningTableCellWeight${props.uniqueKey}`}
              >
                <PlanFeedField
                  isFutureDate = {props.isFuture}
                  type='number'
                  label={'кг'}
                  key={`viewPlanFeedFieldWeight${props.uniqueKey}`}
                  feedPlanDate={ props.feedPlanDate }
                  value={ el.weight }
                  groupId={ el.group.id}
                  planId={props.planId}
                  fieldName={PlanFeedFieldName.WEIGHT}
                  metaFlags={el.has_fact_data_demand}
                  dispatchUpdate={() => { setUpdateTrigger(2) }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
}

export default ViewPlanListCleaning