import TextField from '@mui/material/TextField/TextField'
import React from 'react'
import { COLORS } from '../../styles/colors'

interface ICustomTextFieldProps {
 label: string
 value: number | string
 error: boolean
 helperText: string
 width: string
 onChange: (e: any) => void
 type?: string
}

const CustomTextField = (props: ICustomTextFieldProps) => {
	return (
		<TextField
			variant='outlined'
			label={props.label}
			value={props.value}
			type={props.type} // only type={'number}
			error={props.error}
			autoComplete='off'
			helperText={props.helperText}
			sx={{
				width: props.width,
					'& label': {
          fontSize: props.value ? '16px' : '14px',
				},
				'& label.Mui-focused': {
					color: `${COLORS.main}`,
          fontSize:'16px',
				},
				'& .MuiInputBase-input': {
					fontSize: '14px',
					paddingLeft: '20px',
				},
				'& .MuiOutlinedInput-root': {
					borderRadius: '8px',
					height: '54px',
					'& fieldset': {
						border: `1px solid ${COLORS.inputBorder}`
					},
					'&:hover fieldset': {
						border: `1px solid ${COLORS.hoverInputBorder}`
					},
					'&.Mui-focused fieldset': {
						border: `1px solid ${COLORS.main}`
					},
				},
			}}
			onChange={props.onChange}
			inputProps={{
				style: { fontSize: '14px', paddingLeft: '20px', height: '54px' },
			}}
		/>
	)
}

export default CustomTextField
