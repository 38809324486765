import { createTheme } from '@mui/material'
import MontserratTtf from './fonts/Montserrat-Medium.ttf'

export const fontTheme = createTheme({
    palette: {
        background: {
            default: '#F7F7F7'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
                font-family: 'Montserrat';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: local('Montserrat'), local('Montserrat-Medium'), url(${MontserratTtf}) format('ttf');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            `
        },
    }
}) 