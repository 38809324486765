import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Divider, Typography } from '@mui/material';

import { ReactComponent as GroupDeleteIcon } from '../../assets/group_delete_icon.svg'

import { COLORS } from '../../styles/colors';
import { TypographyPrimary } from '../../ui/TypographyPrimary'

import { useAppSelector, useAppDispatch } from '../../store/hooks';
import {
  setGroupEventFeedPlanActivity,
  setFeedPlanEventInputValue,
  setFeedPlanEventActivityByIndex,
  addNewFeedPlanEvent
} from '../../store/feedPlanSlice'
import { PlanEventTypeEnum } from '../../models';
import RationCheckmarks from '../ration/RationCheckmarks';
import TimeField from '../shared/TimeField'
import { OutlinedButton } from '../../ui/OutlinedButton'
import { TypographySecondary } from '../../ui/TypographySecondary';
import { useRef } from 'react';

const CreatePlanListNudgeContent = () => {

  const planEventId = PlanEventTypeEnum.Nudge

  const groupTypoStyle = { fontWeight: 400, fontSize: '14px', lineHeight: '17px', color: COLORS.secondaryFont }

  const { feedPlanData } = useAppSelector(store => store.feedPlanReducer)
  const { groupList } = useAppSelector(store => store.groupsReducer)
  const dispatch = useAppDispatch()

  const checkedGroups = feedPlanData.plan_data.filter(el => el.active && el.event === planEventId).map(el => el.group)
    .filter((value, index, self) => self.indexOf(value) === index)

  const onGroupsSelectionHandler = (groupId) => {
    if (checkedGroups.indexOf(groupId) === -1) {
      dispatch(setGroupEventFeedPlanActivity({ groupId: groupId, event: planEventId, active: true }))
    }
    else {
      dispatch(setGroupEventFeedPlanActivity({ groupId: groupId, event: planEventId, active: false }))
    }
  }

  const renderPlanDataInputs = (groupId) => {
    const dataCells = []
    let lastPlanEvent = feedPlanData.plan_data.length - 1
    feedPlanData.plan_data.forEach((el, index) => {
      if (!el.active && el.group === groupId && el.event === planEventId) {
        dataCells.push(
          <TimeField
            key={`nudgeTimeCell${el.group}${index}`}
            value={el.time}
            autoFocus={index === lastPlanEvent}
            onBlur={(value) => {
              dispatch(setFeedPlanEventInputValue({ groupId: groupId, event: planEventId, key: 'time', value: value, index: index }))
            }}
            onClose={() => {
              dispatch(setFeedPlanEventActivityByIndex({ index: index, active: false }))
            }}
          />
        )
      }
    })

    return dataCells
  }

  const renderEventsDataCell = (groupId) => {
    let distCounter = 0
    let cleanCounter = 0

    return feedPlanData.plan_data
      .filter(plan => plan.active && plan.group === groupId && plan.event !== PlanEventTypeEnum.Nudge)
      .map((plan, index) => {
        distCounter += plan.event === PlanEventTypeEnum.Distribution ? 1 : 0
        cleanCounter += plan.event === PlanEventTypeEnum.Cleaning ? 1 : 0
        return (
          <Box display='flex' flexDirection='row' alignItems='center' sx={{ pb: '2px', pl: '8px', }}>
            <Typography
              sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '17px', color: COLORS.secondaryFont, whiteSpace: 'nowrap', pr: '4px', }}
            >
              {
                (plan.event === PlanEventTypeEnum.Distribution ? 'P' : 'У') +
                (plan.event === PlanEventTypeEnum.Distribution ? distCounter : cleanCounter) + ':'
              }
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '17px', color: 'black', whiteSpace: 'nowrap', }}
            >
              {plan.time}
            </Typography>
          </Box>
        )
      })
  }

  const sxBorder = `1px solid ${COLORS.secondaryFontOpacity} !important`

  return <>
    <Box display='flex' flexDirection='row' sx={{ pt: '18px' }}>
      <TypographyPrimary>Время подталкивания корма задается отдельно для каждой группы в назначенное время. </TypographyPrimary>
    </Box>
    <Box sx={{ pt: '24px' }}>
      <TableContainer >
        <Table sx={{
          width: '100%',
          minWidth: '1050px',
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none"
          }
        }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '180px', borderBottom: sxBorder}}>
                <TypographySecondary>
                  Группа
                </TypographySecondary> 
              </TableCell>
              <TableCell sx={{ width: '200px', borderBottom: sxBorder }}>
               <TypographySecondary>
                 Действия
               </TypographySecondary>
                </TableCell>
              <TableCell align='left' sx={{borderBottom: sxBorder}} >
                <Box>
                  <TypographySecondary>Периодичность/время</TypographySecondary>
                </Box>
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupList.map((group) => (
              <TableRow>
                <TableCell 
                  key={'NudgeCellKey' + group.id + 1} 
                  component="td" scope="row" align='left' padding='none'
                  sx={{verticalAlign: 'top', pt: '40px'}}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    sx={{
                      borderLeft: `2px solid ${COLORS.icon}`,
                      borderRadius: '0px 12px 12px 0px',
                      pl: '12px', height: '54px', mr: '38px',
                      

                    }}>
                    <TypographyPrimary sx={{ whiteSpace: 'nowrap' }}>{group.title}</TypographyPrimary>
                    <TypographyPrimary sx={{ color: COLORS.secondary, pt: '12px' }}>Групп: {group.cattles_qty}</TypographyPrimary>
                  </Box>
                </TableCell>

                <TableCell 
                  key={'NudgeCellEventKey' + group.id + 1} 
                  component="td" scope="row" align='left' padding='none'
                  sx={{verticalAlign: 'top',}} >
                  <Box display='flex' flexDirection='row' alignItems='center'
                    justifyContent='space-between'
                    sx={{ height: '140px', }}
                  >
                    <Box display='flex' flexDirection='column' justifyContent='center' sx={{ pt: '12px', }} >
                      {renderEventsDataCell(group.id)}
                    </Box>
                  </Box>
                </TableCell>

                <TableCell key={'NudgeCellKey' + group.id + 2} component="td" scope="row" align='left' padding='none' 
                  sx={{
                    maxWidth: '380px',
                    pb: '20px',
                    pt: '40px'
                  }} >
                  {
                    renderPlanDataInputs(group.id)
                  }
                  <OutlinedButton
                    sx={{
                      width: '120px', height: '52px',
                      border: `1px solid ${COLORS.secondaryFont}`, borderRadius: '8px',
                      color: COLORS.secondary,
                      mt: '4px', ml: '4px',
                      '&:hover': {
                        color: COLORS.main,
                        borderColor: COLORS.main,
                        backgroundColor: 'white'
                      }
                    }}
                    onClick={() => dispatch(addNewFeedPlanEvent({ event: planEventId, group: group.id, key: 'time', value: '' }))}
                  >
                    {'+'}
                  </OutlinedButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </>
}

export default CreatePlanListNudgeContent