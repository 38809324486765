import { Typography } from '@mui/material'
import React from 'react'
import { Item } from '../../ui/Item'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { COLORS } from '../../styles/colors'

type GroupCardProps = {
	changeValue: number
	bulk: string
	count: number
	ratio: string
}

const GroupCard = ({ bulk, changeValue, count, ratio }: GroupCardProps) => {
	return (
		<Item sx={{ height: 'fit-content', boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.16)', backgroundColor: 'white' }}>
			<Typography
				sx={{
					fontSize: 12,
					fontWeight: 400,
					color: '#7F7F84',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					marginTop: '-15px',
					marginRight: '-10px',
				}}
			>
				{/* <ArrowDropUpIcon sx={{ color: COLORS.success }} />
				{changeValue} */}
			</Typography>
			<Typography sx={{ marginTop:'15px',fontSize: '14px', fontWeight: 400, display: 'flex', color: 'black' }}>{bulk}</Typography>
			<Typography
				sx={{
					fontSize: 18,
					fontWeight: 600,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
					color: 'black',
				}}
			>
				{count}
				<Typography sx={{ fontSize: 14, fontWeight: 400, color: '#7F7F84', pl: '4px' }}>{ratio}</Typography>
			</Typography>
		</Item>
	)
}

export default GroupCard
