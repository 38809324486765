import * as React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'

import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'
import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as AnalyticsDownloadLogo } from '../../assets/analytics_download_logo.svg'

import { TypographyHeader } from '../../ui/TypographyHeader'
import { Box, IconButton } from '@mui/material'
import { COLORS } from '../../styles/colors'
import { sxButton, sxList } from './utils/headChartStyles'
import { OutlinedButton } from '../../ui/OutlinedButton'
import GroupDate from './GroupDate'
import RangeDate from '../shared/RangeDate'
import { firstDayHandler } from './utils'
import GroupEatenChart from './GroupEatenChart'
import GroupApexChart from './GroupApexChart'

const DeviationCount = props => {
	return (
		<Box display='flex' flexDirection='row' alignItems='baseline'>
			<TypographyHeader>{props.title}</TypographyHeader>
			<TypographyHeader sx={{ color: props.count > 0 ? COLORS.deviationFont : COLORS.success, pl: '8px' }}>{props.count}</TypographyHeader>
		</Box>
	)
}

export default function GroupSpecificList(props) {
	const [open, setOpen] = React.useState(true)
	const [color, setColor] = React.useState(COLORS.secondaryFont)
  
  const primaryDateState = firstDayHandler()

	const [dateRange, setDateRange] = React.useState({
		startDate: Date.parse(primaryDateState[0]) - 10800000,
		endDate: Date.parse(primaryDateState[1]) + 86399000,
	})

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1] + 86399000,
		})
	}
	const sxBtn = sxButton(color)

	const sx = sxList(open)

	const handleClick = () => {
		setOpen(!open)
	}
 

	return (
		<List sx={sx}>
			<Box display='flex' flexDirection='row' alignItems='center' sx={{ pt: '12px', pb: '12px' }}>
				<ListItemButton disableRipple disableTouchRipple sx={{ '&:hover': { backgroundColor: 'transparent' } }} onClick={handleClick}>
						<TypographyHeader>{props.title}</TypographyHeader>
				</ListItemButton>
				<Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
					<Box display='flex' flexDirection='row'>
						{/* <OutlinedButton
							onMouseOver={() => setColor(COLORS.main)}
							onMouseLeave={() => setColor(COLORS.secondaryFont)}
							sx={sxBtn}
							startIcon={
								<AnalyticsDownloadLogo onMouseOver={() => setColor(COLORS.main)} onMouseLeave={() => setColor(COLORS.secondaryFont)} stroke={color} />
							}
						>
							Экспорт
						</OutlinedButton> */}
						<Box sx={{ pl: '24px', pr: '16px' }}><RangeDate onChangeUpdateDate={onChangeCallback} /></Box>
					</Box>
				</Box>
				<IconButton
					disableRipple
					disableTouchRipple
					onClick={handleClick}
					sx={{ pr: '16px', pt: '8px', '&:hover': { backgroundColor: 'transparent' } }}
				>
					{open ? <ArrowOpenDone /> : <ArrowCloseDone />}
				</IconButton>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<ListItemButton sx={{ pl: 2, '&:hover': { backgroundColor: 'transparent' } }} disableRipple disableTouchRipple>
						<Box sx={{ width: '99%' }}>
							{/* <GroupEatenChart id={props.id} startDate={dateRange.startDate} endDate={dateRange.endDate} open={open} /> */}
							<GroupApexChart  id={props.id} startDate={dateRange.startDate} endDate={dateRange.endDate}/>
						</Box>
					</ListItemButton>
				</List>
			</Collapse>
		</List>
	)
}
