import { Box, Divider, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'

import { ReactComponent as GroupCowLogo } from '../../assets/group_cow_logo.svg'
import { ReactComponent as FoodIcon } from '../../assets/food_icon.svg'
import { ReactComponent as FarmTable } from '../../assets/farm_table.svg'
import LinearProgressBar from './utils/LinearProgressBar'

type GroupInfoProps = {
	cows: number
	ration: string
	farmTable: string
	color?: string
	reachable: number
}

const GroupInfo = ({ reachable ,cows, farmTable, ration }: GroupInfoProps) => {
	return (
		<Stack direction='column'>
			<Stack direction='row' alignItems='center' sx={{ paddingLeft: '24px', paddingTop: '20px' }}>
				<GroupCowLogo /> <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '17px', pl: '4px' }}>Голов в группе: {cows}</Typography>
			</Stack>
			<Stack direction='row' alignItems='center' sx={{ paddingLeft: '24px', paddingTop: '20px' }}>
				<FoodIcon />{' '}
				<Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '17px', pl: '4px' }}>Рацион: {ration ? ration : 'Не заполнен'}</Typography>
			</Stack>
			<Stack direction='row' alignItems='center' sx={{ paddingLeft: '24px', paddingTop: '20px' }}>
				<FarmTable /> <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '17px', pl: '4px' }}>Кормовой стол: {farmTable}</Typography>
			</Stack>
			<Box sx={{ pl: '8px', pr: '8px' }}>
				<LinearProgressBar reachable={reachable}/>
			</Box>
		</Stack>
	)
}

export default GroupInfo
