import { Box, ThemeProvider } from '@mui/material'
import React from 'react'

import { ReactComponent as PlusPopupIcon } from '../../assets/plus_popup_icon.svg'
import ManagementTable from './ManagementTable';
import { fontTheme } from '../../styles/font';
import UserPopup from './UserPopup';

import { useAppSelector } from '../../store/hooks';

const theme = fontTheme

const ManagementPage = () => {
  const [open, setOpen] = React.useState(false)

  const { userData } = useAppSelector(store => store.userReducer)

  return <ThemeProvider theme={theme} > <Box sx={{pr: '12px'}}>
      <Box display='flex' flexDirection='row' justifyContent='flex-end' sx={{pl: '24px', pt: '24px', fontFamily: theme.typography.fontFamily, }}>
        <Box sx={{pr: '24px'}} onClick={ () => setOpen(true) }>
          { userData.superuser ? <PlusPopupIcon/> : <></> }
        </Box>
        <UserPopup
          userId={ undefined }
          handleClose={ () => setOpen(false) }
          open={open}
          title='Создание нового'
          action='Создание'
        />
      </Box>
      <Box>
          <ManagementTable parentOpen={open} />
      </Box>
  </Box>
  </ThemeProvider>
}

export default ManagementPage