import { NavLink, useLocation, useParams } from 'react-router-dom'

import Divider from '@mui/material/Divider'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import { sidebarMenuSections } from '../variables'
import { Box } from '@mui/system'
import { ThemeProvider } from '@mui/material'
import { fontTheme } from '../styles/font'

import { ReactComponent as FeedTableIcon } from '../assets/sidebar/feed_table_icon.svg'
import { ReactComponent as GroupsIcon } from '../assets/sidebar/groups_icon.svg'
import { ReactComponent as RationsIcon } from '../assets/sidebar/rations_icon.svg'
import { ReactComponent as EatingPlanIcon } from '../assets/sidebar/eating_plan_icon.svg'
import { ReactComponent as AnalyticsIcon } from '../assets/sidebar/analytics_icon.svg'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useEffect } from 'react'
import { fetchGroupList } from '../store/groupsSlice'
import { TypographyHeaderSecondary } from '../ui/TypographyHeaderSecondary'

const theme = fontTheme

const SidebarMainMenu = () => {
	const location = useLocation()
	const urlParams = useParams()

	const { groupList } = useAppSelector(store => store.groupsReducer)

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(fetchGroupList())
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					fontFamily: theme.typography.fontFamily,
					display: 'flex',
					flexDirection: 'column',
					ml: '-12px',
				}}
			>
				<List>
					{sidebarMenuSections.map(e => (
						<ListItem
							key={e.key}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								pl: '0px',
								pt: '40px',
							}}
						>
							<NavLink
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									minWidth: '210px',
									height: '40px',
									fontWeight: 600,
									fontSize: '16px',
									lineHeight: '20px',
									paddingTop: '10px',
									paddingLeft: '15px',
									paddingBottom: '10px',
									paddingRight: '14px',
									marginBottom: '8px',
									color: 'white',
									backgroundColor:
										`/${e.key}` === location.pathname
											? 'rgba(255, 255, 255, 0.15)'
											: '',
									borderRadius: '12px',
								}}
								to={e.key}
							>
								<Box sx={{ pr: '4px' }}>
									<FeedTableIcon style={{ marginTop: '4px' }} />
								</Box>
								{e.title}{' '}
							</NavLink>
							<NavLink
								to={e.groups.key}
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									minWidth: '210px',
									height: '40px',
									fontWeight: 600,
									fontSize: '16px',
									lineHeight: '20px',
									paddingTop: '10px',
									paddingLeft: '15px',
									paddingBottom: '10px',
									paddingRight: '14px',
									marginBottom: '8px',
									marginTop: '8px',
									color: 'white',
									backgroundColor:
										`/${e.groups.key}` === location.pathname
											? 'rgba(255, 255, 255, 0.15)'
											: '' ||
											  `/${e.groups.key}/${urlParams.groupkey}` ===
													location.pathname
											? 'rgba(255, 255, 255, 0.15)'
											: '',
									borderRadius: '12px',
								}}
							>
								<Box sx={{ pr: '4px' }}>
									<GroupsIcon style={{ marginTop: '4px' }} />
								</Box>
								{e.groups.value}{' '}
								<TypographyHeaderSecondary
									sx={{ pl: '8px', color: 'white' }}
								></TypographyHeaderSecondary>
							</NavLink>
							<NavLink
								to={e.ration.key}
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									minWidth: '210px',
									height: '40px',
									fontWeight: 600,
									fontSize: '16px',
									lineHeight: '20px',
									paddingTop: '10px',
									paddingLeft: '15px',
									paddingBottom: '10px',
									paddingRight: '14px',
									marginBottom: '8px',
									marginTop: '8px',
									color: 'white',
									backgroundColor:
										`/${e.ration.key}` === location.pathname
											? 'rgba(255, 255, 255, 0.15)'
											: '',
									borderRadius: '12px',
								}}
							>
								<Box sx={{ pr: '4px' }}>
									<RationsIcon style={{ marginTop: '4px' }} />
								</Box>
								{e.ration.value}
							</NavLink>
							<NavLink
								to={e.plan.key}
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									minWidth: '210px',
									height: '40px',
									fontWeight: 600,
									fontSize: '16px',
									lineHeight: '20px',
									paddingTop: '10px',
									paddingLeft: '15px',
									paddingBottom: '10px',
									paddingRight: '14px',
									marginBottom: '8px',
									marginTop: '8px',
									color: 'white',
									backgroundColor:
										`/${e.plan.key}` === location.pathname
											? 'rgba(255, 255, 255, 0.15)'
											: '',
									borderRadius: '12px',
								}}
							>
								<Box sx={{ pr: '4px' }}>
									<EatingPlanIcon style={{ marginTop: '4px' }} />
								</Box>
								{e.plan.value}
							</NavLink>
							<NavLink
								to={e.analytics.key}
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									minWidth: '210px',
									height: '40px',
									fontWeight: 600,
									fontSize: '16px',
									lineHeight: '20px',
									paddingTop: '10px',
									paddingLeft: '15px',
									paddingBottom: '10px',
									marginBottom: '8px',
									marginTop: '8px',
									color: 'white',
									backgroundColor:
										`/${e.analytics.key}` === location.pathname
											? 'rgba(255, 255, 255, 0.15)'
											: '',
									borderRadius: '12px',
								}}
							>
								<Box sx={{ pr: '4px' }}>
									<AnalyticsIcon style={{ marginTop: '4px' }} />
								</Box>
								{e.analytics.value}
							</NavLink>
						</ListItem>
					))}
				</List>
			</Box>
		</ThemeProvider>
	)
}

export default SidebarMainMenu
