import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as ProfileIcon } from '../assets/profile_icon.svg';

import useAuth from '../hook/useAuth';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { TypographyHeaderSecondary } from '../ui/TypographyHeaderSecondary';
import { fetchUserData } from '../store/userSlice';


const UserMenu = () => {


    const {userData} = useAppSelector(store => store.userReducer)

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchUserData())
    }, [])


    const authState = useAuth();
    const navigate = useNavigate();

    return <Box sx={{ flexGrow: 0, pr: '24px' }}>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit"
            sx={{ p: 0 }}
        >
            <ProfileIcon style={{height: '32px', width: '32px'}} />
        </IconButton>
        <TypographyHeaderSecondary sx={{whiteSpace: 'pre-line', pl: '16px'}}>{userData.firstname} {'\n'} {userData.lastname}</TypographyHeaderSecondary>  
        </Box>
        
        <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
        >
            <MenuItem key='profile'
                onClick={() => {
                    handleCloseUserMenu()
                    navigate('/profile')
                }}>
                Профиль
            </MenuItem>
            <MenuItem key='quit'
                onClick={() => authState.signOutAndContinue(() => navigate('/login', { replace: true }))}>
                Выйти
            </MenuItem>
        </Menu>
    </Box>
}

export default UserMenu