import { Box, ThemeProvider } from '@mui/material'
import { fontTheme } from '../../styles/font'
import SettingsManagmentList from './SettingsManagment'

const theme = fontTheme

const SettingPage = () => {
  return <ThemeProvider theme={theme}>
      <Box sx={{fontFamily: theme.typography.fontFamily, pl: '16px', pr: '32px'}}>
        <SettingsManagmentList />
    </Box>
</ThemeProvider> 
}

export default SettingPage